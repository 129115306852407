import { useState } from "react";
import { tokenService } from "../../../../services/api";
import {
  LoadingSpinner,
  updateTokenServiceSecret,
  FormInput,
  useToast,
  Toast,
} from "../ConfigUtils";
import { ProfileCard } from "./ProfileCard";

const LLM_OPTIONS = [{ value: "openai", label: "OpenAI" }];

export const LLMEndpointConfiguration = ({
  formState,
  handleInputChange,
  handleLoadConfig,
  handleSaveNewConfig,
  deasyUserConfig,
  setDeasyUserConfig,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [editingProfile, setEditingProfile] = useState(null);
  const [profileName, setProfileName] = useState("");

  const profiles = deasyUserConfig?.llmConfig?.Configs || {};
  const activeProfile = deasyUserConfig?.llmConfig?.LastActive;

  const fieldsFilled = formState.llmType && formState.llmApiKey;
  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const { toast, showToast, hideToast } = useToast();

  const handleAddNew = () => {
    setEditingProfile(null);
    setProfileName("");
    setShowForm(true);
  };

  const handleEdit = (profileName) => {
    handleLoadConfig("llm", profileName);
    setEditingProfile(profileName);
    setProfileName(profileName);
    showToast(
      `LLM configuration ${profileName} loaded successfully`,
      "success",
    );
    setShowForm(true);
  };

  const handleConfigLoad = (name) => {
    handleLoadConfig("llm", name);
    if (name === "new") {
      setShowForm(true);
      return;
    }

    const newConfig = {
      ...deasyUserConfig,
      llmConfig: {
        ...deasyUserConfig.llmConfig,
        LastActive: name,
        Configs: deasyUserConfig.llmConfig.Configs,
      },
    };
    setDeasyUserConfig(newConfig);
    updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
    setShowForm(false);
  };

  const validateFields = () => {
    const errors = [];

    if (!profileName?.trim()) {
      errors.push("Profile name is required");
    }

    if (
      profiles[profileName?.trim()] &&
      (!editingProfile || profileName.trim() !== editingProfile)
    ) {
      errors.push("A profile with this name already exists");
    }

    if (!formState.llmType) {
      errors.push("Please select an LLM provider");
    }

    if (!formState.llmApiKey?.trim()) {
      errors.push("API key is required");
    }

    return errors;
  };

  const validateAndSave = async () => {
    const validationErrors = validateFields();
    if (validationErrors.length > 0) {
      showToast(validationErrors.join(", "), "error");
      return;
    }

    setIsValidating(true);
    setValidationError(null);

    try {
      const config = {
        type: formState.llmType,
        api_key: formState.llmApiKey.trim(),
      };
      const response = await tokenService.validateLLMProvider(config);

      if (response.data.success) {
        handleSaveNewConfig("llm", profileName.trim(), {
          llmType: formState.llmType,
          llmApiKey: formState.llmApiKey.trim(),
        });
        setShowForm(false);
        showToast("LLM configuration saved successfully", "success");
      } else {
        showToast(response.data.message || "Validation failed", "error");
      }
    } catch (error) {
      showToast(
        error.response?.data?.message || "Failed to validate configuration",
        "error",
      );
    } finally {
      setIsValidating(false);
    }
  };

  const getButtonText = () => {
    if (isValidating)
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
          Verifying credentials...
        </div>
      );
    return editingProfile ? "Save Changes" : "Save New Profile";
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-700">
          LLM Endpoint Profiles
        </h2>
        <button
          onClick={handleAddNew}
          className="px-4 py-2 bg-white border-2 border-primary rounded-md hover:bg-primary hover:text-white text-primary"
        >
          +
        </button>
      </div>

      {!showForm ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.entries(profiles).map(([name, config]) => (
            <ProfileCard
              key={name}
              type={config.llmType}
              name={name}
              isActive={name === activeProfile}
              onSelect={(name) => handleConfigLoad(name)}
              onEdit={handleEdit}
              onDelete={(name) => handleSaveNewConfig("llm", name, null)}
              details={{
                Type: config.llmType,
                Provider:
                  LLM_OPTIONS.find((opt) => opt.value === config.llmType)
                    ?.label || config.llmType,
              }}
            />
          ))}
        </div>
      ) : (
        <div className="space-y-6">
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">
              {editingProfile ? `Edit ${editingProfile}` : "Create New Profile"}
            </h3>

            <div className="space-y-4">
              <FormInput
                label="Profile Name"
                type="text"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
                placeholder="Enter profile name"
              />

              {/* LLM Type Selection */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  LLM Provider
                </label>
                <div className="space-y-2">
                  {LLM_OPTIONS.map((llm) => (
                    <label
                      key={llm.value}
                      className="flex items-center space-x-3 cursor-pointer"
                    >
                      <input
                        type="radio"
                        name="llm"
                        value={llm.value}
                        checked={formState.llmType === llm.value}
                        onChange={(e) =>
                          handleInputChange("llmType", e.target.value)
                        }
                        className="form-radio h-5 w-5 text-emerald-600"
                      />
                      <span className="text-gray-700">{llm.label}</span>
                    </label>
                  ))}
                </div>
              </div>

              <FormInput
                label="API Key"
                type="password"
                value={formState.llmApiKey}
                onChange={(e) => handleInputChange("llmApiKey", e.target.value)}
                placeholder="Enter your API key"
              />

              {validationError && (
                <div className="text-red-600 text-sm font-medium">
                  {validationError}
                </div>
              )}
            </div>

            <div className="flex gap-3 mt-6">
              <button
                onClick={validateAndSave}
                disabled={isValidating || !fieldsFilled}
                className="flex-1 py-2 px-4 bg-primary text-white rounded-md 
                         hover:bg-emerald-700 disabled:bg-gray-400"
              >
                {getButtonText()}
              </button>
              <button
                onClick={() => setShowForm(false)}
                className="py-2 px-4 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <Toast
        message={toast.message}
        type={toast.type}
        show={toast.show}
        onClose={hideToast}
      />
    </div>
  );
};
