import { BaseContext } from "../../../../contexts/BaseContext";
import { useContext } from "react";
import { FaSpinner } from "react-icons/fa";
import { AiFillAlert } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";

const TaggingStudioOutput = ({ tsRunning }) => {
  const {
    taggingStudioOutput,
    tagInEditor,
    setTagInEditor,
    setActiveTagEditorSection,
  } = useContext(BaseContext);

  // Empty state component
  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-12 px-4 text-center">
      <AiFillAlert className="w-12 h-12 text-emerald-400 mb-2" />
      <h2 className="text-xl font-semibold text-gray-800">
        No Output Available
      </h2>
      <p className="text-sm text-gray-500 max-w-md">
        To get started, please:
        <ol className="mt-2 text-left list-decimal pl-4 space-y-1">
          <li>Select a tag from the list or create a new one</li>
          <li>Configure your tag settings</li>
          <li>Select the files you want to analyze</li>
          <li>Run the tag test</li>
        </ol>
      </p>
    </div>
  );

  // Loading state component
  const LoadingState = () => (
    <div className="flex flex-col items-center justify-center py-12 px-4">
      <FaSpinner className="w-8 h-8 text-emerald-500 animate-spin mb-4" />
      <h3 className="text-lg font-medium text-gray-900">Processing Files</h3>
      <p className="text-sm text-gray-500 mt-2">
        This may take a few moments...
      </p>
    </div>
  );

  return (
    <div className="relative">
      <div className="w-full bg-white border rounded-lg shadow">
        <div className="p-4 border-b border-gray-200 bg-gray-50 rounded-t-lg">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-800">Output</h2>
          </div>
          {tsRunning && (
            <span className="px-2.5 py-1 bg-emerald-50 text-emerald-700 text-sm rounded-full border border-emerald-200">
              Processing
            </span>
          )}
        </div>

        <div className="max-h-[700px] overflow-y-auto rounded-b-lg">
          {tsRunning ? (
            <LoadingState />
          ) : !taggingStudioOutput || taggingStudioOutput.length === 0 ? (
            <EmptyState />
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[15%] whitespace-nowrap">
                    Filename
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[20%] whitespace-nowrap">
                    Node ID
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[25%] whitespace-nowrap">
                    Value
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[25%] whitespace-nowrap">
                    Evidence
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[15%] whitespace-nowrap">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {taggingStudioOutput?.map((file_data_tuple, fileIndex) =>
                  file_data_tuple[1].map((result, resultIndex) => (
                    <tr
                      key={`${fileIndex}-${resultIndex}`}
                      className="hover:bg-gray-50 transition-colors duration-150"
                    >
                      <td className="px-6 py-4 text-sm text-gray-600">
                        <div className="text-left truncate font-medium">
                          {file_data_tuple[0].split("/").pop()}
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 font-mono">
                        <div className="text-left truncate">
                          {Object.keys(result)[0]}
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-600">
                        <div className="text-left line-clamp-2">
                          {
                            result[Object.keys(result)[0]]?.[tagInEditor.name]
                              ?.value[0]
                          }
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-600">
                        <div className="text-left line-clamp-2">
                          {
                            result[Object.keys(result)[0]]?.[tagInEditor.name]
                              ?.evidence
                          }
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm">
                        <div className="flex items-center space-x-2">
                          <button
                            className="px-4 py-2 text-sm text-emerald-600 bg-emerald-50 border border-emerald-200 rounded-md hover:bg-emerald-100 transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-emerald-50"
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveTagEditorSection("examples");
                              setTagInEditor((prev) => ({
                                ...prev,
                                examples: [
                                  ...prev.examples,
                                  {
                                    id: uuidv4(),
                                    value:
                                      result[Object.keys(result)[0]]?.[
                                        tagInEditor.name
                                      ]?.value,
                                    evidence:
                                      result[Object.keys(result)[0]]?.[
                                        tagInEditor.name
                                      ]?.evidence,
                                    isPositive: true,
                                  },
                                ],
                              }));
                            }}
                          >
                            Add Positive Example
                          </button>
                          <button
                            className="px-4 py-2 text-sm text-red-600 bg-red-50 border border-red-200 rounded-md hover:bg-red-100 transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-red-50"
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveTagEditorSection("examples");
                              setTagInEditor((prev) => ({
                                ...prev,
                                examples: [
                                  ...prev.examples,
                                  {
                                    id: uuidv4(),
                                    value:
                                      result[Object.keys(result)[0]]?.[
                                        tagInEditor.name
                                      ]?.value,
                                    evidence:
                                      result[Object.keys(result)[0]]?.[
                                        tagInEditor.name
                                      ]?.evidence,
                                    isPositive: false,
                                  },
                                ],
                              }));
                            }}
                          >
                            Add Negative Example
                          </button>
                        </div>
                      </td>
                    </tr>
                  )),
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaggingStudioOutput;
