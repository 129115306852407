const defaultVectorDB = {
  type: process.env.REACT_APP_VECTOR_DB_TYPE || "QdrantVectorDBManager",
  collection: process.env.REACT_APP_VECTOR_DB_COLLECTION || "",
  apiKey: process.env.REACT_APP_VECTOR_DB_API_KEY || "",
  url: process.env.REACT_APP_VECTOR_DB_URL || "",
};
const defaultLLMEndpoint = {
  llmType: process.env.REACT_APP_LLM_ENDPOINT_TYPE || "openai",
  llmApiKey: process.env.REACT_APP_LLM_ENDPOINT_API_KEY || "",
};
const defaultDeasyApiKey = process.env.REACT_APP_DEASY_API_KEY || "";
const disabledPages = process.env.REACT_APP_DISABLED_PAGES?.split(",") || [
  "evaluateRAG",
];
export const config = {
  vectorDB: { default: defaultVectorDB },
  llmEndpoint: { default: defaultLLMEndpoint },
  deasy: { apiKey: defaultDeasyApiKey },
  features: {
    disabledPages,
  },
  deasyUserConfig: {
    deasyApiKey: "",
    vdbmConfig: {
      LastActive: "",
      Configs: {},
    },
    llmConfig: {
      LastActive: "",
      Configs: {},
    },
  },
};
