import { Box, LinearProgress, Modal, Typography } from "@mui/material";

export const ProgressModal = ({
  currentStatusText,
  classifyProgress,
  classifyBatchesProgress,
  classifyTotalBatches,
  openProgressModal,
}) => {
  return (
    <Modal
      open={openProgressModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 3,
          p: 4,
        }}
      >
        <Typography className="text-center" variant="h6" component="h2">
          <b>Classifying</b>
        </Typography>
        <Typography
          className="text-center pt-2 text-gray-600 text-sm"
          id="modal-modal-description"
        >
          {currentStatusText}...
        </Typography>
        <LinearProgress
          className="mt-4"
          sx={{
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#4dac94", // Custom bar color
            },
            backgroundColor: "#73ffdc",
          }}
          variant="determinate"
          value={Math.max(
            classifyProgress,
            (classifyBatchesProgress / classifyTotalBatches) * 100,
          )}
        />
      </Box>
    </Modal>
  );
};
