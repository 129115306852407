import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import "@xyflow/react/dist/style.css";
import {
  processData,
  WorkflowSummaryTab,
} from "../DataDiscovery/WorkflowSummaryTab";
import ExtractMetadataTab from "../DataDiscovery/ExtractMeta/ExtractMetadataTab";
import WorkflowProgress from "../../../../utils/WorkflowProgress";

const UseCaseDataDiscoveryScreen = ({ useCase }) => {
  const [selectedStep, setSelectedStep] = useState(0);
  const [filters, setFilters] = useState({
    tags: [],
    dateRange: { start: null, end: null },
    dataTypes: [],
  });

  // Mock data - in real app, this would be filtered based on the filters state
  const FILTERED_TAG_TREE_DATA = {
    nodes: [
      { id: "1", label: "Customer Support", type: "root" },
      { id: "2", label: "Tickets", type: "category" },
      { id: "3", label: "Chat Logs", type: "category" },
      { id: "4", label: "Response Time", type: "metric" },
      { id: "5", label: "Customer Satisfaction", type: "metric" },
    ],
    edges: [
      { source: "1", target: "2" },
      { source: "1", target: "3" },
      { source: "2", target: "4" },
      { source: "2", target: "5" },
      { source: "3", target: "4" },
      { source: "3", target: "5" },
    ],
  };

  const { nodes: initialNodes, edges: initialEdges } = processData(
    FILTERED_TAG_TREE_DATA,
    1000,
  );

  const handleFilterChange = (filterType, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: value,
    }));
  };

  const FilterPanel = () => (
    <div className="bg-white p-4 rounded-lg border mb-4">
      <h3 className="text-lg font-medium mb-4">Filter Data</h3>
      <div className="space-y-4">
        {/* Tags Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Tags
          </label>
          <select
            multiple
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
            value={filters.tags}
            onChange={(e) =>
              handleFilterChange(
                "tags",
                Array.from(e.target.selectedOptions, (option) => option.value),
              )
            }
          >
            <option value="support">Support</option>
            <option value="tickets">Tickets</option>
            <option value="chat">Chat</option>
            <option value="response">Response Time</option>
          </select>
        </div>

        {/* Date Range Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Date Range
          </label>
          <div className="flex gap-2">
            <input
              type="date"
              className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              value={filters.dateRange.start || ""}
              onChange={(e) =>
                handleFilterChange("dateRange", {
                  ...filters.dateRange,
                  start: e.target.value,
                })
              }
            />
            <input
              type="date"
              className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              value={filters.dateRange.end || ""}
              onChange={(e) =>
                handleFilterChange("dateRange", {
                  ...filters.dateRange,
                  end: e.target.value,
                })
              }
            />
          </div>
        </div>

        {/* Data Types Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Data Types
          </label>
          <div className="space-y-2">
            {["Tickets", "Chat Logs", "Email", "Social Media"].map((type) => (
              <label key={type} className="flex items-center">
                <input
                  type="checkbox"
                  className="rounded border-gray-300 text-primary focus:ring-primary"
                  checked={filters.dataTypes.includes(type)}
                  onChange={(e) => {
                    const newTypes = e.target.checked
                      ? [...filters.dataTypes, type]
                      : filters.dataTypes.filter((t) => t !== type);
                    handleFilterChange("dataTypes", newTypes);
                  }}
                />
                <span className="ml-2 text-sm text-gray-700">{type}</span>
              </label>
            ))}
          </div>
        </div>

        <button
          onClick={() => {
            /* Apply filters logic */
          }}
          className="w-full bg-primary text-white px-4 py-2 rounded hover:bg-primary/90"
        >
          Apply Filters
        </button>
      </div>
    </div>
  );

  const renderTabContent = () => {
    switch (selectedStep) {
      case 0:
        return (
          <div className="flex gap-4">
            <div className="w-1/4">
              <FilterPanel />
            </div>
            <div className="flex-1">
              <WorkflowSummaryTab />
            </div>
          </div>
        );
      case 1:
        return <ExtractMetadataTab nodes={initialNodes} edges={initialEdges} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-full">
      <WorkflowProgress currentStep={2} />
      <div className="border-b px-4">
        <Tabs
          value={selectedStep}
          onChange={(e, newValue) => setSelectedStep(newValue)}
          TabIndicatorProps={{
            style: { backgroundColor: "#4dac94" },
          }}
          sx={{
            minHeight: "32px",
            "& .MuiTabs-flexContainer": {
              gap: "16px",
            },
          }}
        >
          <Tab
            label="Filter & Define Metadata"
            tooltip="Filter and define how your metadata should be organized"
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
          <Tab
            label="Extract Dataset Metadata"
            tooltip="Extract and organize metadata from your filtered datasets"
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
        </Tabs>
      </div>
      <div className="flex-1 overflow-auto p-4">{renderTabContent()}</div>
    </div>
  );
};

export default UseCaseDataDiscoveryScreen;
