import { useState, useEffect, useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";

const FileList = ({
  selectedFiles,
  setSelectedFiles,
  setError,
  showTitle = true,
}) => {
  const { deasyUserConfig, files, fetchingFiles, getFiles } =
    useContext(BaseContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(null);
  const [outOfData, setOutOfData] = useState(false);
  const [page, setPage] = useState(1);
  const [previousOffset, setPreviousOffset] = useState("");
  const ITEMS_PER_PAGE = 10;

  // Keep track of all fetched files locally
  const [allFetchedFiles, setAllFetchedFiles] = useState([]);

  const filteredFiles = files.filter((file) =>
    file.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const vectorDBConfiguration =
    deasyUserConfig.vdbmConfig.Configs[deasyUserConfig.vdbmConfig.LastActive];

  const handleFileSelect = (file) => {
    setSelectedFiles((prev) =>
      prev.includes(file) ? prev.filter((f) => f !== file) : [...prev, file],
    );
  };

  useEffect(() => {
    // Skip if we don't have the required configuration
    if (!deasyApiKey || !vectorDBConfiguration) {
      return;
    }

    const loadFiles = async () => {
      try {
        // Only fetch if we're moving forward and need more files
        if (page * ITEMS_PER_PAGE > allFetchedFiles.length && !outOfData) {
          if (fetchingFiles) return;
          const response = await getFiles({
            newOffset: offset,
            newLimit: ITEMS_PER_PAGE * 3,
          });
          if (response?.entities) {
            setAllFetchedFiles((prev) => [
              ...new Set([...prev, ...response.entities]),
            ]);
            setPreviousOffset(response.next_offset || null);
            // Set outOfData when next_offset is null
            if (response.next_offset === null) {
              setOutOfData(true);
            }
          }
        }
      } catch (err) {
        console.log("Error loading files", err);
        setError(err.message);
      }
    };

    loadFiles();
  }, [
    allFetchedFiles.length,
    deasyApiKey,
    fetchingFiles,
    getFiles,
    offset,
    outOfData,
    page,
    setError,
    vectorDBConfiguration,
  ]);

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(allFetchedFiles.length / ITEMS_PER_PAGE);
    if (page < totalPages || previousOffset) {
      setPage(page + 1);
      if (
        (page + 1) * ITEMS_PER_PAGE >= allFetchedFiles.length &&
        previousOffset
      ) {
        setOffset(previousOffset);
      }
    }
  };

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedFiles = filteredFiles.slice(startIndex, endIndex);

  return (
    <div className="h-full flex flex-col">
      {/* Header section - fixed height */}
      <div className="shrink-0">
        {/* Title and count */}
        {showTitle && (
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium">1. Select Files</h3>
            <span className="text-sm text-gray-600">
              Selected: {selectedFiles.length} files
            </span>
          </div>
        )}

        {/* Search Bar */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search files..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Quick Actions */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-2">
            <button
              onClick={() => setSelectedFiles(filteredFiles)}
              className="btn btn-sm btn-outline"
              disabled={filteredFiles.length === 0}
            >
              Select All {searchTerm ? "Filtered" : ""}
            </button>
            <button
              onClick={() => setSelectedFiles([])}
              className="btn btn-sm btn-outline"
              disabled={selectedFiles.length === 0}
            >
              Clear All
            </button>
          </div>
          {searchTerm && (
            <span className="text-sm text-gray-500">
              Showing {filteredFiles.length} of {files.length} files
            </span>
          )}
        </div>
      </div>

      {/* Modified file list container */}
      <div className="flex-1 min-h-0 border rounded-md bg-white overflow-hidden">
        {fetchingFiles ? (
          <div className="flex items-center justify-center h-32">
            <span className="loading loading-spinner loading-md"></span>
            <span className="ml-2 text-gray-600">Loading files...</span>
          </div>
        ) : files.length === 0 ? (
          <div className="flex items-center justify-center h-32 text-gray-500">
            No files available
          </div>
        ) : (
          <div className="h-full flex flex-col">
            {/* Scrollable file list */}
            <div className="flex-1 overflow-y-auto">
              <div className="divide-y divide-gray-100">
                {paginatedFiles.map((file) => (
                  <div
                    key={file}
                    onClick={() => handleFileSelect(file)}
                    className={`px-4 py-3 cursor-pointer hover:bg-gray-50 transition-colors w-full
                      ${selectedFiles.includes(file) ? "bg-gray-200" : ""}`}
                  >
                    <div className="flex flex-col w-full" title={file}>
                      <span className="text-sm font-medium text-gray-700 truncate text-left">
                        {file.split("/").pop()}
                      </span>
                      <span className="text-xs text-gray-500 truncate text-left mt-1">
                        {file}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Pagination - fixed at bottom */}
            <div className="shrink-0 flex justify-between items-center p-4 border-t bg-white">
              <button
                onClick={handlePrevPage}
                disabled={page === 1 || fetchingFiles}
                className="btn btn-sm btn-outline"
              >
                Previous
              </button>
              <span className="text-sm text-gray-600">Page {page}</span>
              <button
                onClick={handleNextPage}
                disabled={
                  (outOfData &&
                    page >=
                      Math.ceil(allFetchedFiles.length / ITEMS_PER_PAGE)) ||
                  fetchingFiles
                }
                className="btn btn-sm btn-outline"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileList;
