import { useState, useContext, useCallback, useEffect } from "react";
import {
  FiPlus,
  FiSearch,
  FiChevronDown,
  FiChevronRight,
  FiChevronLeft,
} from "react-icons/fi";
import { BaseContext } from "../../../../contexts/BaseContext";
import { metadataService } from "../../../../services/api";
import { useDrag } from "react-dnd";
import { GraphContext } from "../../../../contexts/GraphContext";
import { prepareVectorDBConfiguration } from "../../../../services/utils";

const getTagType = (tag) => {
  const types = {
    yesNo: { label: "Yes/No", style: "bg-green-100 text-green-800" },
    custom: { label: "Defined values", style: "bg-gray-100 text-blue-800" },
    aiGenerated: { label: "Open ended", style: "bg-gray-100 text-blue-800" },
  };
  return (
    types[tag.option] || {
      label: "Unknown",
      style: "bg-gray-100 text-gray-600",
    }
  );
};

const TableHeader = ({
  searchTerm,
  setSearchTerm,
  selectedType,
  setSelectedType,
  showUnique,
  setShowUnique,
  onNewTag,
  hideNewTagButton,
}) => (
  <div className="p-4 flex gap-4 items-center border-b border-gray-200 sticky top-0 bg-white z-10">
    <div className="relative flex-grow">
      <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
      <input
        type="text"
        placeholder="Search tags..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
      />
    </div>
    <select
      value={selectedType}
      onChange={(e) => setSelectedType(e.target.value)}
      className="px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
    >
      <option value="">All Types</option>
      <option value="yesNo">Yes/No</option>
      <option value="custom">Defined values</option>
      <option value="aiGenerated">Open ended</option>
    </select>
    <button
      onClick={() => setShowUnique(!showUnique)}
      className={`px-3 py-2 rounded-lg transition-colors ${
        showUnique
          ? "bg-primary text-white border border-primary"
          : "bg-white text-gray-700 border border-gray-300 hover:border-primary hover:text-primary"
      }`}
    >
      Extracted
    </button>
    {!hideNewTagButton && (
      <button
        onClick={onNewTag}
        className="flex items-center gap-2 px-4 py-2 btn bg-white border-2 text-primary border-primary whitespace-nowrap"
      >
        <FiPlus className="w-5 h-5" />
        <span>New Tag</span>
      </button>
    )}
  </div>
);

const CompactTagRow = ({
  tag,
  isSelected,
  onSelect,
  onDelete,
  isExpanded,
  onToggleExpand,
}) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "TAG",
    item: { tag },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    options: {
      dropEffect: "move",
    },
  });

  const handleDragStart = useCallback(
    (e) => {
      e.dataTransfer.setData("draggedItem", JSON.stringify(tag));
      e.dataTransfer.effectAllowed = "move";
    },
    [tag],
  );

  const renderAvailableValues = () => {
    if (
      !Array.isArray(tag.available_values) ||
      tag.available_values.length === 0
    ) {
      const typeMap = {
        number: "Any number",
        date: "Any date",
        text: "Any text",
      };
      return (
        <span className="text-xs text-gray-500 italic">
          {typeMap[tag.output_type] || "Any text"}
        </span>
      );
    }

    return (
      <div className="flex flex-wrap gap-1">
        {tag.available_values.slice(0, 3).map((value, idx) => (
          <span
            key={idx}
            className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded-full"
          >
            {value}
          </span>
        ))}
        {tag.available_values.length > 3 && (
          <span className="text-xs text-gray-500">
            +{tag.available_values.length - 3} more
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      ref={dragRef}
      onDragStart={handleDragStart}
      draggable
      className={`border-b border-gray-200 ${
        isSelected ? "bg-green-50" : "hover:bg-gray-50"
      } cursor-pointer transition-colors ${isDragging ? "opacity-50" : ""}`}
    >
      <div
        className="flex items-center px-4 py-3 text-left"
        onClick={() => {
          onSelect(tag);
          onToggleExpand();
        }}
      >
        <button
          className="mr-2 p-1 hover:bg-gray-200 rounded"
          onClick={(e) => {
            e.stopPropagation();
            onToggleExpand();
          }}
        >
          {isExpanded ? <FiChevronDown /> : <FiChevronRight />}
        </button>

        <div className="flex-1">
          <div className="font-medium">{tag.name}</div>
        </div>

        <div className="flex items-center gap-2">
          <span
            className={`px-2 py-1 text-xs font-semibold rounded-full ${getTagType(tag).style}`}
          >
            {getTagType(tag).label}
          </span>
        </div>
      </div>

      {isExpanded && (
        <div className="px-11 pb-3">
          <p className="text-sm text-gray-600 mb-2">{tag.description}</p>
          <div className="mt-2">{renderAvailableValues()}</div>
        </div>
      )}
    </div>
  );
};

const CompactTagsTable = ({
  hideNewTagButton = false,
  tagInEditor,
  setTagInEditor,
}) => {
  const { savedTags, setSavedTags, deasyUserConfig } = useContext(BaseContext);
  const { selectedFiles } = useContext(GraphContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [showUnique, setShowUnique] = useState(false);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [uniqueTags, setUniqueTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // You can adjust this number

  useEffect(() => {
    const fetchUniqueTags = async () => {
      if (showUnique && deasyUserConfig?.deasyApiKey) {
        try {
          const vectorDBConfig = prepareVectorDBConfiguration(
            deasyUserConfig.vdbmConfig.Configs[
              deasyUserConfig.vdbmConfig.LastActive
            ],
          );

          const response = await metadataService.getUniqueTags(
            deasyUserConfig.deasyApiKey,
            selectedFiles,
            vectorDBConfig,
          );

          // Create a map of saved tags for quick lookup
          const savedMap = new Map(savedTags.map((tag) => [tag.name, tag]));

          // Convert unique tags to the right format
          const tags = response.data.tags.map((tagName) => ({
            name: tagName,
            isUnique: true,
            ...(savedMap.get(tagName) || {}),
          }));

          setUniqueTags(tags);
        } catch (error) {
          console.error("Error fetching unique tags:", error);
        }
      }
    };

    fetchUniqueTags();
  }, [showUnique, deasyUserConfig, selectedFiles, savedTags]);

  const handleDelete = async (tag) => {
    if (
      !window.confirm(`Are you sure you want to delete the tag "${tag.name}"?`)
    )
      return;

    try {
      if (tag.tag_id) {
        await metadataService.deleteTag(
          tag.tag_id,
          deasyUserConfig.deasyApiKey,
        );
        const response = await metadataService.getSavedTags(
          deasyUserConfig.deasyApiKey,
        );
        setSavedTags(response.data.tags || []);
      } else {
        setSavedTags((prev) => prev.filter((t) => t.name !== tag.name));
      }

      if (
        tagInEditor?.tag_id === tag.tag_id ||
        tagInEditor?.name === tag.name
      ) {
        setTagInEditor({});
      }
    } catch (error) {
      console.error("Failed to delete tag:", error);
    }
  };

  const handleNewTag = () => {
    setTagInEditor({});
  };

  const filteredTags = useCallback(() => {
    const tagsToFilter = showUnique ? uniqueTags : savedTags || [];

    return tagsToFilter.filter((tag) => {
      const matchesSearch =
        tag?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tag?.description?.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesType = !selectedType || tag.option === selectedType;
      return matchesSearch && matchesType;
    });
  }, [savedTags, uniqueTags, searchTerm, selectedType, showUnique]);

  const toggleExpand = useCallback((tagId) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(tagId)) {
        newSet.delete(tagId);
      } else {
        newSet.add(tagId);
      }
      return newSet;
    });
  }, []);

  const Pagination = ({ total, current, onChange }) => (
    <div className="flex items-center justify-between px-4 py-3 border-t border-gray-200">
      <div className="flex items-center gap-2">
        <span className="text-sm text-gray-700">
          Showing {Math.min((current - 1) * itemsPerPage + 1, total)} to{" "}
          {Math.min(current * itemsPerPage, total)} of {total} tags
        </span>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={() => onChange(current - 1)}
          disabled={current === 1}
          className="p-2 rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <FiChevronLeft />
        </button>
        <span className="text-sm text-gray-700">
          Page {current} of {Math.ceil(total / itemsPerPage)}
        </span>
        <button
          onClick={() => onChange(current + 1)}
          disabled={current >= Math.ceil(total / itemsPerPage)}
          className="p-2 rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <FiChevronRight />
        </button>
      </div>
    </div>
  );

  return (
    <div className="bg-white flex flex-col h-full">
      <TableHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        showUnique={showUnique}
        setShowUnique={setShowUnique}
        onNewTag={handleNewTag}
        hideNewTagButton={hideNewTagButton}
      />

      <div className="flex-1 overflow-auto">
        {filteredTags()
          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          .map((tag) => (
            <CompactTagRow
              key={tag.tag_id || tag.name}
              tag={tag}
              isSelected={
                tagInEditor?.tag_id === tag.tag_id ||
                tagInEditor?.name === tag.name
              }
              onSelect={() => setTagInEditor(tag)}
              onDelete={handleDelete}
              isExpanded={expandedRows.has(tag.tag_id || tag.name)}
              onToggleExpand={() => toggleExpand(tag.tag_id || tag.name)}
            />
          ))}
      </div>

      <Pagination
        total={filteredTags().length}
        current={currentPage}
        onChange={setCurrentPage}
      />
    </div>
  );
};

export default CompactTagsTable;
