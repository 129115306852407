import React, { useState, useContext, useEffect } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import { useSavedTags } from "../../../hooks/SavedTagsHook";
import { VectorDBConfiguration } from "./ConfigElements/VDBConfig";
import { LLMEndpointConfiguration } from "./ConfigElements/LLMConfig";
import { updateTokenServiceSecret } from "./ConfigUtils";

const DeasyConfig = () => {
  useSavedTags();

  const { savedTags, deasyUserConfig, setDeasyUserConfig } =
    useContext(BaseContext);

  const [formState, setFormState] = useState({
    deasyApiKey: deasyUserConfig.deasyApiKey || "",
    type:
      deasyUserConfig.vdbmConfig?.Configs[
        deasyUserConfig.vdbmConfig?.LastActive
      ]?.type || "",
    collection:
      deasyUserConfig.vdbmConfig?.Configs[
        deasyUserConfig.vdbmConfig?.LastActive
      ]?.collection || "",
    apiKey:
      deasyUserConfig.vdbmConfig?.Configs[
        deasyUserConfig.vdbmConfig?.LastActive
      ]?.apiKey || "",
    url:
      deasyUserConfig.vdbmConfig?.Configs[
        deasyUserConfig.vdbmConfig?.LastActive
      ]?.url || "",
    llmType:
      deasyUserConfig.llmConfig?.Configs[deasyUserConfig.llmConfig?.LastActive]
        ?.llmType || "",
    llmApiKey:
      deasyUserConfig.llmConfig?.Configs[deasyUserConfig.llmConfig?.LastActive]
        ?.llmApiKey || "",
  });
  const handleInputChange = (field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  };

  const handleLoadConfig = (type, name) => {
    if (!name) {
      // Reset form state when no configuration is selected
      if (type === "vectorDB") {
        setFormState((prev) => ({
          ...prev,
          type: "",
          collection: "",
          apiKey: "",
          url: "",
        }));
      } else if (type === "llm") {
        setFormState((prev) => ({
          ...prev,
          llmType: "",
          llmApiKey: "",
        }));
      }
      return;
    }

    // Load configuration based on type
    if (type === "vectorDB") {
      const config = deasyUserConfig?.vdbmConfig?.Configs?.[name];
      if (config) {
        setFormState((prev) => ({
          ...prev,
          type: config.type || "",
          collection: config.collection || "",
          apiKey: config.apiKey || "",
          url: config.url || "",
        }));
      }
    } else if (type === "llm") {
      const config = deasyUserConfig?.llmConfig?.Configs?.[name];
      if (config) {
        setFormState((prev) => ({
          ...prev,
          llmType: config.llmType || "",
          llmApiKey: config.llmApiKey || "",
        }));
      }
    }
  };

  const handleSaveNewConfig = (type, name, config) => {
    if (type === "vectorDB") {
      const newConfigs = { ...deasyUserConfig?.vdbmConfig?.Configs };
      if (config === null) {
        delete newConfigs[name];
        // Find first available config or set to empty
        const nextConfig = Object.keys(newConfigs)[0] || "";

        const newConfig = {
          ...deasyUserConfig,
          vdbmConfig: {
            LastActive: nextConfig,
            Configs: newConfigs,
          },
        };

        setDeasyUserConfig(newConfig);
        updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));

        // Immediately load the next config or reset form
        handleLoadConfig("vectorDB", nextConfig);
      } else {
        newConfigs[name] = config;
        const newConfig = {
          ...deasyUserConfig,
          vdbmConfig: {
            LastActive: name,
            Configs: newConfigs,
          },
        };
        setDeasyUserConfig(newConfig);
        updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
      }
    } else if (type === "llm") {
      const newConfigs = { ...deasyUserConfig?.llmConfig?.Configs };
      if (config === null) {
        delete newConfigs[name];
        // Find first available config or set to empty
        const nextConfig = Object.keys(newConfigs)[0] || "";

        const newConfig = {
          ...deasyUserConfig,
          llmConfig: {
            LastActive: nextConfig,
            Configs: newConfigs,
          },
        };

        setDeasyUserConfig(newConfig);
        updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));

        // Immediately load the next config or reset form
        handleLoadConfig("llm", nextConfig);
      } else {
        newConfigs[name] = config;
        const newConfig = {
          ...deasyUserConfig,
          llmConfig: {
            LastActive: name,
            Configs: newConfigs,
          },
        };
        setDeasyUserConfig(newConfig);
        updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
      }
    } else if (type === "deasyApiKey") {
      const newConfig = {
        ...deasyUserConfig,
        deasyApiKey: name, // Save the API key directly
      };
      setDeasyUserConfig(newConfig);
      updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
    }
  };

  useEffect(() => {
    if (savedTags === null && formState.deasyApiKey) {
      setFormState((prev) => ({
        ...prev,
        deasyApiKey: "",
      }));
    }
  }, [savedTags, formState.deasyApiKey]);

  return (
    <div className="h-full w-full overflow-y-auto">
      <div className="max-w-4xl mx-auto p-6 space-y-6">
        {/* Vector DB Configuration Section */}
        <VectorDBConfiguration
          formState={formState}
          handleInputChange={handleInputChange}
          handleLoadConfig={handleLoadConfig}
          handleSaveNewConfig={handleSaveNewConfig}
          deasyUserConfig={deasyUserConfig}
          setDeasyUserConfig={setDeasyUserConfig}
        />

        {/* Endpoint Configuration Section */}
        <LLMEndpointConfiguration
          formState={formState}
          handleInputChange={handleInputChange}
          handleLoadConfig={handleLoadConfig}
          handleSaveNewConfig={handleSaveNewConfig}
          deasyUserConfig={deasyUserConfig}
          setDeasyUserConfig={setDeasyUserConfig}
        />
      </div>
    </div>
  );
};

export default DeasyConfig;
