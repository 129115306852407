import { useContext, useState } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import { FiPlay } from "react-icons/fi";
import { metadataService } from "../../../../services/api";
import {
  prepareEndpointManagerConfig,
  prepareVectorDBConfiguration,
} from "../../../../services/utils";

const ActionBar = ({ selectedFiles }) => {
  const { tagInEditor, setTaggingStudioOutput, deasyUserConfig } =
    useContext(BaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const vectorDBConfiguration =
    deasyUserConfig.vdbmConfig.Configs[deasyUserConfig.vdbmConfig.LastActive];
  const llmEndpointConfiguration =
    deasyUserConfig.llmConfig.Configs[deasyUserConfig.llmConfig.LastActive];

  const handleTestTagging = async () => {
    if (!tagInEditor?.name || selectedFiles.length === 0) {
      alert("Please select a tag and at least one file to test");
      return;
    }

    setIsLoading(true);
    try {
      const result = await metadataService.taggingStudioClassify(
        prepareVectorDBConfiguration(vectorDBConfiguration),
        prepareEndpointManagerConfig(llmEndpointConfiguration),
        selectedFiles,
        { [tagInEditor?.name]: tagInEditor },
        deasyApiKey,
      );
      setTaggingStudioOutput(result.data.output.file_results);
    } catch (error) {
      console.error("Error testing tag:", error);
      alert("An error occurred while testing the tag");
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !tagInEditor?.name || selectedFiles.length === 0;

  return (
    <div className="fixed bottom-6 right-6 z-30">
      <button
        className={`btn flex items-center gap-2 px-6 py-3 rounded-lg shadow-lg hover:shadow-xl transition-all duration-200 ${
          isDisabled
            ? "bg-gray-100 text-gray-400 cursor-not-allowed"
            : "bg-primary hover:bg-primary-dark text-white font-medium"
        }`}
        onClick={handleTestTagging}
        disabled={isLoading || isDisabled}
      >
        {isLoading ? (
          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
        ) : (
          <FiPlay className="w-5 h-5" />
        )}
        <span>Test Tag</span>
      </button>
    </div>
  );
};

export default ActionBar;
