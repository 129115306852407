import React, { createContext, useContext, useState, useEffect } from "react";
import { metadataService } from "../services/api";
import { auth } from "../config/firebase";
import { BaseContext } from "./BaseContext";
import { graphService } from "../services/api";
import { prepareVectorDBConfiguration } from "../services/utils";

export const GraphContext = createContext();

export const GraphProvider = ({ children }) => {
  const [graphData, setGraphData] = useState(null);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  const [hierarchyStats, setHierarchyStats] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [graphs, setGraphs] = useState([]);
  const [fetchingGraphs, setFetchingGraphs] = useState(false);
  const [selectedDiscoveryGraph, setSelectedDiscoveryGraph] = useState(null);
  const { deasyUserConfig } = useContext(BaseContext);
  const discoveryGraphData = selectedDiscoveryGraph?.graph_data || {};
  const setDiscoveryGraphData = (data) => {
    setSelectedDiscoveryGraph((prev) => ({
      ...prev,
      graph_data: data,
    }));
  };

  useEffect(() => {
    const fetchGraphs = async () => {
      setFetchingGraphs(true);
      const graphs = await graphService.getGraphs(deasyUserConfig.deasyApiKey);
      setGraphs(graphs.data.data.graphs);
      setFetchingGraphs(false);
    };
    fetchGraphs();
  }, [setGraphs, deasyUserConfig.deasyApiKey]);

  const updateHierarchyStats = async (data, apiKey) => {
    try {
      const requestData = {
        vector_db_config: prepareVectorDBConfiguration({
          ...data.vector_db_config,
          user: auth.currentUser.email,
        }),
        endpoint_manager_config: data.endpoint_manager_config,
        conditions: data.conditions || [],
        current_tree: JSON.stringify(discoveryGraphData) || [],
      };

      const response = await metadataService.getHierarchyCountDistributions(
        requestData,
        apiKey,
      );

      // Process the hierarchy to ensure proper nesting
      const processHierarchy = (stats, conditions) => {
        if (!stats || !conditions || conditions.length === 0) return stats;

        let current = stats;
        for (const condition of conditions) {
          const { tag_id, values } = condition;

          // Create or update children for this tag
          if (!current.children) {
            current.children = [];
          }

          // Find or create tag node
          let tagNode = current.children.find((child) => child.name === tag_id);
          if (!tagNode) {
            tagNode = {
              name: tag_id,
              file_count: current.file_count,
              percentage: current.percentage,
              children: [],
            };
            current.children.push(tagNode);
          }

          // Create value nodes for each value in the condition
          values.forEach((value) => {
            let valueNode = tagNode.children.find(
              (child) => child.name === value,
            );
            if (!valueNode) {
              valueNode = {
                name: value,
                file_count: 0,
                percentage: 0,
                children: [],
              };
              tagNode.children.push(valueNode);
            }
          });

          // Update current to the last value node if there is one
          if (values.length > 0) {
            const lastValue = values[values.length - 1];
            const lastValueNode = tagNode.children.find(
              (child) => child.name === lastValue,
            );
            if (lastValueNode) {
              current = lastValueNode;
            }
          }
        }

        return stats;
      };

      const processedStats = processHierarchy(
        response.data?.hierarchy,
        requestData.conditions,
      );

      setHierarchyStats(processedStats);
    } catch (error) {
      console.error("Error updating hierarchy stats:", error);
    }
  };

  const refreshHierarchyStats = async (apiKey) => {
    try {
      // Build conditions for all nodes in the tree
      const buildTreeConditions = (data) => {
        const conditions = [];

        const processNode = (node, tagId) => {
          if (!node || typeof node !== "object") return;

          // If node has TagAvailableValues, it's a tag node
          if (node.TagAvailableValues) {
            conditions.push({
              tag_id: tagId,
              values: node.TagAvailableValues,
            });
          }

          // Process child nodes
          Object.entries(node).forEach(([key, value]) => {
            if (key !== "TagAvailableValues" && typeof value === "object") {
              processNode(value, key);
            }
          });
        };

        Object.entries(data).forEach(([key, value]) => {
          processNode(value, key);
        });

        return conditions;
      };

      const conditions = buildTreeConditions(discoveryGraphData);

      const requestData = {
        vector_db_config: prepareVectorDBConfiguration({
          ...deasyUserConfig.vdbmConfig?.Configs?.[
            deasyUserConfig.vdbmConfig?.LastActive
          ],
          user: auth.currentUser.email,
        }),
        endpoint_manager_config:
          deasyUserConfig.llmConfig?.Configs?.[
            deasyUserConfig.llmConfig?.LastActive
          ],
        conditions: conditions,
        current_tree: JSON.stringify(discoveryGraphData) || [],
      };

      const response = await metadataService.getHierarchyCountDistributions(
        requestData,
        apiKey,
      );

      setHierarchyStats(response.data?.hierarchy);
    } catch (error) {
      console.error("Error refreshing hierarchy stats:", error);
    }
  };

  const value = {
    graphData,
    setGraphData,
    sidePanelOpen,
    setSidePanelOpen,
    selectedNodeData,
    setSelectedNodeData,
    hierarchyStats,
    setHierarchyStats,
    updateHierarchyStats,
    refreshHierarchyStats,
    selectedNodes,
    setSelectedNodes,
    graphs,
    setGraphs,
    selectedDiscoveryGraph,
    setSelectedDiscoveryGraph,
    discoveryGraphData,
    setDiscoveryGraphData,
    fetchingGraphs,
    setFetchingGraphs,
  };

  return (
    <GraphContext.Provider value={value}>{children}</GraphContext.Provider>
  );
};

export const useGraph = () => {
  const context = useContext(GraphContext);
  if (!context) {
    throw new Error("useGraph must be used within a GraphProvider");
  }
  return context;
};
