import React, { useContext } from "react";
import { WorkflowContext } from "../../contexts/WorkflowContext";
import { WorkflowType } from "../../models/WorkflowModel";

const WorkflowProgress = ({ currentStep }) => {
  const { setCurrentScreen, selectedWorkflow } = useContext(WorkflowContext);
  const steps = [
    { id: WorkflowType.CONNECT_DATA, label: "Connect Data" },
    { id: WorkflowType.DATA_DISCOVERY, label: "Data Discovery" },
    { id: WorkflowType.USE_CASE_MANAGEMENT, label: "Use Cases" },
    { id: WorkflowType.EXPORT_METADATA, label: "Export" },
  ];
  const backScreen = currentStep > 0 ? steps[currentStep - 1] : null;
  const nextScreen =
    currentStep < steps.length - 1 ? steps[currentStep + 1] : null;

  const getStepStatus = (index) => {
    if (index < currentStep) return "completed";
    if (index === currentStep) return "current";
    return "upcoming";
  };

  return (
    <div className="flex items-center justify-between bg-gray-50 py-2 px-4 border-b">
      <div className="flex justify-start">
        <button
          onClick={() => setCurrentScreen(backScreen.id)}
          disabled={currentStep === 0}
          title={currentStep === 0 ? "This is the first step" : ""}
          className={`
          px-4 py-2 rounded border
          ${
            currentStep === 0
              ? "border-gray-200 text-gray-400 cursor-not-allowed"
              : "border-gray-300 text-gray-600 hover:bg-gray-50"
          }
        `}
        >
          ← Back
        </button>
      </div>
      <div className="flex flex-1 items-center gap-4 justify-center">
        {steps.map((step, index) => (
          <React.Fragment key={step.id}>
            {index > 0 && <div className="w-6 h-px bg-gray-300" />}
            <div
              className="flex items-center gap-2 cursor-pointer hover:bg-white/80 px-2 rounded-md"
              onClick={() => setCurrentScreen(step.id)}
            >
              <div
                className={`w-5 h-5 rounded-full flex items-center justify-center text-xs text-white
                  ${getStepStatus(index) === "completed" ? "bg-primary" : ""}
                  ${getStepStatus(index) === "current" ? "bg-primary" : ""}
                  ${getStepStatus(index) === "upcoming" ? "bg-gray-200" : ""}`}
              >
                {getStepStatus(index) === "completed" ? "✓" : step.number}
              </div>
              <div
                className={`text-sm ${
                  getStepStatus(index) === "upcoming" ? "text-gray-500" : ""
                }`}
              >
                {step.label}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => setCurrentScreen(nextScreen.id)}
          disabled={!selectedWorkflow || currentStep === steps.length - 1}
          title={
            !selectedWorkflow || currentStep === steps.length - 1
              ? "This is the last step"
              : ""
          }
          className={`
          px-4 py-2 rounded bg-primary text-white
          ${
            !selectedWorkflow || currentStep === steps.length - 1
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-primary/90"
          }
        `}
        >
          Continue →
        </button>
      </div>
    </div>
  );
};

export default WorkflowProgress;
