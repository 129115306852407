import axios from "axios";
import { auth } from "../config/firebase";
import { v4 as uuidv4 } from "uuid";
const API_URL = process.env.REACT_APP_API_URL;

// Create single API instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add authentication interceptor for console endpoints
const getAuthHeaders = async () => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    return {
      Authorization: `Bearer ${token}`,
      "x-user": user.email,
    };
  }
  return {};
};

// Metadata specific headers
export const tokenService = {
  listTokens: async () =>
    api.get("/console/token/list", { headers: await getAuthHeaders() }),
  createToken: async (username) =>
    api.post(
      "/console/token/create",
      { username },
      { headers: await getAuthHeaders() },
    ),
  deleteToken: async (tokenId, softDelete = true) =>
    api.delete(`/console/token/${tokenId}`, {
      data: { soft_delete: softDelete },
      headers: await getAuthHeaders(),
    }),
  getSecret: async (secretName) =>
    api.post(
      `/console/secret/get`,
      { secret_name: secretName },
      { headers: await getAuthHeaders() },
    ),
  storeSecret: async (secretName, secretValue) =>
    api.post(
      `/console/secret/store`,
      { secret_name: secretName, secret_value: secretValue },
      { headers: await getAuthHeaders() },
    ),
  createPaymentSession: async (credits) =>
    api.post(
      "/console/payment/create-session",
      { credits },
      { headers: await getAuthHeaders() },
    ),
  verifyPayment: async (sessionId) =>
    api.post(
      "/console/payment/verify",
      { session_id: sessionId },
      { headers: await getAuthHeaders() },
    ),
  validateVectorDB: async (config) =>
    api.post("/console/vector-db/validate", config, {
      headers: await getAuthHeaders(),
    }),
  validateLLMProvider: async (providerConfig) =>
    api.post("/console/llm-provider/validate", providerConfig, {
      headers: await getAuthHeaders(),
    }),
};

export const metadataService = {
  getMetadataHealth: async (vectorDBConfiguration, deasyApiKey) =>
    api.post(
      "/metadata_health",
      { vector_db_config: vectorDBConfiguration },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  createTag: async (tagData, deasyApiKey) =>
    api.post(
      "/tags/create",
      { tag_data: tagData },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  getSavedTags: async (deasyApiKey) =>
    api.get("/tags/list", {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  suggestTags: async (
    vectorDBConfiguration,
    endpointManagerConfig,
    currentTree,
    node,
    context,
    infoContext,
    file_names,
    numTags,
    tagType,
    maxDepth,
    deasyApiKey,
  ) =>
    api.post(
      "/metadata/suggest",
      {
        vector_db_config: vectorDBConfiguration,
        endpoint_manager_config: endpointManagerConfig,
        current_tree: currentTree,
        node: node,
        context: context,
        use_file_level_tags: infoContext === "File",
        file_names: file_names,
        num_tags: numTags,
        tag_type: tagType,
        max_height: maxDepth,
        use_existing_tags: infoContext === "Existing Tags",
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  suggestReadiness: async (
    vectorDBConfiguration,
    endpointManagerConfig,
    deasyApiKey,
  ) =>
    api.post(
      "/metadata/readiness",
      {
        vector_db_config: vectorDBConfiguration,
        endpoint_manager_config: endpointManagerConfig,
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  updateTag: async (tagData, deasyApiKey) =>
    api.put(
      "/tags/update",
      { tag_data: tagData },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  deleteTag: async (tagId, deasyApiKey) =>
    api.delete(`/tags/delete?tag_id=${tagId}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  autoCreateTags: async (data, deasyApiKey) =>
    api.post("/auto_create_tags", data, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  listPaginated: async (
    vectorDBConfiguration,
    deasyApiKey,
    limit = 50,
    offset = 0,
    groupBy = "file",
    entities_already_scrolled = [],
  ) =>
    api
      .post(
        "/metadata/list_paginated",
        {
          vector_db_config: vectorDBConfiguration,
          limit: limit,
          offset: offset,
          group_by: groupBy,
          entities_already_scrolled: entities_already_scrolled,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response.data;
      }),
  queryFileTags: async (
    vectorDBConfiguration,
    deasyApiKey,
    file_names = null,
    metadata_keys = null,
    metadata_value_filters = null,
    full_text_filters = null,
  ) =>
    api.post(
      "/query_file_level_tags",
      {
        vector_db_config: vectorDBConfiguration,
        file_names,
        metadata_key_filters: metadata_keys,
        metadata_value_filters,
        full_text_filters: full_text_filters,
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  taggingStudioClassify: (
    vectorDBConfiguration,
    endpointManagerConfig,
    fileNames,
    tags,
    deasyApiKey,
    soft_run = true,
    overwrite = true,
    currentTree = {},
    abortController,
    callback,
  ) => {
    const jobId = uuidv4();
    const BATCH_SIZE = 10;
    const fileBatches = [];
    for (let i = 0; i < fileNames.length; i += BATCH_SIZE) {
      fileBatches.push(fileNames.slice(i, i + BATCH_SIZE));
    }
    fileBatches.forEach((fileBatch) =>
      api
        .post(
          "/classify",
          {
            vector_db_config: vectorDBConfiguration,
            endpoint_manager_config: endpointManagerConfig,
            file_names: fileBatch,
            tags: tags,
            soft_run: soft_run,
            job_id: jobId,
            overwrite: overwrite,
            hierarchy: currentTree,
          },
          {
            signal: abortController.signal,
            headers: {
              "x-token": deasyApiKey,
              "x-user": auth.currentUser.email,
            },
          },
        )
        .catch((error) => console.log("Cancelled", error))
        .finally(() => callback()),
    );
    return [jobId, fileBatches.length];
  },
  trackJob: async (uuid, deasyApiKey) =>
    api.get(`/task_status/${uuid}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),

  generateFileLevelTags: async (
    vectorDBConfiguration,
    endpointManagerConfig,
    fileNames,
    tags,
    deasyApiKey,
  ) =>
    api
      .post(
        "/generate_file_level_tags",
        {
          vector_db_config: vectorDBConfiguration,
          endpoint_manager_config: endpointManagerConfig,
          file_names: fileNames,
          tags: tags,
          job_id: "client",
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        console.log("Headers:", {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        });
        return response;
      }),

  exportMetadata: async (requestBody, deasyApiKey) =>
    api
      .post("/export_usecase_metadata", requestBody, {
        headers: {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        },
      })
      .then((response) => {
        console.log("Headers:", {
          "x-token": deasyApiKey,
          "x-user": auth.currentUser.email,
        });
        return response;
      }),
  getHierarchyCountDistributions: async (data, deasyApiKey) =>
    api.post("/metadata/count_distributions", data, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),

  exportToVectorDB: async (requestBody, deasyApiKey) =>
    api.post("/export_usecase2vdb", requestBody, {
      headers: {
        "x-token": deasyApiKey,
        "x-user": auth.currentUser.email,
      },
    }),

  createUseCase: async (useCaseData, deasyApiKey) => {
    // Transform selectedParentNodes to database format
    const conditions = useCaseData.selectedParentNodes.map((node) => {
      const nodePath = node.data.nodePath;
      let condition = {};
      // Process pairs of tag and value
      for (let i = 0; i < nodePath.length; i += 2) {
        const tag = nodePath[i];
        const value = nodePath[i + 1];
        if (tag && value) {
          condition[tag] = [value];
        }
      }

      return condition;
    });

    api
      .post(
        "/create_usecase",
        {
          usecase_id: useCaseData.id,
          usecase_name: useCaseData.name,
          workflow_id: useCaseData.workflowId,
          user_id: auth.currentUser.email,
          description: useCaseData.description,
          status: useCaseData.status,
          data_points: useCaseData.dataPoints,
          latest_graph: useCaseData.latestGraph,
          graph_id: useCaseData.graphId,
          last_updated: useCaseData.lastUpdated,
          condition: conditions,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response;
      });
  },

  deleteUsecase: async (usecaseId, deasyApiKey) =>
    api.delete(`/delete_usecase?usecase_id=${usecaseId}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  getUniqueTags: async (
    deasyApiKey,
    fileNames = null,
    vector_db_config = null,
  ) =>
    api.post(
      "/get_unique_tags",
      {
        file_names: fileNames,
        vector_db_config: vector_db_config,
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  estimateClassify: async (
    vectorDBConfiguration,
    endpointManagerConfig,
    fileNames,
    tags,
    deasyApiKey,
    currentTree = {},
  ) =>
    api.post(
      "/classify/estimate",
      {
        vector_db_config: vectorDBConfiguration,
        endpoint_manager_config: endpointManagerConfig,
        file_names: fileNames,
        tags: tags,
        hierarchy: currentTree,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  listUsecases: async (workflowId, deasyApiKey) => {
    const response = await api.get(`/list_usecases/${workflowId}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    });

    const transformedUsecases = response.data.usecases.map((usecase) => {
      const selectedParentNodes =
        usecase.condition?.map((condition) => {
          const nodePath = Object.entries(condition).reduce(
            (path, [key, values]) => {
              return [...path, key, values[0]];
            },
            [],
          );

          return {
            data: { nodePath: nodePath },
            stats: {},
          };
        }) || [];

      return {
        id: usecase.usecase_id,
        workflowId: usecase.workflow_id,
        name: usecase.usecase_name,
        description: usecase.description,
        status: usecase.status || "active",
        dataPoints: usecase.data_points,
        graphId: usecase.graph_id,
        latestGraph: usecase.latest_graph,
        lastUpdated: usecase.last_updated,
        selectedParentNodes,
      };
    });

    return transformedUsecases;
  },
};

export const graphService = {
  createUpdateGraph: async (
    deasyApiKey,
    graph_id = null,
    graph_name = null,
    graph_description = null,
    graph_data = null,
  ) =>
    api
      .post(
        "/create_update_graph",
        {
          graph_id,
          graph_name,
          graph_description,
          graph_data,
        },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response;
      }),
  getGraphs: async (deasyApiKey, graphIds = null) =>
    api
      .post(
        `/list_graphs`,
        { graph_ids: graphIds },
        {
          headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
        },
      )
      .then((response) => {
        return response;
      }),
  deleteGraph: async (graphId, deasyApiKey) =>
    api.delete(`/delete_graph?graph_id=${graphId}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
};

export const workflowService = {
  createWorkflow: async (workflowData, deasyApiKey) =>
    api.post("/create_workflow", workflowData, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),

  updateWorkflow: async (workflowId, workflowData, deasyApiKey) =>
    api.put(`/workflow/${workflowId}`, workflowData, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),

  deleteWorkflow: async (workflowId, deasyApiKey) =>
    api.delete(`/workflow/${workflowId}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),

  listWorkflows: async (deasyApiKey) =>
    api.get("/list_workflows", {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
};
