import React, { useState, useContext } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import { BaseContext } from "../../contexts/BaseContext";
import { fetchUserSecrets } from "../../services/utils";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const navigate = useNavigate();
  const { setSavedTags, setDeasyUserConfig } = useContext(BaseContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      if (isResetPassword) {
        await sendPasswordResetEmail(auth, email);
        setSuccess("Password reset email sent!");
        return;
      }

      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        await fetchUserSecrets(setSavedTags, setDeasyUserConfig);
      }

      navigate("/");
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-login-credentials":
          setError("Invalid email or password. Please try again.");
          break;
        case "auth/invalid-email":
          setError("Please enter a valid email address.");
          break;
        case "auth/user-disabled":
          setError("This account has been disabled. Please contact support.");
          break;
        case "auth/user-not-found":
          setError("Invalid email or password. Please try again.");
          break;
        case "auth/wrong-password":
          setError("Invalid email or password. Please try again.");
          break;
        case "auth/too-many-requests":
          setError("Too many failed attempts. Please try again later.");
          break;
        case "auth/email-already-in-use":
          setError("An account with this email already exists.");
          break;
        case "auth/weak-password":
          setError("Password should be at least 6 characters long.");
          break;
        default:
          setError("An error occurred. Please try again.");
      }
      console.error("Auth error:", err);
    }
  };

  return (
    <div className="auth-container bg-gradient-to-br from-teal-200 via-emerald-400 to-teal-600">
      <form
        onSubmit={handleSubmit}
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <div className="text-center mb-8">
          <img
            src="/deasy-icon.png"
            alt="Deasy"
            className="w-16 h-16 mx-auto mb-4"
          />
          <h1 className="text-4xl font-bold mb-2">DeasyLabs</h1>
          <h2 className="text-2xl text-gray-600">
            {isResetPassword
              ? "Reset Password"
              : isSignUp
                ? "Sign Up"
                : "Login"}
          </h2>
        </div>

        {error && (
          <div style={{ color: "red", marginBottom: "10px" }}>{error}</div>
        )}
        {success && (
          <div style={{ color: "green", marginBottom: "10px" }}>{success}</div>
        )}

        <div style={{ marginBottom: "15px" }}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          {!isResetPassword && (
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          )}
        </div>

        <button type="submit" className="btn btn-primary w-full mb-3">
          {isResetPassword ? "Send Reset Link" : isSignUp ? "Sign Up" : "Login"}
        </button>

        {!isResetPassword && (
          <button
            type="button"
            onClick={() => setIsSignUp(!isSignUp)}
            className="btn btn-secondary w-full mb-3"
          >
            {isSignUp
              ? "Already have an account? Login"
              : "Need an account? Sign Up"}
          </button>
        )}

        <button
          type="button"
          onClick={() => setIsResetPassword(!isResetPassword)}
          className="btn btn-secondary w-full"
        >
          {isResetPassword ? "Back to Login" : "Forgot Password?"}
        </button>
      </form>
    </div>
  );
}

export default Login;
