import React, { useState, useContext } from "react";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { WorkflowContext } from "../../../../../contexts/WorkflowContext";
import { metadataService } from "../../../../../services/api";
import WorkflowProgress from "../../../../utils/WorkflowProgress";
import { prepareVectorDBConfiguration } from "../../../../../services/utils";
import { toast } from "react-hot-toast";

const ExportMetadataScreen = ({ includeProgress = true }) => {
  const { deasyUserConfig } = useContext(BaseContext);
  const { selectedWorkflow } = useContext(WorkflowContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showVDBForm, setShowVDBForm] = useState(false);

  const vdbProfiles = deasyUserConfig?.vdbmConfig?.Configs || {};
  const activeProfileName = deasyUserConfig?.vdbmConfig?.LastActive;
  const activeProfile = vdbProfiles[activeProfileName];

  const [vdbSettings, setVdbSettings] = useState({
    collection: activeProfile?.collection || "",
    apiKey: activeProfile?.apiKey || "",
    url: activeProfile?.url || "",
    type: activeProfile?.type || "PineconeVectorDBManager",
  });

  const handleFormatClick = (format) => {
    setSelectedFormat(format);
    setShowDropdown(true);
    setShowVDBForm(false);
  };

  const handleVDBClick = () => {
    setSelectedFormat("vdb");
    setShowDropdown(false);
    setShowVDBForm(true);
  };

  const handleVDBSettingChange = (field, value) => {
    setVdbSettings((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleVDBExport = async () => {
    try {
      setIsLoading(true);

      const vectorDBConfig = prepareVectorDBConfiguration({
        type: vdbSettings.type,
        apiKey: vdbSettings.apiKey,
        collection: vdbSettings.collection,
        url: vdbSettings.url,
      });

      const originalVectorDBConfig = prepareVectorDBConfiguration({
        type: activeProfile?.type || "PineconeVectorDBManager",
        apiKey: activeProfile?.apiKey || "",
        collection: activeProfile?.collection || "",
        url: activeProfile?.url || "",
      });

      const requestBody = {
        usecase_id: selectedWorkflow?.selectedUseCase?.id,
        ori_vector_db_config: originalVectorDBConfig,
        target_vector_db_config: vectorDBConfig,
      };

      if (!selectedWorkflow?.selectedUseCase?.id) {
        console.error("No usecase selected");
        toast.error("No usecase has been selected");
        return;
      }

      await metadataService.exportToVectorDB(requestBody, deasyApiKey);

      setShowVDBForm(false);
      setVdbSettings({
        collection: activeProfile?.collection || "",
        apiKey: activeProfile?.apiKey || "",
        url: activeProfile?.url || "",
        type: activeProfile?.type || "PineconeVectorDBManager",
      });
    } catch (error) {
      console.error("VDB Export failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = async (metadataLevel) => {
    try {
      setIsLoading(true);

      const vectorDBConfig = prepareVectorDBConfiguration(
        deasyUserConfig.vdbmConfig.Configs[
          deasyUserConfig.vdbmConfig.LastActive
        ],
      );

      if (!selectedWorkflow?.selectedUseCase?.id) {
        console.error("No usecase selected");
        toast.error("No usecase has been selected");
        return;
      }

      const requestBody = {
        usecase_id: selectedWorkflow.selectedUseCase.id,
        export_file_level: metadataLevel === "file",
        export_format: selectedFormat,
        vector_db_config: vectorDBConfig,
      };
      const response = await metadataService.exportMetadata(
        requestBody,
        deasyApiKey,
      );

      const content =
        selectedFormat === "json"
          ? JSON.stringify(response.data, null, 2)
          : response.data;

      const blob = new Blob([content], {
        type: selectedFormat === "json" ? "application/json" : "text/csv",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `usecase_metadata.${selectedFormat}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setShowDropdown(false);
      setSelectedFormat(null);
    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-full">
      {includeProgress && <WorkflowProgress currentStep={3} />}
      <div className="flex-1 overflow-auto p-8 w-1/2">
        <div className="bg-white rounded-lg border border-gray-200 p-6">
          <h2 className="text-lg font-medium mb-4">Export Options</h2>
          <div className="space-y-4">
            <div className="flex flex-col gap-4">
              <div className="flex items-center">
                <button
                  className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90 disabled:opacity-50 mr-4"
                  onClick={() => handleFormatClick("json")}
                  disabled={isLoading}
                >
                  Export as JSON
                </button>
                <button
                  className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90 disabled:opacity-50 mr-4"
                  onClick={() => handleFormatClick("csv")}
                  disabled={isLoading}
                >
                  Export as CSV
                </button>
                <button
                  className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90 disabled:opacity-50"
                  onClick={handleVDBClick}
                  disabled={isLoading}
                >
                  Export to Vector Database
                </button>
              </div>

              {showVDBForm && (
                <div className="flex flex-col gap-4 mt-4">
                  <div className="space-y-4">
                    {activeProfileName && (
                      <div className="bg-primary/5 p-3 rounded-md">
                        <span className="text-sm text-primary font-medium">
                          Using settings from active profile:{" "}
                          {activeProfileName}
                        </span>
                      </div>
                    )}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Database Type
                      </label>
                      <select
                        className="w-full border border-gray-300 rounded-md px-3 py-2"
                        value={vdbSettings.type}
                        onChange={(e) =>
                          handleVDBSettingChange("type", e.target.value)
                        }
                      >
                        <option value="PineconeVectorDBManager">
                          Pinecone
                        </option>
                        <option value="QdrantVectorDBManager">Qdrant</option>
                        <option value="ChromaVectorDBManager">Chroma</option>
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Collection Name
                      </label>
                      <input
                        type="text"
                        className="w-full border border-gray-300 rounded-md px-3 py-2"
                        value={vdbSettings.collection}
                        onChange={(e) =>
                          handleVDBSettingChange("collection", e.target.value)
                        }
                        placeholder="Enter collection name"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        API Key
                      </label>
                      <input
                        type="password"
                        className="w-full border border-gray-300 rounded-md px-3 py-2"
                        value={vdbSettings.apiKey}
                        onChange={(e) =>
                          handleVDBSettingChange("apiKey", e.target.value)
                        }
                        placeholder="Enter API key"
                      />
                    </div>

                    {vdbSettings.type !== "PineconeVectorDBManager" && (
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Database URL
                        </label>
                        <input
                          type="text"
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                          value={vdbSettings.url}
                          onChange={(e) =>
                            handleVDBSettingChange("url", e.target.value)
                          }
                          placeholder="Enter database URL"
                        />
                      </div>
                    )}
                    <button
                      className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90 disabled:opacity-50"
                      onClick={handleVDBExport}
                      disabled={
                        isLoading ||
                        !vdbSettings.collection ||
                        !vdbSettings.apiKey
                      }
                    >
                      {isLoading ? "Exporting..." : "Export"}
                    </button>
                  </div>
                </div>
              )}
              {showDropdown && (
                <div className="flex flex-col gap-2">
                  <label className="text-sm text-gray-600 font-medium">
                    Select metadata level:
                  </label>
                  <div className="flex items-center gap-3">
                    <select
                      className="min-w-[200px] border border-gray-300 rounded-md px-3 py-2 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
                      onChange={(e) => handleExport(e.target.value)}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Choose level
                      </option>
                      <option value="file">File Level Metadata</option>
                      <option value="chunk">Chunk Level Metadata</option>
                    </select>
                    {isLoading && (
                      <span className="text-sm text-gray-600 flex items-center gap-2">
                        <svg
                          className="animate-spin h-4 w-4 text-primary"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Exporting...
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportMetadataScreen;
