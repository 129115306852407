import { useContext, useEffect, useState } from "react";
import { BaseContext } from "../../../../../../../contexts/BaseContext";
import { GraphContext } from "../../../../../../../contexts/GraphContext";
import {
  Boxes,
  CircleAlert,
  CircleCheck,
  FileText,
  Settings,
  Tags,
} from "lucide-react";
import { cleanSchema, flattenMap } from "./AutoSuggestUtils";
import { metadataService } from "../../../../../../../services/api";
import {
  prepareEndpointManagerConfig,
  prepareVectorDBConfiguration,
} from "../../../../../../../services/utils";
import { ChevronDown } from "lucide-react";
import toast from "react-hot-toast";
import ComputeEstimation from "../../ExtractMeta/ComputeEstimation";
import {
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import React from "react";
export const AutoSuggestedMetadata = ({
  openFilePicker,
  setOpenFilePicker,
  selectedFiles,
  setSelectedFiles,
}) => {
  const { deasyUserConfig } = useContext(BaseContext);
  const {
    discoveryGraphData,
    selectedNodeData,
    setSidePanelOpen,
    setDiscoveryGraphData,
  } = useContext(GraphContext);

  // Destructure configurations
  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    llmConfig: { Configs: llmConfigs, LastActive: llmLastActive },
    deasyApiKey,
  } = deasyUserConfig;

  const vectorDBConfiguration = vdbmConfigs[vdbmLastActive];
  const llmEndpointConfiguration = llmConfigs[llmLastActive];

  // State management
  const [value, setValue] = useState("");
  const [infoContext, setInfoContext] = useState("Chunk");
  const [currentStatusText, setCurrentStatusText] = useState("Please wait");
  const [modalOpen, setModalOpen] = useState(false);
  const [oobHealthStatusText, setOobHealthStatusText] = useState(
    "Checking for OOB Health...",
  );
  const [fetchState, setFetchState] = useState("fetching");
  const getFetchUI = () => {
    const states = {
      fetching: {
        borderColor: "#E0E0E0",
        textColor: "black",
        iconColor: "black",
        Icon: CircularProgress,
      },
      success: {
        borderColor: "#4dac94",
        textColor: "#4dac94",
        iconColor: "#4dac94",
        Icon: CircleCheck,
      },
      error: {
        borderColor: "rgb(208, 0, 0)",
        textColor: "rgb(208, 0, 0)",
        iconColor: "rgb(208, 0, 0)",
        Icon: CircleAlert,
      },
    };
    return states[fetchState];
  };
  useEffect(() => {
    async function fetchReadiness() {
      const response = await metadataService.suggestReadiness(
        prepareVectorDBConfiguration(vectorDBConfiguration),
        prepareEndpointManagerConfig(llmEndpointConfiguration),
        deasyApiKey,
      );
      if (response.data?.all_files === 0) {
        setOobHealthStatusText("No files found! Did you run classify?");
        setFetchState("error");
      } else if (
        response.data?.all_files - response.data?.classified_files !==
        0
      ) {
        setOobHealthStatusText(
          `${response.data?.all_files - response.data?.classified_files} files not ready!`,
        );
        setFetchState("error");
      } else {
        setOobHealthStatusText(`All files ready!`);
        setFetchState("success");
      }
    }
    fetchReadiness();
    // eslint-disable-next-line
  }, []);
  // Fetch files on component mount
  const [numTags, setNumTags] = useState(5);
  const [maxDepth, setMaxDepth] = useState(2);

  const [tagType, setTagType] = useState("Yes/No");
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);

  const handleClick = async () => {
    console.log("handleClick");
    if (!openFilePicker) {
      setModalOpen(true);
    }

    try {
      setCurrentStatusText("Please wait...");
      const nodeLabel =
        selectedNodeData?.nodeType === "value"
          ? selectedNodeData.nodePath.at(-2)
          : selectedNodeData?.label;

      const response = await metadataService.suggestTags(
        prepareVectorDBConfiguration(vectorDBConfiguration),
        prepareEndpointManagerConfig(llmEndpointConfiguration),
        discoveryGraphData,
        {
          label: nodeLabel,
          path: selectedNodeData.nodePath,
        },
        value,
        infoContext,
        selectedFiles,
        numTags,
        tagType,
        maxDepth,
        deasyApiKey,
      );

      // Process successful response
      setSidePanelOpen(false);
      if (Object.keys(discoveryGraphData).length === 0) {
        const cleanedData = cleanSchema(response.data.suggestion);
        setDiscoveryGraphData(cleanedData);
      } else {
        let currentNode = discoveryGraphData;
        for (let i = 0; i < selectedNodeData.nodePath.length - 1; i++) {
          currentNode = currentNode[selectedNodeData.nodePath[i]];
        }
        currentNode[selectedNodeData.nodePath.at(-1)] = cleanSchema(
          response.data.suggestion,
        );
        const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));
        setDiscoveryGraphData(updatedData);
      }

      const individualNodes = Object.values(
        flattenMap(response.data.suggestion),
      );
      await Promise.all(
        individualNodes.map((tag) =>
          metadataService.createTag(
            {
              name: tag.name,
              label: tag.name,
              description: tag.description,
              available_values: tag.availableValues,
              option: tag?.option || "aiGenerated",
              output_type: tag.output_type,
            },
            deasyApiKey,
          ),
        ),
      );
    } catch (error) {
      const errorMessage =
        error.response?.data?.detail ||
        error.message ||
        "Unknown error occurred";
      setCurrentStatusText(`Error: ${errorMessage}`);
      toast.error(`${errorMessage}`);
    } finally {
      setTimeout(() => {
        setModalOpen(false);
      }, 2000);
    }
  };
  return (
    <div className="flex flex-col p-4 pt-0 space-y-4">
      {/* Header */}
      <div className="flex flex-col">
        <div className="flex items-center space-x-3 mt-2">
          <Tags size={24} className="text-emerald-600" />
          <h2 className="text-xl font-semibold">
            Auto Suggest Metadata Schema
          </h2>
        </div>
        <div className="text-gray-500 text-sm text-left italic">
          Create intelligent metadata schema based on your context
        </div>
      </div>
      <Accordion
        elevation={0}
        className="mt-4"
        sx={{
          "&:before": {
            display: "none", // Removes the top divider line
          },
          border: `1px solid ${getFetchUI()?.borderColor}`,
          borderRadius: 1,
          // backgroundColor: "#f0f4ec",
          backgroundColor: `white`,
        }}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          expandIcon={fetchState === "error" ? <ExpandMoreOutlined /> : null}
        >
          <div className="flex flex-row items-center h-full">
            {getFetchUI()?.Icon
              ? React.createElement(getFetchUI()?.Icon, {
                  style: {
                    color: `${getFetchUI()?.iconColor}`,
                    height: "24px",
                    width: "24px",
                  },
                })
              : null}
            <span
              className="font-medium ml-4"
              style={{ color: `${getFetchUI()?.textColor}` }}
            >
              {oobHealthStatusText}
            </span>
          </div>
        </AccordionSummary>
        {fetchState === "error" && (
          <AccordionDetails>
            <span className="font-medium text-left">
              To cover all the files, please run classify on OOB tags first.
            </span>
            <ComputeEstimation
              tagDefs={{}}
              selectedFiles={[]}
              selectedTags={[]}
            />
            <Button
              onClick={handleClick}
              variant="contained"
              className="w-full pt-6"
              style={{
                textTransform: "none",
                backgroundColor: "#4dac94",
                marginTop: "12px",
                marginLeft: "auto",
              }}
            >
              Run Classify
            </Button>
          </AccordionDetails>
        )}
      </Accordion>

      {/* Context Selection */}
      <div className="space-y-2">
        <label className="text-sm font-medium text-gray-700">
          Choose context:
        </label>
        <div className="flex border rounded-md divide-x">
          {[
            ["File", FileText],
            ["Chunk", Boxes],
            ["Existing Tags", Tags],
          ].map(([option, Icon]) => (
            <button
              key={option}
              onClick={() => setInfoContext(option)}
              className={`flex-1 py-2 px-4 flex items-center justify-center space-x-2 ${
                infoContext === option
                  ? "bg-emerald-50 text-emerald-600"
                  : "text-gray-600 hover:bg-gray-50"
              }`}
            >
              <Icon size={18} />
              <span>{option}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Advanced Features Section */}
      <div className="border rounded-lg">
        <button
          onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
          className="w-full flex items-center justify-between p-4 hover:bg-gray-50"
        >
          <div className="flex items-center space-x-3">
            <Settings size={20} className="text-gray-400" />
            <span className="font-medium">Advanced Features</span>
          </div>
          <ChevronDown
            className={`transform transition-transform ${
              isAdvancedOpen ? "rotate-180" : ""
            }`}
          />
        </button>

        {isAdvancedOpen && (
          <div className="p-4 space-y-4 border-t">
            {/* File Selection */}
            <div className="space-y-2">
              <label className="text-sm font-medium">Select Files</label>
              <button
                onClick={() => setOpenFilePicker(true)}
                className="w-full border rounded-md p-2 text-left text-gray-600 hover:bg-gray-50"
              >
                {selectedFiles.length === 0
                  ? "No files selected"
                  : `${selectedFiles.length} file(s) selected`}
              </button>
            </div>

            {/* Other inputs converted to Tailwind */}
            <div className="space-y-2">
              <label className="text-sm font-medium">Input Context</label>
              <textarea
                className="w-full rounded-md p-2"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Tag Schema Generation Context"
              />
            </div>

            {/* Number inputs */}
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Number of Tags</label>
                <input
                  type="number"
                  className="w-full rounded-md p-2"
                  value={numTags}
                  onChange={(e) => setNumTags(e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Max Tree Depth</label>
                <input
                  type="number"
                  className="w-full rounded-md p-2"
                  value={maxDepth}
                  onChange={(e) => setMaxDepth(e.target.value)}
                />
              </div>
            </div>

            {/* Tag Type Selection */}
            <div className="space-y-2">
              <label className="text-sm font-medium">Tag Type</label>
              <select
                className="w-full border rounded-md p-2"
                value={tagType}
                onChange={(e) => setTagType(e.target.value)}
              >
                <option value="Open-Ended">Open-Ended</option>
                <option value="Yes/No">Yes/No</option>
                <option value="Both">Both</option>
              </select>
            </div>
          </div>
        )}
      </div>

      {/* Action Button */}
      <button
        onClick={async (e) => {
          e.preventDefault();
          await handleClick();
        }}
        className="w-full py-2 px-4 bg-primary text-white rounded-md hover:bg-primary/90 transition-colors"
      >
        Auto Suggest
      </button>

      {/* Modal */}
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-lg font-semibold mb-4">Auto-Creating tags</h3>
            <div className="flex items-center space-x-4">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-primary" />
              <p>{currentStatusText}...</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
