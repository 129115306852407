import { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useContext } from "react";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { metadataService } from "../../../../../services/api";
import {
  TruncatedText,
  TableHeader,
  renderAvailableTagValues,
} from "./TagUtils";

const TagsTable = ({
  columns = ["Name", "Description", "Available Values", "Actions"],
  hideNewTagButton = false,
  forceExpanded = false,
  tagInEditor,
  setTagInEditor,
  isTagEditorCollapsed,
  setIsTagEditorCollapsed,
  compressedTagTable = false,
}) => {
  const tagTableCompressed = !forceExpanded && compressedTagTable;
  if (tagTableCompressed) {
    columns = [];
  }

  const { savedTags, setSavedTags, deasyUserConfig } = useContext(BaseContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  // Ensure savedTags is always an array and handle initial loading state
  const tags = Array.isArray(savedTags) ? savedTags : [];

  const filteredTags = tags.filter(
    (tag) =>
      tag?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tag?.description?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const totalPages = Math.ceil(filteredTags.length / rowsPerPage);

  const handleEdit = (tag) => {
    // Clear previous selection and set new tag
    const { isNewlySaved, ...cleanTag } = tag;
    setTagInEditor(cleanTag);
  };

  const handleDelete = async (tag) => {
    try {
      if (
        !window.confirm(
          `Are you sure you want to delete the tag "${tag.name}"?`,
        )
      ) {
        return;
      }

      if (tag.tag_id) {
        await metadataService.deleteTag(
          tag.tag_id,
          deasyUserConfig.deasyApiKey,
        );
        const response = await metadataService.getSavedTags(
          deasyUserConfig.deasyApiKey,
        );
        setSavedTags(response.data.tags || []);
      } else {
        setSavedTags((prev) => prev.filter((t) => t.name !== tag.name));
      }

      if (
        tagInEditor?.tag_id === tag.tag_id ||
        tagInEditor?.name === tag.name
      ) {
        setTagInEditor({});
      }
    } catch (error) {
      console.error("Failed to delete tag:", error);
    }
  };

  const handleNewTag = () => {
    setIsTagEditorCollapsed(false);
    setTagInEditor({});
  };

  return (
    <div className={`bg-white rounded-lg shadow-md flex flex-col h-full`}>
      <div className="flex-shrink-0">
        <TableHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onNewTag={handleNewTag}
          hideNewTagButton={hideNewTagButton}
        />
      </div>

      <div className={`flex-1 overflow-auto relative`}>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 z-10">
            <tr>
              {columns.map((header) => (
                <th
                  key={header}
                  className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 ${
                    header === "Description" && !isTagEditorCollapsed
                      ? "w-48"
                      : ""
                  }`}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 relative z-0">
            {!Array.isArray(savedTags) ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-6 py-12 text-center text-gray-500"
                >
                  Loading...
                </td>
              </tr>
            ) : filteredTags.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-6 py-12 text-center text-gray-500"
                >
                  No tags found
                </td>
              </tr>
            ) : (
              filteredTags
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((tag) => {
                  const isSelected =
                    (tagInEditor?.tag_id &&
                      tagInEditor.tag_id === tag.tag_id) ||
                    (!tagInEditor?.tag_id && tagInEditor?.name === tag.name);

                  return (
                    <tr
                      key={tag.tag_id || tag.name}
                      className={`cursor-pointer ${
                        isSelected
                          ? "bg-green-50 hover:opacity-80"
                          : "hover:bg-gray-50"
                      }`}
                      onClick={() => handleEdit(tag)}
                    >
                      <td className="flex flex-col px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-left">
                        <TruncatedText text={tag.name} maxLength={30} />
                        {tagTableCompressed && (
                          <>
                            <div className="text-gray-500 italic pb-1">
                              <TruncatedText
                                text={tag.description}
                                maxLength={
                                  !forceExpanded && !isTagEditorCollapsed
                                    ? 35
                                    : 150
                                }
                              />
                            </div>
                            <div>{renderAvailableTagValues(tag)}</div>
                          </>
                        )}
                      </td>
                      {!tagTableCompressed && (
                        <>
                          <td
                            className={`px-6 py-4 text-sm text-gray-500 text-left ${
                              !forceExpanded && !isTagEditorCollapsed
                                ? "max-w-[12rem]"
                                : ""
                            }`}
                          >
                            <TruncatedText
                              text={tag.description}
                              maxLength={
                                !forceExpanded && !isTagEditorCollapsed
                                  ? 40
                                  : 100
                              }
                            />
                          </td>

                          <td className="px-6 py-4 text-sm text-left">
                            {renderAvailableTagValues(tag)}
                          </td>
                        </>
                      )}
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div
                          className="flex gap-2 justify-center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(tag);
                            }}
                            className="shadow-sm text-red-600 hover:text-red-900 p-1 rounded-full bg-red-50 hover:bg-red-100"
                            title="Delete tag"
                          >
                            <FaTrashAlt className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
            )}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="flex-shrink-0 bg-white px-4 py-2 flex items-center justify-between border-t border-gray-200">
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-700">Rows per page:</span>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setPage(0);
              }}
              className="border border-gray-300 rounded-md text-sm p-1"
            >
              {[10, 30, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setPage(Math.max(0, page - 1))}
              disabled={page === 0}
              className="px-3 py-1 border border-gray-300 rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Previous
            </button>
            <span className="text-sm text-gray-700">
              Page {page + 1} of {Math.max(1, totalPages)}
            </span>
            <button
              onClick={() => setPage(Math.min(totalPages - 1, page + 1))}
              disabled={page >= totalPages - 1}
              className="px-3 py-1 border border-gray-300 rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagsTable;
