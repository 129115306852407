import React, { useState, useContext } from "react";
import { Tab, Tabs } from "@mui/material";
import { GraphContext } from "../../../../../contexts/GraphContext";
import WorkflowProgress from "../../../../utils/WorkflowProgress";
import { UseCaseSelector } from "./UseCaseTabs/UseCaseSelector";
import ExportMetadataScreen from "./ExportMetadataScreen";

const UseCaseManagementScreen = () => {
  const [selectedStep, setSelectedStep] = useState(0);
  const { selectedDiscoveryGraph } = useContext(GraphContext);

  const renderTabContent = () => {
    switch (selectedStep) {
      case 0:
        return <div>Base Graph Placeholder</div>;
      case 1:
        return <UseCaseSelector />;
      case 2:
        return <div>Data Quality Checks Placeholder</div>;
      case 3:
        return <div>Configure Use Case Placeholder</div>;
      case 4:
        return <div>Extract Metadata Placeholder</div>;
      case 5:
        return <ExportMetadataScreen includeProgress={false} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-full">
      <WorkflowProgress currentStep={2} />
      <div className="border-b px-4">
        <Tabs
          value={selectedStep}
          onChange={(e, newValue) => setSelectedStep(newValue)}
          variant="fullWidth"
          TabIndicatorProps={{
            style: { backgroundColor: "#4dac94" },
          }}
          sx={{
            minHeight: "32px",
            "& .MuiTabs-flexContainer": {
              gap: "0px",
            },
          }}
        >
          <Tab
            label="Select Base Graph"
            tooltip="Select the base graph for the use case"
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              textTransform: "none",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
          <Tab
            label="Select Use Case"
            tooltip="Select the use case to use"
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              textTransform: "none",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
          <Tab
            label="Data Quality Checks"
            tooltip="To be released in a future version"
            disabled={true}
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              textTransform: "none",
              cursor: "not-allowed",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
          <Tab
            label="Configure Use Case"
            tooltip="Configure the use case"
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              textTransform: "none",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
          <Tab
            label="Extract Metadata"
            tooltip="Extract metadata from the use case"
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              textTransform: "none",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
          <Tab
            label="Export Data"
            tooltip="Export the data of the use case"
            disabled={!selectedDiscoveryGraph}
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              textTransform: "none",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
        </Tabs>
      </div>
      <div className="flex-1 px-6 py-5">{renderTabContent()}</div>
    </div>
  );
};

export default UseCaseManagementScreen;
