import { WorkflowModel, WorkflowStatus } from "../models/WorkflowModel";

const DUMMY_WORKFLOW_ID = "evaluation";

export const mockWorkflows = [
  new WorkflowModel({
    id: "wf-1",
    name: "Data Import Pipeline",
    status: WorkflowStatus.SYNCED,
    clusters_count: 2,
    tags_extracted: 4505,
    vector_database: "Qdrant",
    auto_sync: true,
    config: {
      sources: ["s3", "local"],
      schedule: "daily",
    },
  }),
  new WorkflowModel({
    id: "wf-2",
    name: "Customer Data Analysis",
    status: WorkflowStatus.SYNCED,
    clusters_count: 2,
    tags_extracted: 4505,
    vector_database: "Qdrant",
    auto_sync: true,
    config: {
      data_types: ["chat", "email"],
      tags: ["sentiment", "intent"],
    },
  }),
  new WorkflowModel({
    id: "wf-3",
    name: "Product Feedback Analysis",
    status: WorkflowStatus.SYNCED,
    clusters_count: 5,
    tags_extracted: 8234,
    vector_database: "Qdrant",
    auto_sync: true,
    config: {
      data_types: ["reviews", "feedback"],
      tags: ["product", "feature", "bug"],
    },
  }),
  new WorkflowModel({
    id: "wf-4",
    name: "Support Ticket Classification",
    status: WorkflowStatus.SYNCED,
    clusters_count: 3,
    tags_extracted: 2156,
    vector_database: "Qdrant",
    auto_sync: false,
    config: {
      data_types: ["tickets", "chat"],
      tags: ["priority", "category", "resolution"],
    },
  }),
  new WorkflowModel({
    id: "wf-5",
    name: "Knowledge Base Export",
    status: WorkflowStatus.SYNCED,
    clusters_count: 4,
    tags_extracted: 3789,
    vector_database: "Qdrant",
    auto_sync: true,
    config: {
      export_format: ["json", "csv"],
      schedule: "weekly",
    },
  }),
];

// Mock data for tag tree
export const TAG_TREE_DATA1 = {
  year: {
    TagAvailableValues: [2019, 2020, 2021, 2022, 2023],
    2017: {
      us_president: {
        TagAvailableValues: ["Trump", "Biden"],
      },
    },
  },
};
export const TAG_TREE_DATA = {
  is_financial_data: {
    TagAvailableValues: ["Yes", "No"],
    Yes: {
      includes_net_income: {
        TagAvailableValues: ["Yes", "No"],
        No: {
          includes_revenue: {
            TagAvailableValues: ["Yes", "No"],
            Yes: {
              cost_of_goods_sold: {
                TagAvailableValues: [],
              },
            },
          },
          year: {
            TagAvailableValues: [2019, 2020, 2021, 2022, 2023],
            2017: {
              us_president: {
                TagAvailableValues: ["Trump", "Biden"],
              },
            },
          },
        },
      },
    },
  },
};

// Mock data for vector databases
export const mockVectorDatabases = [
  {
    id: "vdb-1",
    name: "Qdrant",
    status: "active",
    connection_string: "http://localhost:6333",
    collections: ["products", "customers", "support"],
  },
  {
    id: "vdb-2",
    name: "Weaviate",
    status: "inactive",
    connection_string: "http://localhost:8080",
    collections: ["articles", "images"],
  },
];

// Mock data for tags
export const mockTags = [
  {
    id: "tag-1",
    name: "Customer Sentiment",
    description: "Identifies customer sentiment in feedback",
    type: "classification",
    values: ["positive", "negative", "neutral"],
  },
  {
    id: "tag-2",
    name: "Product Category",
    description: "Product category classification",
    type: "multi-label",
    values: ["electronics", "clothing", "food", "accessories"],
  },
];

// Mock data for metadata schemas
export const mockMetadataSchemas = [
  {
    id: "schema-1",
    name: "Customer Feedback Schema",
    fields: [
      { name: "sentiment", type: "string", required: true },
      { name: "category", type: "string", required: true },
      { name: "priority", type: "number", required: false },
    ],
  },
  {
    id: "schema-2",
    name: "Product Data Schema",
    fields: [
      { name: "name", type: "string", required: true },
      { name: "description", type: "text", required: true },
      { name: "price", type: "number", required: true },
      { name: "categories", type: "array", required: false },
    ],
  },
];

// Export all mock data as a single object
export const mockData = {
  workflows: mockWorkflows,
  vectorDatabases: mockVectorDatabases,
  tags: mockTags,
  metadataSchemas: mockMetadataSchemas,
  DUMMY_WORKFLOW_ID: DUMMY_WORKFLOW_ID,
};
