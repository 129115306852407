const cleanSchema = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const cleanedObj = {};

  for (const [key, value] of Object.entries(obj)) {
    // Skip description and output_type fields
    if (key === "description" || key === "output_type") {
      continue;
    }

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      // Get available values if they exist
      const availableValues = value.TagAvailableValues || [];

      // Clean nested object
      const cleanedValue = cleanSchema(value);

      // If object has TagAvailableValues, validate branches
      if ("TagAvailableValues" in value) {
        // Get all keys except TagAvailableValues and special fields
        const branches = Object.keys(cleanedValue).filter(
          (k) =>
            k !== "TagAvailableValues" &&
            k !== "description" &&
            k !== "output_type",
        );

        // For each branch, check if it's in TagAvailableValues
        // If not, remove it
        branches.forEach((branch) => {
          if (
            !availableValues.includes(branch) &&
            availableValues[0] !== "<ANY>"
          ) {
            delete cleanedValue[branch];
          }
        });

        // If TagAvailableValues is empty and we have branches, remove TagAvailableValues
        if (availableValues.length === 0) {
          delete cleanedValue.TagAvailableValues;
        }
      }

      cleanedObj[key] = cleanedValue;
    } else {
      cleanedObj[key] = value;
    }
  }

  return cleanedObj;
};

const flattenMap = (obj, parentKey = "") => {
  const result = {};
  for (const key in obj) {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (
        obj[key].description !== undefined &&
        obj[key].output_type !== undefined
      ) {
        result[currentKey] = {
          name: key,
          label: key,
          description: obj[key].description,
          output_type: obj[key].output_type,
          availableValues: obj[key]?.TagAvailableValues,
        };
      }
      const nested = flattenMap(obj[key], currentKey);
      Object.assign(result, nested);
    }
  }

  return result;
};

export { cleanSchema, flattenMap };
