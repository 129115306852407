import FileList from "../../Utils/FileList";

const FileSelectionDropdown = ({ selectedFiles, setSelectedFiles }) => {
  return (
    <div className="h-full flex flex-col">
      <div className="p-4 border-b border-gray-200 bg-gray-50">
        <div className="flex items-center justify-between">
          <h2 className="font-semibold">File Selector</h2>
          <span className="px-2.5 py-1 bg-white text-sm text-gray-600 rounded-full border border-gray-200">
            {selectedFiles.length} selected
          </span>
        </div>
      </div>
      <div className="flex-1 p-2">
        <FileList
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          setError={() => {}}
          showTitle={false}
        />
      </div>
    </div>
  );
};

export default FileSelectionDropdown;
