import React from "react";
import TaggingStudio from "../TaggingStudio/TaggingStudio";

const MetadataStudio = () => {
  return (
    <div className="h-full">
      <TaggingStudio />
    </div>
  );
};

export default MetadataStudio;
