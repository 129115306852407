import { tokenService } from "../../../services/api";
import { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";

export const LoadingSpinner = () => (
  <svg
    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

export const FormInput = ({
  label,
  type,
  value,
  onChange,
  placeholder,
  disabled,
}) => (
  <div>
    <label
      className={`block text-sm font-medium text-gray-700 mb-1 ${
        disabled ? "opacity-50" : ""
      }`}
    >
      {label}
    </label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 ${
        disabled ? "bg-gray-100 opacity-50" : ""
      }`}
      placeholder={placeholder}
      required
      disabled={disabled}
      autoComplete="off"
    />
  </div>
);

export const updateTokenServiceSecret = (secretName, secretValue) => {
  try {
    tokenService.storeSecret(secretName, secretValue);
  } catch (err) {
    console.error("Error updating token service secret:", err);
  }
};

export const ConfigurationSelector = ({
  type,
  configs,
  activeConfig,
  onLoad,
  onSaveNew,
  onDelete,
  disabled,
}) => {
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newConfigName, setNewConfigName] = useState("");
  const [selectedConfig, setSelectedConfig] = useState(activeConfig || "");

  // Update selected config when activeConfig changes
  useEffect(() => {
    setSelectedConfig(activeConfig || "");
    if (activeConfig) {
      setIsAddingNew(false);
    }
  }, [activeConfig]);

  const handleSaveNew = () => {
    if (newConfigName.trim()) {
      // Check if name already exists
      if (configs[newConfigName.trim()]) {
        alert("A configuration with this name already exists.");
        return;
      }
      onSaveNew(newConfigName.trim());
      setNewConfigName("");
      setIsAddingNew(false);
    }
  };

  const handleDelete = (e, configName) => {
    e.stopPropagation();
    if (window.confirm(`Are you sure you want to delete "${configName}"?`)) {
      onDelete(configName);
    }
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedConfig(value);

    if (value === "new") {
      setIsAddingNew(true);
    } else {
      setIsAddingNew(false);
      onLoad(value);
    }
  };

  return (
    <div className="mb-4">
      <div className="flex flex-col gap-2">
        <label className="block text-sm font-medium text-gray-700">
          {type === "vectorDB" ? "Vector DB Profile" : "LLM Profile"}
        </label>

        <div className="flex gap-2">
          <div className="flex-1">
            <select
              value={isAddingNew ? "new" : selectedConfig}
              onChange={handleSelectChange}
              className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                         focus:ring-emerald-500 focus:border-emerald-500 bg-white
                         ${disabled ? "bg-gray-100" : ""}`}
              disabled={disabled}
            >
              <option value="">Select a profile</option>
              {Object.keys(configs || {}).map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
              <option value="new">+ Create new profile</option>
            </select>
          </div>

          {activeConfig && !isAddingNew && (
            <button
              onClick={(e) => handleDelete(e, activeConfig)}
              className="px-3 py-2 bg-red-600 text-white rounded-md hover:bg-red-700
                         disabled:bg-gray-400 disabled:cursor-not-allowed
                         transition-colors duration-200"
              disabled={disabled}
              title="Delete configuration"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </div>

        {isAddingNew && (
          <div className="flex gap-2">
            <input
              type="text"
              value={newConfigName}
              onChange={(e) => setNewConfigName(e.target.value)}
              placeholder="Enter profile name"
              className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                         focus:ring-emerald-500 focus:border-emerald-500"
              autoFocus
            />
            <button
              onClick={handleSaveNew}
              disabled={!newConfigName.trim()}
              className="px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 
                         disabled:bg-gray-400 disabled:cursor-not-allowed
                         transition-colors duration-200"
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsAddingNew(false);
                setNewConfigName("");
              }}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200
                         transition-colors duration-200"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const Toast = ({ message, type = "success", show, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed bottom-4 right-4 z-50">
        <div
          className={`rounded-lg shadow-lg p-4 max-w-sm w-full 
          ${
            type === "success"
              ? "bg-emerald-50 text-emerald-800 border border-emerald-200"
              : type === "error"
                ? "bg-red-50 text-red-800 border border-red-200"
                : "bg-blue-50 text-blue-800 border border-blue-200"
          }`}
        >
          <div className="flex items-center">
            {type === "success" && (
              <svg
                className="h-5 w-5 text-emerald-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {type === "error" && (
              <svg
                className="h-5 w-5 text-red-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {type === "info" && (
              <svg
                className="h-5 w-5 text-blue-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            <p className="ml-3 text-sm font-medium">{message}</p>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const useToast = () => {
  const [toast, setToast] = useState({
    show: false,
    message: "",
    type: "success",
  });

  const showToast = (message, type = "success") => {
    setToast({ show: true, message, type });
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  return { toast, showToast, hideToast };
};
