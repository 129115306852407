import React, { useContext, useState } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import TagsTable from "../SavedTags/components/TagTables/TagsTable";
import TagEditor from "../SavedTags/components/TagEditor/TagEditor";
import { FaTimes, FaChevronRight } from "react-icons/fa";

const MetadataLibrary = () => {
  const { deasyUserConfig, setDeasyUserConfig, savedTags } =
    useContext(BaseContext);
  const [isTagEditorCollapsed, setIsTagEditorCollapsed] = useState(false);
  const [tagInEditor, setTagInEditor] = useState({});
  const [apiKeyInput, setApiKeyInput] = useState("");

  if (!deasyUserConfig.deasyApiKey || savedTags === null) {
    return (
      <div className="flex items-center justify-center overflow-hidden">
        <div className="w-full max-w-md p-8 bg-white rounded-xl shadow-sm">
          <div className="text-center mb-8">
            <div className="w-16 h-16 bg-emerald-50 rounded-full flex items-center justify-center mx-auto mb-4">
              <svg
                className="w-8 h-8 text-emerald-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Welcome to Metadata Schemas
            </h2>
            <p className="text-gray-600">
              Please configure your API key to get started
            </p>
          </div>

          <form
            onSubmit={() => {
              setDeasyUserConfig((prev) => ({
                ...prev,
                deasyApiKey: apiKeyInput,
              }));
            }}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Deasy API Key
              </label>
              <div className="relative rounded-lg shadow-sm">
                <input
                  type="password"
                  value={apiKeyInput}
                  onChange={(e) => setApiKeyInput(e.target.value)}
                  className="block w-full pr-10 border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 transition-all duration-200"
                  placeholder="Enter your API key"
                  required
                />
                {apiKeyInput && (
                  <button
                    type="button"
                    onClick={() => setApiKeyInput("")}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    <FaTimes className="h-4 w-4 text-gray-400 hover:text-gray-600" />
                  </button>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-colors duration-200"
            >
              Configure API Key
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full bg-gray-50 rounded-lg overflow-hidden">
      {/* Left Panel - Schema List */}
      <div
        className={`flex flex-col ${
          isTagEditorCollapsed ? "w-full" : "w-[55%]"
        } bg-white border-r border-gray-200 transition-all duration-300`}
      >
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-800">
              Metadata Schemas
            </h2>
          </div>
        </div>

        <div className="flex-1 overflow-hidden">
          <TagsTable
            tagInEditor={tagInEditor}
            setTagInEditor={setTagInEditor}
            isTagEditorCollapsed={isTagEditorCollapsed}
            setIsTagEditorCollapsed={setIsTagEditorCollapsed}
          />
        </div>
      </div>

      {/* Right Panel - Tag Editor */}
      <div
        className={`${
          isTagEditorCollapsed ? "w-0" : "w-[45%]"
        } bg-white border-l border-gray-200 transition-all duration-300 relative`}
      >
        <button
          onClick={() => setIsTagEditorCollapsed(!isTagEditorCollapsed)}
          className={`absolute -left-28 top-6 z-10 p-1.5 bg-white border border-gray-200 rounded-full shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-all duration-200 ${isTagEditorCollapsed ? "-left-28 " : "-left-9"}`}
        >
          {isTagEditorCollapsed ? (
            <p className=" text-gray-600 whitespace-nowrap"> Open Editor </p>
          ) : (
            <FaChevronRight />
          )}
        </button>
        <div
          className={`h-full ${isTagEditorCollapsed ? "invisible" : "visible"}`}
        >
          <TagEditor
            tagInEditor={tagInEditor}
            setTagInEditor={setTagInEditor}
            isTagEditorCollapsed={isTagEditorCollapsed}
            setIsTagEditorCollapsed={setIsTagEditorCollapsed}
          />
        </div>
      </div>
    </div>
  );
};

export default MetadataLibrary;
