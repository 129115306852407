import React from "react";
import {
  FaProjectDiagram,
  FaBook,
  FaPlug,
  FaKey,
  FaTags,
} from "react-icons/fa";

const MainNavigation = ({ onItemSelect, selectedItem }) => {
  const navItems = [
    { id: "workflows", label: "Deasy workflows", icon: FaProjectDiagram },
    { id: "metadata", label: "Metadata library", icon: FaBook },
    { id: "metadata-studio", label: "Metadata studio", icon: FaTags },
    { id: "connections", label: "Data connections", icon: FaPlug },
    { id: "tokens", label: "API Keys & Tokens", icon: FaKey },
  ];

  const getItemClass = (itemId) => {
    const isSelected = selectedItem === itemId;
    return `flex cursor-pointer text-left p-1 gap-2 transition-colors w-full justify-between items-center duration-200 whitespace-nowrap ${
      isSelected ? "text-emerald-400" : "text-gray-400 hover:text-emerald-300"
    }`;
  };

  return (
    <nav className="h-screen w-30 bg-[#1a1f2b] flex flex-col items-center py-4 px-4">
      <div className="mb-8">
        <img src="/deasy-icon.png" alt="Deasy Labs" className="w-8 h-8" />
      </div>

      <div className="flex flex-col space-y-6">
        {navItems.map((item) => {
          const Icon = item.icon;
          return (
            <div
              key={item.id}
              className={getItemClass(item.id)}
              onClick={() => onItemSelect(item.id)}
            >
              <div className="flex items-center gap-2">
                <Icon size={15} />
                <p>{item.label}</p>
              </div>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

export default MainNavigation;
