import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
} from "reactflow";
import "reactflow/dist/style.css";
import { nodeTypes } from "./Graph/GraphNodes";
import { GraphContext } from "../../../../../contexts/GraphContext";
import { useContext } from "react";
import { AddNodeSidePanel } from "./Graph/AddNodeSidePanel";
import { metadataService } from "../../../../../services/api";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { auth } from "../../../../../config/firebase";
import { FiRefreshCw } from "react-icons/fi";
import { useEffect } from "react";
import { graphService } from "../../../../../services/api";
import { FiSave } from "react-icons/fi";
import { prepareVectorDBConfiguration } from "../../../../../services/utils";

const processData = (data, rootDatasetSize = 0) => {
  let nodeId = 0;
  const rootNodeId = `node_${nodeId++}`;
  const nodes = [
    {
      id: rootNodeId,
      type: "root",
      position: { x: 0, y: 0 },
      data: {
        label: "Root",
        datasetSize: rootDatasetSize,
        position: { x: 0, y: 0 },
        nodePath: [],
      },
    },
  ];
  const edges = [];
  const HORIZONTAL_SPACING = 200;
  const VERTICAL_SPACING = 150;
  const levelRightmostX = new Map();

  const processNode = (
    node,
    depth = 0,
    parentId = null,
    parentX = 0,
    currentPath = [],
  ) => {
    const isQuestion = depth % 2 === 0 || depth === 0;

    Object.entries(node).forEach(([key, value], index) => {
      if (!isQuestion && key !== "TagAvailableValues") return;

      const currentId = `node_${nodeId++}`;
      const newPath = [...currentPath, key];

      if (key === "TagAvailableValues") {
        const startX =
          levelRightmostX.get(depth) ||
          parentX - (value.length * HORIZONTAL_SPACING) / 2;

        // combine the available values with the other keys
        const allNodes = [
          ...Object.keys(node).filter((key) => key !== "TagAvailableValues"),
          ...node.TagAvailableValues,
        ];
        const uniqueNodes = [...new Set(allNodes)].sort();
        uniqueNodes.forEach((availableValue, valueIndex) => {
          const valueNodeId = `node_${nodeId++}`;
          const xPos = startX + valueIndex * HORIZONTAL_SPACING;
          const valuePath = [...currentPath, availableValue];

          nodes.push({
            id: valueNodeId,
            type: "value",
            position: { x: xPos, y: (1 + depth) * VERTICAL_SPACING },
            data: {
              id: valueNodeId,
              label: availableValue,
              isOutOfSync: !node.TagAvailableValues.includes(availableValue),
              nodePath: valuePath,
              parentId: parentId,
            },
          });

          levelRightmostX.set(depth, xPos + HORIZONTAL_SPACING);

          if (parentId) {
            edges.push({
              id: `edge_${parentId}_${valueNodeId}`,
              source: parentId,
              target: valueNodeId,
              type: "step",
            });
          }

          if (availableValue in node) {
            processNode(
              node[availableValue],
              depth + 1,
              valueNodeId,
              xPos,
              valuePath,
            );
          }
        });
      } else {
        const xPos = levelRightmostX.get(depth) || 0;
        nodes.push({
          id: currentId,
          type: "question",
          position: { x: xPos, y: (1 + depth) * VERTICAL_SPACING },
          data: {
            id: currentId,
            label: key,
            nodePath: newPath,
            parentId: parentId,
            isOpenEnded: value?.TagAvailableValues?.length === 0,
          },
        });

        levelRightmostX.set(depth, xPos + HORIZONTAL_SPACING);

        edges.push({
          id: `edge_${parentId}_${currentId}`,
          source: parentId || rootNodeId,
          target: currentId,
          type: "step",
        });

        if (value && typeof value === "object") {
          processNode(value, depth + 1, currentId, xPos, newPath);
        }
      }
    });
  };

  processNode(data);
  return { nodes, edges };
};

const WorkflowSummaryTab = () => {
  const { deasyUserConfig } = useContext(BaseContext);
  const {
    setGraphs,
    selectedNodeData,
    setSelectedNodeData,
    setSidePanelOpen,
    hierarchyStats,
    setHierarchyStats,
    refreshHierarchyStats,
    discoveryGraphData,
    setDiscoveryGraphData,
    selectedDiscoveryGraph,
  } = useContext(GraphContext);

  // Fetch initial stats when the screen opens
  useEffect(() => {
    const fetchInitialStats = async () => {
      try {
        const activeVdbConfig =
          deasyUserConfig.vdbmConfig?.Configs?.[
            deasyUserConfig.vdbmConfig?.LastActive
          ] || {};

        const activeLlmConfig =
          deasyUserConfig.llmConfig?.Configs?.[
            deasyUserConfig.llmConfig?.LastActive
          ] || {};

        const data = {
          vector_db_config: prepareVectorDBConfiguration({
            ...activeVdbConfig,
            user: auth.currentUser.email,
          }),
          endpoint_manager_config: activeLlmConfig,
          conditions: [],
          current_tree: JSON.stringify(discoveryGraphData) || [],
        };

        console.log("Fetching initial stats");
        const response = await metadataService.getHierarchyCountDistributions(
          data,
          deasyUserConfig.deasyApiKey,
        );

        if (response.data?.hierarchy) {
          console.log("Received initial stats:", response.data.hierarchy);
          setHierarchyStats(response.data.hierarchy);
        }
      } catch (err) {
        console.error("Error fetching initial stats:", err);
      }
    };

    if (deasyUserConfig?.deasyApiKey) {
      fetchInitialStats();
    }
  }, [
    deasyUserConfig.deasyApiKey,
    deasyUserConfig.vdbmConfig?.Configs,
    deasyUserConfig.vdbmConfig?.LastActive,
    deasyUserConfig.llmConfig?.Configs,
    deasyUserConfig.llmConfig?.LastActive,
    setHierarchyStats,
    discoveryGraphData,
  ]);

  // Get the total count from hierarchyStats
  const totalCount = hierarchyStats?.file_count || 0;

  const { nodes: initialNodes, edges: initialEdges } = processData(
    discoveryGraphData,
    totalCount,
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  // Update nodes when hierarchyStats changes
  useEffect(() => {
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      discoveryGraphData,
      hierarchyStats?.file_count || 0,
    );
    setNodes(updatedNodes);
    setEdges(updatedEdges);
  }, [hierarchyStats, discoveryGraphData, setNodes, setEdges]);

  const handleNodeAddClicked = (nodeData, nodeType) => {
    setSelectedNodeData({ ...nodeData, nodeType });
    setSidePanelOpen(true);
  };

  const removeNodeFromStructure = (data, nodePath) => {
    let current = data;

    // Handle empty path or root node
    if (!nodePath || nodePath.length === 0) {
      return;
    }

    // Navigate to parent node
    for (let i = 0; i < nodePath.length - 1; i++) {
      const pathItem = nodePath[i];
      if (!(pathItem in current)) {
        return; // Node path is invalid
      }
      current = current[pathItem];
    }

    const targetNodeName = nodePath[nodePath.length - 1];

    // Delete the node
    if (current) {
      delete current[targetNodeName];

      // Only filter TagAvailableValues if it exists
      if (
        current.TagAvailableValues &&
        Array.isArray(current.TagAvailableValues)
      ) {
        current.TagAvailableValues = current.TagAvailableValues.filter(
          (value) => value !== targetNodeName,
        );
      }

      // Clean up empty objects
      if (
        Object.keys(current).length === 0 ||
        (Object.keys(current).length === 1 &&
          current.TagAvailableValues?.length === 0)
      ) {
        delete current.TagAvailableValues;
      }
    }
  };

  const handleNodeDeletion = (nId) => {
    // Get all descendant nodes recursively
    const nodesToDelete = new Set();

    const collectDescendants = (currentId) => {
      nodesToDelete.add(currentId);
      const childEdges = edges.filter((edge) => edge.source === currentId);
      childEdges.forEach((edge) => collectDescendants(edge.target));
    };

    collectDescendants(nId);

    // Update the discoveryGraphData by removing nodes
    const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));

    nodesToDelete.forEach((nodeId) => {
      const node = nodes.find((n) => n.id === nodeId);
      if (node) {
        removeNodeFromStructure(updatedData, node.data.nodePath);
      }
    });

    // Re-process the graph
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      updatedData,
      1000,
    );

    setDiscoveryGraphData(updatedData);
    setGraphs((prev) =>
      prev.map((graph) =>
        graph.graph_id === selectedDiscoveryGraph.graph_id
          ? { ...graph, graph_data: updatedData }
          : graph,
      ),
    );
    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };

  const handleAddNodeApplied = (selectedNodeData, tag) => {
    if (!selectedNodeData || !tag) return;

    // Create a deep copy of the data structure
    const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));
    // Helper function to navigate and update the data structure
    const addNodeToStructure = (data, nodePath, newTag) => {
      let current = data;

      if (newTag?.option === "yesNo") newTag.available_values = ["Yes", "No"];
      if (newTag?.option === "aiGenerated") newTag.available_values = ["<ANY>"];

      if (selectedNodeData.label === "Root") {
        // For root node, add directly to the top level
        current[newTag.name] = {
          TagAvailableValues: newTag.available_values || [],
        };
      } else if (selectedNodeData.nodeType === "value") {
        // Add a question node after a value node
        // Navigate to the correct position
        for (let i = 0; i < nodePath.length; i++) {
          const pathItem = nodePath[i];
          if (!(pathItem in current)) {
            current[pathItem] = {};
          }
          current = current[pathItem];
        }

        current[newTag.name] = {
          TagAvailableValues: newTag.available_values || [],
        };
      } else {
        // For question nodes, we want to insert the new tag one level above
        // Navigate to one level before the target position

        for (let i = 0; i < nodePath.length - 2; i++) {
          const pathItem = nodePath[i];
          if (!(pathItem in current)) {
            current[pathItem] = {};
          }
          current = current[pathItem];
        }

        // Store the existing question node data
        const targetNodeName = nodePath[nodePath.length - 2];
        const existingNodeData = JSON.parse(
          JSON.stringify(current[targetNodeName] || {}),
        );

        // Make each value point to the existing node structure
        current[targetNodeName][newTag.name] = {
          TagAvailableValues: newTag.available_values || [],
        };

        if (newTag.available_values) {
          newTag.available_values.forEach((value) => {
            current[targetNodeName][newTag.name][value] = existingNodeData;
          });
        }

        // Remove the original node if it exists
        if (current[targetNodeName][selectedNodeData.label]) {
          delete current[targetNodeName][selectedNodeData.label];
        }
      }
    };

    // Add the new node to the data structure
    if (Array.isArray(tag)) {
      tag.forEach((t) =>
        addNodeToStructure(updatedData, selectedNodeData.nodePath || [], t),
      );
    } else {
      addNodeToStructure(updatedData, selectedNodeData.nodePath || [], tag);
    }

    // Update the graph
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      updatedData,
      1000,
    );

    setDiscoveryGraphData(updatedData);
    setGraphs((prev) =>
      prev.map((graph) =>
        graph.graph_id === selectedDiscoveryGraph.graph_id
          ? { ...graph, graph_data: updatedData }
          : graph,
      ),
    );
    setNodes(updatedNodes);
    setEdges(updatedEdges);
    setSidePanelOpen(false);
    setSelectedNodeData(null);
  };

  const nodesWithCallbacks = nodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      onDeleteNode: handleNodeDeletion,
      onAddNode: handleNodeAddClicked,
      handleAddNodeApplied: handleAddNodeApplied,
    },
  }));

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = (event) => {
    event.preventDefault();

    let tag;
    try {
      const draggedItem = event.dataTransfer.getData("draggedItem");
      tag = draggedItem ? JSON.parse(draggedItem) : null;
    } catch (e) {
      console.error("Error parsing dragged item:", e);
      return;
    }

    if (!tag) return;

    // Set the selected node data for the root if no node is selected
    if (!selectedNodeData) {
      setSelectedNodeData({
        label: "Root",
        nodeType: "root",
        nodePath: [],
      });
    }

    // Wait for state update before calling handleAddNodeApplied
    setTimeout(() => {
      handleAddNodeApplied(selectedNodeData, tag);
    }, 0);
  };

  const handleSaveGraph = async () => {
    await graphService.createUpdateGraph(
      deasyUserConfig.deasyApiKey,
      selectedDiscoveryGraph.graph_id,
      selectedDiscoveryGraph.graph_name,
      selectedDiscoveryGraph.graph_description,
      selectedDiscoveryGraph.graph_data,
    );
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold">Extract Dataset Metadata</h2>
        <div className="flex items-center gap-2">
          <button
            onClick={() => refreshHierarchyStats(deasyUserConfig.deasyApiKey)}
            className="flex items-center gap-2 px-3 py-1.5 text-sm bg-white rounded-md shadow-sm
                     hover:shadow transition-all duration-200 text-gray-600 hover:text-primary
                     border border-gray-200 hover:border-primary"
            title="Refresh metadata stats"
          >
            <FiRefreshCw className="w-4 h-4" />
            <span>Refresh Stats</span>
          </button>
          <button
            onClick={handleSaveGraph}
            className="flex items-center gap-2 px-3 py-1.5 text-sm bg-white rounded-md shadow-sm
                     hover:shadow transition-all duration-200 text-gray-600 hover:text-primary
                     border border-gray-200 hover:border-primary"
            title="Save Graph"
          >
            <FiSave className="w-4 h-4" />
            <span>Save Graph</span>
          </button>
        </div>
      </div>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodesWithCallbacks}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          fitView
          onDragOver={onDragOver}
          onDrop={onDrop}
          defaultEdgeOptions={{
            type: "step",
            style: { stroke: "#d1d5db" },
          }}
        >
          <Background />
        </ReactFlow>
        <AddNodeSidePanel handleAddNodeApplied={handleAddNodeApplied} />
      </ReactFlowProvider>
    </div>
  );
};

export { WorkflowSummaryTab, processData };
