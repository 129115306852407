import React from "react";
import { FaTrash } from "react-icons/fa";

function TokenTable({ tokens, onDeleteToken }) {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  if (tokens.length === 0) {
    return (
      <div
        style={{
          textAlign: "center",
          padding: "40px",
          backgroundColor: "#f8f9fa",
          borderRadius: "12px",
          border: "2px dashed #dee2e6",
          color: "#6c757d",
          fontSize: "1.1rem",
        }}
      >
        No tokens created yet.
      </div>
    );
  }

  return (
    <div
      style={{
        overflowX: "auto",
        borderRadius: "12px",
        boxShadow: "0 4px 16px rgba(0,0,0,0.1)",
      }}
    >
      <table
        style={{
          width: "100%",
          borderCollapse: "separate",
          borderSpacing: "0",
          backgroundColor: "white",
        }}
      >
        <thead>
          <tr style={{ backgroundColor: "#f8f9fa" }}>
            <th
              style={{
                padding: "16px",
                textAlign: "left",
                borderBottom: "2px solid #dee2e6",
                color: "#495057",
                fontWeight: "600",
                fontSize: "0.95rem",
                whiteSpace: "nowrap",
              }}
            >
              Key ID
            </th>
            <th
              style={{
                padding: "16px",
                textAlign: "left",
                borderBottom: "2px solid #dee2e6",
              }}
            >
              Username
            </th>
            <th
              style={{
                padding: "16px",
                textAlign: "left",
                borderBottom: "2px solid #dee2e6",
              }}
            >
              Created At
            </th>
            <th
              style={{
                padding: "16px",
                textAlign: "left",
                borderBottom: "2px solid #dee2e6",
              }}
            >
              Last Used
            </th>
            <th
              style={{
                padding: "16px",
                textAlign: "left",
                borderBottom: "2px solid #dee2e6",
              }}
            >
              Status
            </th>
            <th
              style={{
                padding: "16px",
                textAlign: "left",
                borderBottom: "2px solid #dee2e6",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {tokens.map((token) => (
            <tr
              key={token.token_id}
              style={{
                borderBottom: "1px solid #dee2e6",
                transition: "background-color 0.2s",
                ":hover": { backgroundColor: "#f8f9fa" },
              }}
            >
              <td
                style={{
                  padding: "16px",
                  textAlign: "left",
                  color: "#212529",
                  fontSize: "0.95rem",
                }}
              >
                {token.token_id}
              </td>
              <td style={{ padding: "16px", textAlign: "left" }}>
                {token.username}
              </td>
              <td style={{ padding: "16px", textAlign: "left" }}>
                {formatDate(token.created_at)}
              </td>
              <td style={{ padding: "16px", textAlign: "left" }}>
                {formatDate(token.last_used)}
              </td>
              <td style={{ padding: "16px", textAlign: "left" }}>
                <span
                  style={{
                    padding: "6px 12px",
                    borderRadius: "20px",
                    backgroundColor:
                      token.status === "active" ? "#e7f5ea" : "#fbe9e7",
                    color: token.status === "active" ? "#0a7c1c" : "#c62828",
                    fontSize: "0.875rem",
                    fontWeight: "500",
                  }}
                >
                  {token.status}
                </span>
              </td>
              <td style={{ padding: "16px", textAlign: "left" }}>
                <button
                  onClick={() => onDeleteToken(token.token_id)}
                  style={{
                    background: "none",
                    border: "1px solid #dc3545",
                    color: "#dc3545",
                    cursor: "pointer",
                    padding: "8px 12px",
                    borderRadius: "6px",
                    transition: "all 0.2s",
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    fontSize: "0.875rem",
                    ":hover": {
                      backgroundColor: "#dc3545",
                      color: "white",
                    },
                  }}
                  title="Delete API key"
                >
                  <FaTrash /> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TokenTable;
