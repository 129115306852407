import React, { useContext, useState } from "react";
import ReactFlow, { Background, Controls, MiniMap } from "reactflow";
import "reactflow/dist/style.css";
import { processData } from "../DataDiscovery/WorkflowSummaryTab";
import { nodeTypes, findNodeStats } from "../DataDiscovery/Graph/GraphNodes";
import { GraphContext } from "../../../../../contexts/GraphContext";

const UseCaseDefinitionView = ({ workflowData, onSelectionChange }) => {
  const {
    selectedNodes,
    setSelectedNodes,
    discoveryGraphData,
    hierarchyStats,
  } = useContext(GraphContext);
  const [view, setView] = useState("graph");

  // Use discoveryGraphData instead of metadataTree
  const { nodes: initialNodes, edges: initialEdges } = processData(
    discoveryGraphData,
    hierarchyStats?.file_count || 1000,
  );

  // Convert the graph data into table format
  const tableData = {
    columns: [
      { field: "tag", headerName: "TAG", width: 200 },
      { field: "tagValue", headerName: "TAG_VALUE", width: 150 },
      { field: "parentTag", headerName: "Parent TAG", width: 200 },
      { field: "parentTagValue", headerName: "Parent TAG Value", width: 200 },
      { field: "count", headerName: "Data Points", width: 120 },
    ],
    rows: [
      ...initialNodes
        .filter((node) => node.type === "root")
        .map((node) => ({
          id: node.id,
          tag: "Root",
          tagValue: "",
          parentTag: "",
          parentTagValue: "",
          count: node.data.datasetSize || 0,
        })),
      ...initialNodes
        .filter((node) => node.type === "value")
        .map((node) => {
          const parentEdge = initialEdges.find(
            (edge) => edge.target === node.id,
          );
          const immediateParent = parentEdge
            ? initialNodes.find((n) => n.id === parentEdge.source)
            : null;

          const findNearestValueParent = (currentNode) => {
            if (!currentNode) return null;
            const parentEdge = initialEdges.find(
              (edge) => edge.target === currentNode.id,
            );
            const parent = parentEdge
              ? initialNodes.find((n) => n.id === parentEdge.source)
              : null;

            if (!parent) return null;
            if (parent.type === "value") return parent;
            return findNearestValueParent(parent);
          };

          const valueTypeParent = findNearestValueParent(node);

          return {
            id: node.id,
            tag: immediateParent?.data.label || "",
            tagValue: node.data.label,
            parentTag: valueTypeParent
              ? initialNodes.find(
                  (n) =>
                    n.id ===
                    initialEdges.find((e) => e.target === valueTypeParent.id)
                      ?.source,
                )?.data.label
              : "",
            parentTagValue: valueTypeParent?.data.label || "",
            count: node.data.nodeStats?.file_count || 0,
          };
        }),
    ],
  };

  // Add node selection callback to nodes
  const nodesWithCallbacks = initialNodes.map((node) => ({
    ...node,
    selected: selectedNodes.some((n) => n.id === node.id),
    data: {
      ...node.data,
      hideControls: true,
      onNodeClick: (nodeData) => {
        const newSelection = [...selectedNodes];
        const index = newSelection.findIndex((n) => n.id === nodeData.id);
        if (index !== -1) {
          newSelection.splice(index, 1);
        } else {
          newSelection.push(nodeData);
        }
        setSelectedNodes(newSelection);
        onSelectionChange({
          type: "graph",
          selection: newSelection,
        });
      },
    },
  }));

  const handleRowClick = (row) => {
    const node = initialNodes.find((n) => n.id === row.id);
    if (node) {
      setSelectedNodes((prev) => {
        const newSelection = [...prev];
        const nodeIndex = newSelection.findIndex((n) => n.id === node.id);

        if (nodeIndex !== -1) {
          const nodePath = node.data.nodePath;

          // Remove the current node and all nodes whose paths start with this node's path
          return newSelection.filter((n) => {
            const isChild =
              n.data.nodePath.slice(0, nodePath.length).join(">") !==
              nodePath.join(">");
            return isChild;
          });
        }
        // If node is not selected, select it and all its children
        else {
          const nodePath = node.data.nodePath;
          const descendants = [];

          // Find all nodes that have this node's path as their prefix
          initialNodes.forEach((n) => {
            // Only include nodes of type "value"
            if (n.data.nodePath && n.type === "value") {
              const currentPath = n.data.nodePath;
              const isDescendant =
                currentPath.slice(0, nodePath.length).join(">") ===
                nodePath.join(">");

              if (isDescendant) {
                const nodeStats = findNodeStats(
                  hierarchyStats,
                  n.data.nodePath,
                );
                descendants.push({
                  id: n.id,
                  data: { nodePath: n.data.nodePath, label: n.data.label },
                  stats: {
                    fileCount: nodeStats?.file_count || 0,
                    percentage: nodeStats?.percentage || 0,
                  },
                });
              }
            }
          });

          return [...newSelection, ...descendants];
        }
      });

      onSelectionChange({
        type: "table",
        selection: selectedNodes,
      });
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* View Toggle */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-2">
          <button
            onClick={() => setView("graph")}
            className={`px-4 py-2 rounded-md ${
              view === "graph"
                ? "bg-primary text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
            }`}
          >
            Graph View
          </button>
          <button
            onClick={() => setView("table")}
            className={`px-4 py-2 rounded-md ${
              view === "table"
                ? "bg-primary text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
            }`}
          >
            Table View
          </button>
        </div>
        <div className="text-sm text-gray-500">
          {selectedNodes.length} nodes selected
        </div>
      </div>

      {/* View Content */}
      {view === "graph" ? (
        <div className="flex-1 border rounded-lg overflow-hidden">
          <ReactFlow
            nodes={nodesWithCallbacks}
            edges={initialEdges}
            nodeTypes={nodeTypes}
            fitView
            defaultEdgeOptions={{
              type: "step",
              style: { stroke: "#d1d5db" },
            }}
          >
            <Background />
            <Controls />
            <MiniMap />
          </ReactFlow>
        </div>
      ) : (
        <div className="flex-1 border rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {tableData.columns.map((column) => (
                  <th
                    key={column.field}
                    className={`px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider ${
                      column.field === "count" ? "text-right" : "text-left"
                    }`}
                  >
                    {column.headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {tableData.rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => handleRowClick(row)}
                  className={`cursor-pointer hover:bg-gray-50 ${
                    selectedNodes.some((n) => n.id === row.id)
                      ? "bg-primary/5"
                      : ""
                  }`}
                >
                  {tableData.columns.map((column) => (
                    <td
                      key={`${row.id}-${column.field}`}
                      className={`px-6 py-4 whitespace-nowrap text-sm text-gray-900 ${
                        column.field === "count" ? "text-right" : "text-left"
                      }`}
                    >
                      {row[column.field]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UseCaseDefinitionView;
