import { Card } from "@mui/material";
import { CircleEllipsis, Calculator, Brain } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { metadataService, tokenService } from "../../../../../../services/api";
import {
  prepareEndpointManagerConfig,
  prepareVectorDBConfiguration,
} from "../../../../../../services/utils";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { GraphContext } from "../../../../../../contexts/GraphContext";

const ComputeEstimation = ({ selectedFiles, selectedTags, tagDefs }) => {
  const { deasyUserConfig } = useContext(BaseContext);
  const { discoveryGraphData } = useContext(GraphContext);
  const vectorDBConfiguration =
    deasyUserConfig.vdbmConfig.Configs[deasyUserConfig.vdbmConfig.LastActive];
  const llmEndpointConfiguration =
    deasyUserConfig.llmConfig.Configs[deasyUserConfig.llmConfig.LastActive];
  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const [estTokens, setEstTokens] = useState("Estimating...");
  const [remainingTokens, setRemainingTokens] = useState("Estimating...");
  const [nodesAffected, setNodesAffected] = useState("Estimating...");
  useEffect(() => {
    setEstTokens("Estimating...");
    setNodesAffected("Estimating...");
    async function estimateClassify() {
      const filteredKeys = Object.keys(tagDefs)
        .filter((key) => selectedTags.includes(key))
        .reduce((filteredObj, key) => {
          filteredObj[key] = tagDefs[key];
          return filteredObj;
        }, {});
      const response = await metadataService.estimateClassify(
        prepareVectorDBConfiguration(vectorDBConfiguration),
        prepareEndpointManagerConfig(llmEndpointConfiguration),
        selectedFiles,
        filteredKeys,
        deasyApiKey,
        discoveryGraphData,
      );
      const tokenResponse = await tokenService.listTokens();
      const availableCredits = tokenResponse.data?.available_credits;
      const creditProportion = (
        response.data?.tokens / (availableCredits || 1)
      ).toFixed(2);
      setRemainingTokens(`${creditProportion}% of remaining balance`);
      setEstTokens(response.data?.tokens || 0);
      setNodesAffected(response.data?.nodes || 0);
    }
    try {
      estimateClassify();
    } catch (error) {
      console.log("Error estimating classify...", error);
      setRemainingTokens("---");
      setEstTokens("---");
      setNodesAffected("---");
    }
    // eslint-disable-next-line
  }, [selectedFiles, selectedTags]);
  return (
    <Card
      variant="outlined"
      elevation={0}
      className="w-full mt-6"
      sx={{ backgroundColor: "transparent" }}
    >
      <div className="p-4">
        <h4 className="text-lg font-medium text-gray-900 mb-6 flex items-center gap-2">
          <Calculator className="w-4 h-4 text-gray-500" />
          Compute, token & time estimation
        </h4>

        <div className="space-y-4">
          <div className="flex items-center">
            <div className="w-5">
              <svg
                className="w-4 h-4 text-gray-500"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <rect x="2" y="4" width="20" height="16" rx="2" />
                <path d="M6 8h12M6 12h12M6 16h8" />
              </svg>
            </div>
            <div className="ml-2">
              <div className="text-sm text-gray-600 text-left">
                Deasy Tokens
              </div>
              <div className="flex items-center gap-1">
                <span className="text-sm font-medium text-gray-900 text-left">
                  {estTokens}
                </span>
                <span className="text-xs text-gray-500 text-left">
                  ({remainingTokens})
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-5">
              <Brain className="w-4 h-4 text-gray-500" />
            </div>
            <div className="ml-2">
              <div className="text-sm text-gray-600 text-left">LLM compute</div>
              <div className="text-sm text-gray-900 text-left">Yes</div>
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-5">
              <CircleEllipsis className="w-4 h-4 text-gray-500" />
            </div>
            <div className="ml-2">
              <div className="text-sm text-gray-600 text-left">
                Total points affected
              </div>
              <div className="text-sm text-gray-900 text-left">
                {nodesAffected}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ComputeEstimation;
