import { FiPlus, FiSearch } from "react-icons/fi";

// Add this new component for truncated text with tooltip
export const TruncatedText = ({ text, maxLength = 50 }) => {
  const truncatedText =
    text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  return (
    <div className="truncate" title={text}>
      {truncatedText}
    </div>
  );
};

// Utility functions
export const getTagType = (tag) => {
  const types = {
    yesNo: { label: "Yes/No", style: "bg-green-100 text-green-800" },
    custom: { label: "Defined values", style: "bg-gray-100 text-blue-800" },
    aiGenerated: { label: "Open ended", style: "bg-gray-100 text-blue-800" },
  };
  return (
    types[tag.option] || {
      label: "Unknown",
      style: "bg-gray-100 text-gray-600",
    }
  );
};

export const renderAvailableTagValues = (tag) => {
  if (
    !Array.isArray(tag.available_values) ||
    tag.available_values.length === 0
  ) {
    const typeMap = {
      number: "Any number",
      date: "Any date",
      text: "Any text",
    };
    return (
      <span className="text-xs text-gray-500 italic">
        {typeMap[tag.output_type] || "Any text"}
      </span>
    );
  }

  return (
    <div className="flex flex-wrap gap-1">
      {tag.available_values.map((value, idx) => (
        <span
          key={idx}
          className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded-full"
        >
          {value}
        </span>
      ))}
    </div>
  );
};

export const TableHeader = ({
  searchTerm,
  setSearchTerm,
  onNewTag,
  hideNewTagButton,
}) => (
  <div className="p-4 flex gap-4 items-center border-b border-gray-200 sticky top-0 bg-white z-10">
    <div className="relative flex-grow">
      <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
      <input
        type="text"
        placeholder="Search tags..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
      />
    </div>
    {!hideNewTagButton && (
      <button
        onClick={onNewTag}
        className="flex items-center gap-2 px-4 py-2 btn bg-white border-2 text-primary border-primary whitespace-nowrap"
      >
        <FiPlus className="w-5 h-5" />
        <span>New Tag</span>
      </button>
    )}
  </div>
);
