import { createContext, useState, useEffect } from "react";
import { FaChartLine, FaDatabase } from "react-icons/fa";
import { auth } from "../config/firebase";
import { config } from "../config/config";
import { mockData } from "../mocks/mockData";
import { WorkflowType, WorkflowStatus } from "../models/WorkflowModel";
import { prepareVectorDBConfiguration } from "../services/utils";
import { metadataService } from "../services/api";

export const BaseContext = createContext();

export const BaseProvider = ({ children }) => {
  // Page configuration
  const activePageToData = {
    tokenDashboard: {
      title: "API keys and credits",
      helpDescription: "Manage your Deasy API keys and add credits",
      icon: <FaChartLine className="w-5 h-5" />,
    },
    vectorDatabase: {
      title: "Vector Database",
      helpDescription: "Configure your Vector Database",
      icon: <FaDatabase className="w-5 h-5" />,
    },
  };
  const disabledPages = config.features.disabledPages;
  const [deasyUserConfig, setDeasyUserConfig] = useState(
    config.deasyUserConfig,
  );

  const [activePage, setActivePage] = useState("");
  const [metadataHealth, setMetadataHealth] = useState(null);
  const [explorerLevelSelection, setExplorerLevelSelection] = useState("FILE");
  const [activeTagEditorSection, setActiveTagEditorSection] =
    useState("general");
  const [savedTags, setSavedTags] = useState(() => {
    try {
      // Try to get from localStorage if exists
      const saved = localStorage.getItem("savedTags");
      return saved ? JSON.parse(saved) : [];
    } catch {
      return [];
    }
  });
  const [tagInEditor, setTagInEditor] = useState({});
  const [isTagEditorCollapsed, setIsTagEditorCollapsed] = useState(true);
  const [taggingStudioOutput, setTaggingStudioOutput] = useState(null);

  // Use mock data from the mocks file
  const { workflows: mockWorkflows } = mockData;

  // Add effect to persist savedTags
  useEffect(() => {
    if (Array.isArray(savedTags)) {
      localStorage.setItem("savedTags", JSON.stringify(savedTags));
    }
  }, [savedTags]);

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/token") {
      setActivePage("tokenDashboard");
    } else if (path === "/") {
      setActivePage("");
    }
  }, []);

  // Utility functions
  const signOut = () => {
    setDeasyUserConfig(config.deasyUserConfig);
    setActivePage("");
    setMetadataHealth(null);
    setSavedTags([]);
    setTagInEditor({});
    setTaggingStudioOutput(null);
    auth.signOut();
  };

  const exportData = ({ type = "json" }) => {
    if (!savedTags?.length) return;

    if (type === "json") {
      const jsonData = savedTags.reduce((acc, tag) => {
        const { tag_id, username, created_at, updated_at, ...cleanedTag } = tag;
        acc[tag.name] = cleanedTag;
        return acc;
      }, {});

      const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "tags.json";
      a.click();
    }
  };

  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);

  const [files, setFiles] = useState([]);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);

  const getFiles = async ({ newOffset = offset, newLimit = limit }) => {
    try {
      setFetchingFiles(true);
      if (
        !(
          deasyUserConfig.deasyApiKey &&
          deasyUserConfig.vdbmConfig.LastActive &&
          deasyUserConfig.vdbmConfig.Configs?.[
            deasyUserConfig.vdbmConfig.LastActive
          ]
        )
      ) {
        return { entities: [], next_offset: null };
      }
      const vectorDBConfiguration =
        deasyUserConfig.vdbmConfig.Configs[
          deasyUserConfig.vdbmConfig.LastActive
        ];

      const response = await metadataService.listPaginated(
        prepareVectorDBConfiguration(vectorDBConfiguration),
        deasyUserConfig.deasyApiKey,
        newLimit,
        newOffset,
        "file",
        files,
      );
      const entities = response.entities;
      const next_offset = response.next_offset;

      setFiles((prev) => [...new Set([...prev, ...entities])]);
      setOffset(next_offset);
      setLimit(newLimit);
      return { entities, next_offset };
    } catch (err) {
      console.log("Error fetching files", err);
      return { entities: [], next_offset: null };
    } finally {
      setFetchingFiles(false);
    }
  };

  return (
    <BaseContext.Provider
      value={{
        // Getters
        activePage,
        activePageToData,
        metadataHealth,
        savedTags,
        tagInEditor,
        isTagEditorCollapsed,
        disabledPages,
        taggingStudioOutput,
        activeTagEditorSection,
        deasyUserConfig,
        explorerLevelSelection,
        mockWorkflows,
        selectedWorkflow,
        currentNode,
        files,
        fetchingFiles,
        // Setters
        setActivePage,
        setMetadataHealth,
        setSavedTags,
        setTagInEditor,
        setIsTagEditorCollapsed,
        setTaggingStudioOutput,
        setActiveTagEditorSection,
        setDeasyUserConfig,
        setExplorerLevelSelection,
        setSelectedWorkflow,
        setCurrentNode,
        setFiles,
        setFetchingFiles,
        // Functions
        signOut,
        exportData,
        WorkflowStatus,
        WorkflowType,
        getFiles,
      }}
    >
      {children}
    </BaseContext.Provider>
  );
};
