import React, { useContext } from "react";
import { WorkflowContext } from "../../../contexts/WorkflowContext";
import WorkflowConfigurationScreen from "./screens/WorkflowConfigurationScreen";
import ConnectDataScreen from "./screens/ConnectDataScreen";
import DataDiscoveryScreen from "./screens/DataDiscovery/DataDiscoveryScreen";
import UseCaseManagementScreen from "./screens/UseCase/UseCaseManagementScreen";
import UseCaseDataDiscoveryScreen from "./screens/UseCase/UseCaseDataDiscoveryScreen";
import UseCaseSummaryScreen from "./screens/UseCase/UseCaseSummaryScreen";
import AllWorkflows from "./AllWorkflows";
import WorkflowContextBanner from "./WorkflowContextBanner";
import { WorkflowType } from "../../../models/WorkflowModel";
import ExportMetadataScreen from "./screens/UseCase/ExportMetadataScreen";

const WorkflowScreens = ({ selectedWorkflowType }) => {
  const { selectedWorkflow, currentScreen } = useContext(WorkflowContext);

  // Handle the "all" workflows view
  if (currentScreen === "all") {
    return <AllWorkflows />;
  }

  // Show configuration screen when creating/editing
  if (selectedWorkflowType === "configure") {
    return <WorkflowConfigurationScreen />;
  }

  // Don't show workflow screens if no workflow is selected
  if (!selectedWorkflow) {
    return <AllWorkflows />;
  }

  // For specific workflow types, show the workflow screens
  switch (currentScreen) {
    case WorkflowType.CONNECT_DATA:
      return <ConnectDataScreen />;
    case WorkflowType.DATA_DISCOVERY:
      return <DataDiscoveryScreen />;
    case WorkflowType.USE_CASE_MANAGEMENT:
      return <UseCaseManagementScreen />;
    case WorkflowType.USE_CASE_DATA_DISCOVERY:
      return (
        <UseCaseDataDiscoveryScreen
          useCase={selectedWorkflow.selectedUseCase}
        />
      );
    case WorkflowType.USE_CASE_SUMMARY:
      return (
        <UseCaseSummaryScreen useCase={selectedWorkflow.selectedUseCase} />
      );
    case WorkflowType.EXPORT_METADATA:
      return <ExportMetadataScreen />;
    case WorkflowType.COMPLETE:
      return <div>Workflow Complete!</div>;
    default:
      return <ConnectDataScreen />;
  }
};

const Workflows = ({ selectedWorkflowType }) => {
  return (
    <div className="flex flex-col h-full overflow-x-hidden">
      <WorkflowContextBanner />
      <div className="flex-1 min-h-0 overflow-auto">
        <WorkflowScreens selectedWorkflowType={selectedWorkflowType} />
      </div>
    </div>
  );
};

export default Workflows;
