import React from "react";
import ReactDOM from "react-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export const NodeInfoModal = ({
  nodeData,
  nodeType,
  onClose,
  portalTarget = document.body,
}) => {
  // Mock data for the chart
  const mockTimeSeriesData = [
    { month: "Jan", count: 4000 },
    { month: "Feb", count: 3000 },
    { month: "Mar", count: 2000 },
    { month: "Apr", count: 2780 },
    { month: "May", count: 1890 },
    { month: "Jun", count: 2390 },
  ];

  const modalContent = (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000] animate-fadeIn"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div
        className="bg-white rounded-lg p-6 w-[900px] max-h-[80vh] overflow-y-auto mx-4 animate-slideIn"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-gray-900">{nodeData.label}</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 p-1"
          >
            ✕
          </button>
        </div>

        <div className="space-y-8">
          {/* Basic Info Section */}
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
              Overview
            </h3>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <p className="text-sm text-gray-500 mb-1">Total Datasets</p>
                <p className="text-2xl font-semibold text-gray-900">
                  {nodeData?.tagDistributionData?.datasetCount?.toLocaleString() ||
                    0}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500 mb-1">Distribution</p>
                <p className="text-2xl font-semibold text-gray-900">
                  {nodeData?.tagDistributionData?.datasetDistribution || "0%"}
                </p>
              </div>
            </div>
          </div>

          {/* Time Series Chart */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
              Dataset Growth Over Time
            </h3>
            <div className="bg-white h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={mockTimeSeriesData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                  <XAxis dataKey="month" tick={{ fill: "#6B7280" }} />
                  <YAxis tick={{ fill: "#6B7280" }} />
                  <Tooltip />
                  <Bar dataKey="count" fill="#4F46E5" radius={[4, 4, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Additional Details */}
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
              Additional Information
            </h3>
            <div className="space-y-3">
              <div className="flex justify-between items-center border-b border-gray-200 pb-3">
                <span className="text-gray-500">Created</span>
                <span className="text-gray-900">
                  {new Date().toLocaleDateString()}
                </span>
              </div>
              <div className="flex justify-between items-center border-b border-gray-200 pb-3">
                <span className="text-gray-500">Last Updated</span>
                <span className="text-gray-900">
                  {new Date().toLocaleDateString()}
                </span>
              </div>
              {nodeType === "question" && nodeData.isOpenEnded && (
                <div className="pt-2">
                  <p className="text-gray-500 italic">
                    This is an open-ended tag
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, portalTarget);
};
