import React, { useState } from "react";
import { tokenService } from "../../../../services/api";

function AddCreditsModal({ onClose }) {
  const [selectedCredits, setSelectedCredits] = useState("100");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const creditOptions = [
    { value: "500", label: "500 Credits ($50)" },
    { value: "5000", label: "5,000 Credits ($100)" },
    { value: "250000", label: "250,000 Credits ($1,000)" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await tokenService.createPaymentSession(
        parseInt(selectedCredits),
      );
      window.location.href = response.data.url;
    } catch (err) {
      setError("Failed to initialize payment: " + err.message);
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          width: "90%",
          maxWidth: "400px",
        }}
      >
        <h3 style={{ marginTop: 0 }}>Add Credits</h3>

        {error && (
          <div style={{ color: "red", marginBottom: "10px" }}>{error}</div>
        )}

        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "20px" }}>
            <label style={{ display: "block", marginBottom: "10px" }}>
              Select Credits Amount:
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                backgroundColor: "#f8f9fa",
                padding: "15px",
                borderRadius: "4px",
              }}
            >
              {creditOptions.map((option) => (
                <label
                  key={option.value}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "8px",
                    backgroundColor:
                      selectedCredits === option.value
                        ? "#e2e6ea"
                        : "transparent",
                    borderRadius: "4px",
                    transition: "background-color 0.2s",
                  }}
                >
                  <input
                    type="radio"
                    value={option.value}
                    checked={selectedCredits === option.value}
                    onChange={(e) => setSelectedCredits(e.target.value)}
                    style={{ marginRight: "10px" }}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary"
              style={{
                flex: 1,
                cursor: "pointer",
              }}
            >
              {loading ? (
                "Processing..."
              ) : (
                <>
                  Add Credits
                  <br />
                  (Stripe checkout)
                </>
              )}
            </button>
            <button
              onClick={onClose}
              type="button"
              disabled={loading}
              className="btn btn-secondary"
              style={{
                flex: 1,
                cursor: loading ? "not-allowed" : "pointer",
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddCreditsModal;
