import { tokenService } from "../services/api";
import { metadataService } from "../services/api";
import { config } from "../config/config";

export const prepareVectorDBConfiguration = (vectorDBConfiguration) => {
  return {
    classname: vectorDBConfiguration.type,
    collection_name: vectorDBConfiguration.collection,
    url: vectorDBConfiguration.url,
    api_key: vectorDBConfiguration.apiKey,
    dimension_threshold: vectorDBConfiguration.dimensionThreshold,
  };
};

export const prepareEndpointManagerConfig = (endpointManagerConfig) => {
  return {
    model: "gpt-4o-mini",
    embedding_model: "text-embedding-3-small",
    api_key: endpointManagerConfig.llmApiKey,
    vendor: endpointManagerConfig.llmType.toLowerCase(),
    // seed: 42,
    // max_tokens: 1000,
    temperature: 0,
    // rpm: 1000,
    // tpm: 1000,
  };
};

export const prepareTagForTS = (tag) => {
  const tagName = tag.name;
  return {
    [tagName]: {
      name: tagName,
      description: tag.description,
      availableValues: tag.available_values,
      examples: tag.examples,
      option: tag.option,
      output_type: tag.output_type,
    },
  };
};

export const fetchUserSecrets = async (setSavedTags, setDeasyUserConfig) => {
  // Initialize default values
  const defaults = {
    deasyApiKey: "",
    vectorDBConfiguration: config.vectorDB.default,
    deasyUserConfig: config.deasyUserConfig,
  };
  let deasyApiKey = defaults.deasyApiKey;
  let vectorDBConfiguration = defaults.vectorDBConfiguration;

  try {
    // Load and parse user config
    const userConfigResult = await tokenService.getSecret("deasyUserConfig");
    const deasyUserConfig =
      JSON.parse(userConfigResult.data.secret) || defaults.deasyUserConfig;
    setDeasyUserConfig(deasyUserConfig);

    // Load API key and tags
    try {
      deasyApiKey = deasyUserConfig.deasyApiKey;
      const savedTags = deasyApiKey
        ? (await metadataService.getSavedTags(deasyApiKey)).data.tags || []
        : [];
      setSavedTags(savedTags);
    } catch (error) {
      setSavedTags([]);
    }

    return {
      deasyApiKey,
      vectorDBConfiguration,
    };
  } catch (err) {
    // Reset to defaults on error
    setDeasyUserConfig(defaults.deasyUserConfig);
    setSavedTags(defaults.savedTags);

    return {
      deasyApiKey: defaults.deasyApiKey,
      vectorDBConfiguration: defaults.vectorDBConfiguration,
    };
  }
};

export const isValidDBConfigured = (deasyUserConfig) => {
  return Boolean(
    deasyUserConfig.deasyApiKey &&
      deasyUserConfig.vdbmConfig.Configs?.[
        deasyUserConfig.vdbmConfig.LastActive
      ],
  );
};
