import { useContext } from "react";
import { WorkflowContext } from "../../../../../../contexts/WorkflowContext";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { toast } from "react-hot-toast";
import { processData } from "../../DataDiscovery/WorkflowSummaryTab";
import { useState } from "react";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { metadataService } from "../../../../../../services/api";
import { WorkflowType } from "../../../../../../models/WorkflowModel";
import UseCaseDefinitionView from "../UseCaseDefinitionView";

export const UseCaseSelector = () => {
  const { deasyUserConfig } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const {
    setCurrentScreen,
    selectedWorkflow,
    updateWorkflow,
    useCases,
    refreshUseCasesHook,
  } = useContext(WorkflowContext);
  const {
    selectedNodes,
    setSelectedNodes,
    selectedDiscoveryGraph,
    setSelectedDiscoveryGraph,
    graphs,
  } = useContext(GraphContext);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [showUseCaseConfig, setShowUseCaseConfig] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const handleNewUseCase = () => {
    setSelectedUseCase(null);
    setFormData({ name: "", description: "" });
    setShowUseCaseConfig(true);
  };

  const handleEditUseCase = (useCase, e) => {
    e.stopPropagation();

    const matchingGraph = graphs.find(
      (graph) => graph.graph_id === useCase.graphId,
    );
    if (!matchingGraph) {
      toast.error(
        "Graph not found. Please recreate the graph for the use case.",
      );
      return;
    }

    // Check if graphs are in sync
    if (
      JSON.stringify(useCase.latestGraph) !==
      JSON.stringify(matchingGraph.graph_data)
    ) {
      toast.error(
        "Use case graph is out of sync with discovery graph. Please recreate the use case with the latest graph or adjust the graph",
        {
          duration: 10000,
          style: {
            padding: "20px",
            minWidth: "500px",
            fontSize: "16px",
          },
        },
      );
    }

    // Set the graph data from the usecase
    const graphToSet = {
      graph_id: useCase.graphId,
      graph_data: useCase.latestGraph,
      graph_name: matchingGraph.graph_name, // Use name from matching graph
    };
    setSelectedDiscoveryGraph(graphToSet);

    // Process the graph data to get the proper node structure
    if (useCase.latestGraph) {
      const { nodes } = processData(useCase.latestGraph);

      // Find matching nodes based on parent node paths
      if (useCase.selectedParentNodes) {
        const matchingNodes = nodes.filter((node) => {
          if (!node.data.nodePath) return false;

          // Convert node path to pairs
          const nodePairs = new Set();
          for (let i = 0; i < node.data.nodePath.length; i += 2) {
            if (node.data.nodePath[i] && node.data.nodePath[i + 1]) {
              nodePairs.add(
                `${node.data.nodePath[i]}:${node.data.nodePath[i + 1]}`,
              );
            }
          }

          // Check if any parent node's pairs match exactly
          return useCase.selectedParentNodes.some((parentNode) => {
            if (!parentNode.data.nodePath) return false;

            // Convert parent path to pairs
            const parentPairs = new Set();
            for (let i = 0; i < parentNode.data.nodePath.length; i += 2) {
              if (
                parentNode.data.nodePath[i] &&
                parentNode.data.nodePath[i + 1]
              ) {
                parentPairs.add(
                  `${parentNode.data.nodePath[i]}:${parentNode.data.nodePath[i + 1]}`,
                );
              }
            }

            // Compare sets
            return (
              nodePairs.size === parentPairs.size &&
              [...nodePairs].every((pair) => parentPairs.has(pair))
            );
          });
        });

        setSelectedNodes(matchingNodes);
      }
    } else {
      console.warn("No graph data found");
      setSelectedNodes([]);
    }

    // Set form data and show config
    setSelectedUseCase(useCase);
    setFormData({
      name: useCase.name,
      description: useCase.description,
    });
    setShowUseCaseConfig(true);
  };

  const handleExportUseCase = (useCase, e) => {
    e.stopPropagation();
    if (selectedWorkflow) {
      updateWorkflow(selectedWorkflow.id, {
        ...selectedWorkflow,
        selectedUseCase: useCase,
      });
    }
    setCurrentScreen(WorkflowType.EXPORT_METADATA);
  };

  const handleDeleteUseCase = async (useCaseId, e) => {
    e.stopPropagation();
    if (selectedWorkflow) {
      await metadataService.deleteUsecase(
        useCaseId,
        selectedWorkflow.id,
        deasyApiKey,
      );
      refreshUseCasesHook();

      // Update local state
      const updatedUseCases = useCases.filter((uc) => uc.id !== useCaseId);
      updateWorkflow(selectedWorkflow.id, {
        ...selectedWorkflow,
        useCases: updatedUseCases,
        selectedUseCase: null,
      });
    }
  };

  // Config
  const handleCancelConfig = () => {
    if (selectedUseCase) {
      setSelectedDiscoveryGraph(null);
      setSelectedNodes([]);
    }
    setShowUseCaseConfig(false);
  };

  const handleCreateUseCase = async (e) => {
    e.preventDefault();

    const getSelectedParentNodes = (nodes) => {
      const selectedParentNodes = [];
      const seenPaths = new Set();

      // Sort nodes by path length to process shorter paths first
      const sortedNodes = [...nodes].sort(
        (a, b) =>
          (a.data.nodePath?.length || 0) - (b.data.nodePath?.length || 0),
      );

      sortedNodes.forEach((node) => {
        const nodePath = node.data.nodePath;
        if (!nodePath) return;

        const pathString = nodePath.join(">");

        const isSubPath = Array.from(seenPaths).some((existingPath) =>
          pathString.startsWith(existingPath),
        );

        if (!isSubPath) {
          seenPaths.add(pathString);
          selectedParentNodes.push(node);
        }
      });

      return selectedParentNodes;
    };

    // Get parent nodes
    const selectedParentNodes = getSelectedParentNodes(selectedNodes);

    // Calculate total file count from stats
    const totalFileCount = selectedParentNodes.reduce((total, node) => {
      return total + (node.stats?.fileCount || 0);
    }, 0);

    const newUseCase = {
      id: formData.name.toLowerCase().replace(/\s+/g, "_"),
      workflowId: selectedWorkflow.id,
      name: formData.name,
      description: formData.description,
      status: "active",
      dataPoints: totalFileCount,
      graphId: selectedDiscoveryGraph.graph_id,
      latestGraph: selectedDiscoveryGraph.graph_data,
      lastUpdated: new Date().toISOString(),
      selectedParentNodes: selectedParentNodes,
    };

    // Call the API service to create the use case
    await metadataService.createUseCase(newUseCase, deasyApiKey);
    refreshUseCasesHook();

    // Update the workflow with the new use case
    if (selectedWorkflow) {
      const updatedUseCases = selectedUseCase
        ? useCases.map((uc) => (uc.id === selectedUseCase.id ? newUseCase : uc))
        : [...useCases, newUseCase];

      updateWorkflow(selectedWorkflow.id, {
        ...selectedWorkflow,
        useCases: updatedUseCases,
        selectedUseCase: newUseCase,
      });
    }

    setShowUseCaseConfig(false);
    setSelectedUseCase(null);
    setFormData({ name: "", description: "" });
  };

  const handleDataSelectionChange = (selection) => {};

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  if (showUseCaseConfig) {
    return (
      <div className="flex-1 overflow-auto p-8">
        <div className="max-w-5xl mx-auto">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-semibold">
              {selectedUseCase ? "Edit Use Case" : "Create New Use Case"}
            </h1>
            <button
              onClick={handleCancelConfig}
              className="text-gray-600 hover:text-gray-900"
            >
              Cancel
            </button>
          </div>

          {/* Use Case Configuration Form */}
          <div className="bg-white p-6 rounded-lg border mb-8">
            <form className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Use Case Name
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                  value={formData.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  placeholder="Enter use case name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                  rows={4}
                  value={formData.description}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                  placeholder="Describe the use case"
                />
              </div>
            </form>
          </div>

          {/* Data Selection View */}
          <div className="bg-white p-6 rounded-lg border">
            <h2 className="text-lg font-medium mb-4">
              Select Data for Use Case
            </h2>
            <div className="h-[600px]">
              <UseCaseDefinitionView
                workflowData={selectedWorkflow}
                onSelectionChange={handleDataSelectionChange}
              />
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-3 mt-6">
            <button
              onClick={handleCancelConfig}
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleCreateUseCase}
              className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90"
              disabled={selectedNodes.length === 0 || !formData.name}
            >
              {selectedUseCase ? "Update Use Case" : "Create Use Case"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Use Cases</h1>
        <button
          onClick={handleNewUseCase}
          disabled={!selectedDiscoveryGraph}
          title={
            !selectedDiscoveryGraph
              ? "Please select a graph to create a new use case"
              : ""
          }
          className={`relative group ${
            selectedDiscoveryGraph
              ? "bg-primary text-white hover:bg-primary/90"
              : "bg-gray-300 cursor-not-allowed"
          } px-4 py-2 rounded`}
        >
          New Use Case
          {!selectedDiscoveryGraph && (
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
              Please select a graph to create a new use case
            </div>
          )}
        </button>
      </div>

      {useCases.length === 0 ? (
        <div className="bg-white rounded-lg border border-gray-200 p-8 text-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            No Use Cases Yet
          </h3>
          <p className="text-gray-500 mb-4">
            Create your first use case to start organizing your data.
          </p>
          <button
            onClick={handleNewUseCase}
            disabled={!selectedDiscoveryGraph}
            title={
              !selectedDiscoveryGraph
                ? "Please select a graph to create a new use case"
                : ""
            }
            className={`relative group ${
              selectedDiscoveryGraph
                ? "bg-primary text-white hover:bg-primary/90"
                : "bg-gray-300 cursor-not-allowed"
            } px-4 py-2 rounded`}
          >
            New Use Case
            {!selectedDiscoveryGraph && (
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                Please select a graph to create a new use case
              </div>
            )}
          </button>
        </div>
      ) : (
        <div className="bg-white rounded-lg border border-gray-200">
          <div className="overflow-x-auto">
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "20%" }}
                  >
                    Use Case
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "35%" }}
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "10%" }}
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "10%" }}
                  >
                    Data Points
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "10%" }}
                  >
                    Last Updated
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ width: "15%" }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {useCases.map((useCase) => (
                  <tr key={useCase.id} className="hover:bg-gray-50">
                    <td
                      className="px-6 py-4 text-sm font-medium text-gray-900 text-left"
                      style={{ width: "20%" }}
                    >
                      <div className="truncate">{useCase.name}</div>
                    </td>
                    <td
                      className="px-6 py-4 text-sm text-gray-500 text-left"
                      style={{ width: "35%" }}
                    >
                      <div className="truncate">{useCase.description}</div>
                    </td>
                    <td
                      className="px-6 py-4 text-left"
                      style={{ width: "10%" }}
                    >
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          useCase.status === "active"
                            ? "bg-green-100 text-green-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {useCase.status}
                      </span>
                    </td>
                    <td
                      className="px-6 py-4 text-sm text-gray-500 text-left"
                      style={{ width: "10%" }}
                    >
                      {useCase.dataPoints.toLocaleString()}
                    </td>
                    <td
                      className="px-6 py-4 text-sm text-gray-500 text-left"
                      style={{ width: "10%" }}
                    >
                      {new Date(useCase.lastUpdated).toLocaleString()}
                    </td>
                    <td
                      className="px-6 py-4 text-sm font-medium text-right"
                      style={{ width: "15%" }}
                    >
                      <div className="flex justify-end items-center gap-2">
                        <button
                          onClick={(e) => handleEditUseCase(useCase, e)}
                          className="inline-flex items-center px-2 py-1 border border-primary text-primary hover:bg-primary hover:text-white rounded-md transition-colors duration-200"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                          Edit
                        </button>
                        <button
                          onClick={(e) => handleExportUseCase(useCase, e)}
                          className="inline-flex items-center px-2 py-1 border border-primary text-primary hover:bg-primary hover:text-white rounded-md transition-colors duration-200"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                          Export
                        </button>
                        <button
                          onClick={(e) => handleDeleteUseCase(useCase.id, e)}
                          className="inline-flex items-center px-2 py-1 border border-red-500 text-red-500 hover:bg-red-500 hover:text-white rounded-md transition-colors duration-200"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
