import React from "react";
import WorkflowProgress from "../../../../utils/WorkflowProgress";

const UseCaseSummaryScreen = ({ useCase }) => {
  // Mock data - in real app, this would come from the actual filtered and processed data
  const summaryData = {
    totalDataPoints: 1250,
    dataTypes: ["Tickets", "Chat Logs"],
    dateRange: {
      start: "2023-01-01",
      end: "2024-01-15",
    },
    topTags: [
      { name: "Customer Support", count: 850 },
      { name: "Response Time", count: 450 },
      { name: "Customer Satisfaction", count: 380 },
    ],
    metrics: {
      avgResponseTime: "2.5 hours",
      customerSatisfaction: "4.2/5",
      totalTickets: 850,
      totalChats: 400,
    },
  };

  const MetricCard = ({ title, value }) => (
    <div className="bg-white p-4 rounded-lg border">
      <h4 className="text-sm font-medium text-gray-500 mb-1">{title}</h4>
      <p className="text-2xl font-semibold text-gray-900">{value}</p>
    </div>
  );

  return (
    <div className="flex flex-col h-full">
      <WorkflowProgress currentStep={3} />
      <div className="flex-1 overflow-auto p-8">
        <div className="max-w-5xl mx-auto">
          <div className="mb-8">
            <h1 className="text-2xl font-semibold mb-2">Use Case Summary</h1>
            <p className="text-gray-600">
              Review the filtered and processed data for your use case before
              proceeding to find relevant data.
            </p>
          </div>

          {/* Overview Section */}
          <div className="grid grid-cols-4 gap-4 mb-8">
            <MetricCard
              title="Total Data Points"
              value={summaryData.totalDataPoints.toLocaleString()}
            />
            <MetricCard
              title="Data Types"
              value={summaryData.dataTypes.length}
            />
            <MetricCard
              title="Date Range"
              value={`${summaryData.dateRange.start} to ${summaryData.dateRange.end}`}
            />
            <MetricCard title="Top Tags" value={summaryData.topTags.length} />
          </div>

          {/* Detailed Metrics */}
          <div className="grid grid-cols-2 gap-8">
            {/* Left Column */}
            <div className="space-y-6">
              <div className="bg-white p-6 rounded-lg border">
                <h3 className="text-lg font-medium mb-4">Data Distribution</h3>
                <div className="space-y-4">
                  {Object.entries(summaryData.metrics).map(([key, value]) => (
                    <div
                      key={key}
                      className="flex justify-between items-center"
                    >
                      <span className="text-gray-600">
                        {key.replace(/([A-Z])/g, " $1").trim()}
                      </span>
                      <span className="font-medium">{value}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg border">
                <h3 className="text-lg font-medium mb-4">Data Types</h3>
                <div className="space-y-2">
                  {summaryData.dataTypes.map((type) => (
                    <div key={type} className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-primary mr-2" />
                      <span>{type}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-6">
              <div className="bg-white p-6 rounded-lg border">
                <h3 className="text-lg font-medium mb-4">Top Tags</h3>
                <div className="space-y-3">
                  {summaryData.topTags.map((tag) => (
                    <div
                      key={tag.name}
                      className="flex items-center justify-between"
                    >
                      <span className="text-gray-600">{tag.name}</span>
                      <span className="font-medium">
                        {tag.count.toLocaleString()}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg border">
                <h3 className="text-lg font-medium mb-4">
                  Date Range Coverage
                </h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Start Date</span>
                    <span className="font-medium">
                      {summaryData.dateRange.start}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">End Date</span>
                    <span className="font-medium">
                      {summaryData.dateRange.end}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCaseSummaryScreen;
