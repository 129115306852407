import React, { useContext, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import "@xyflow/react/dist/style.css";
import ExtractMetadataTab from "./ExtractMeta/ExtractMetadataTab";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { WorkflowSummaryTab } from "./WorkflowSummaryTab";
import WorkflowProgress from "../../../../utils/WorkflowProgress";
import GraphDiscoverySelector from "./GraphDiscoverySelector";
import { GraphContext } from "../../../../../contexts/GraphContext";

const DataDiscoveryScreen = () => {
  const { metadataTree } = useContext(BaseContext);
  const { selectedDiscoveryGraph } = useContext(GraphContext);
  const [selectedStep, setSelectedStep] = useState(0);

  const renderTabContent = () => {
    switch (selectedStep) {
      case 0:
        return <GraphDiscoverySelector />;
      case 1:
        return (
          <WorkflowSummaryTab
            tagTreeData={metadataTree}
            rootDatasetSize={1000}
          />
        );
      case 2:
        return <ExtractMetadataTab />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-full">
      <WorkflowProgress currentStep={1} />
      <div className="border-b px-4">
        <Tabs
          value={selectedStep}
          onChange={(e, newValue) => setSelectedStep(newValue)}
          variant="fullWidth"
          TabIndicatorProps={{
            style: { backgroundColor: "#4dac94" },
          }}
          sx={{
            minHeight: "32px",
            "& .MuiTabs-flexContainer": {
              gap: "0px",
            },
          }}
        >
          <Tab
            label="Select Discovery Graph"
            tooltip="Select the discovery graph to use"
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              textTransform: "none",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
          <Tab
            label="Define Metadata Structure"
            tooltip="Define how your metadata should be organized"
            disabled={!selectedDiscoveryGraph}
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              textTransform: "none",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
          <Tab
            label="Extract Dataset Metadata"
            tooltip="Extract and organize metadata from your datasets"
            disabled={!selectedDiscoveryGraph}
            sx={{
              minHeight: "32px",
              padding: "6px 12px",
              fontSize: "13px",
              textTransform: "none",
              "&.Mui-selected": {
                color: "#4dac94",
              },
            }}
          />
        </Tabs>
      </div>
      <div className="flex-1 px-6 py-5">{renderTabContent()}</div>
    </div>
  );
};

export default DataDiscoveryScreen;
