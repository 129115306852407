import React, { useContext, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { GraphContext } from "../../../../../contexts/GraphContext";
import { v4 as uuidv4 } from "uuid";
import { graphService } from "../../../../../services/api";
import { BaseContext } from "../../../../../contexts/BaseContext";

const GraphDiscoverySelector = () => {
  const {
    selectedDiscoveryGraph,
    setSelectedDiscoveryGraph,
    graphs,
    setGraphs,
    fetchingGraphs,
  } = useContext(GraphContext);
  const { deasyUserConfig } = useContext(BaseContext);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newGraphName, setNewGraphName] = useState("");
  const [newGraphDescription, setNewGraphDescription] = useState("");

  const handleCreateDiscoveryGraph = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateGraph = async () => {
    const newDiscoveryGraph = {
      graph_id: String(uuidv4()),
      graph_name: newGraphName || "New Graph",
      graph_description: newGraphDescription,
      graph_data: {},
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
    await graphService.createUpdateGraph(
      deasyUserConfig.deasyApiKey,
      newDiscoveryGraph.graph_id,
      newDiscoveryGraph.graph_name,
      newDiscoveryGraph.graph_description,
      newDiscoveryGraph.graph_data,
    );
    setSelectedDiscoveryGraph(newDiscoveryGraph);
    setGraphs((prev) => [...prev, newDiscoveryGraph]);
    setIsCreateModalOpen(false);
    setNewGraphName("");
    setNewGraphDescription("");
  };

  const handleSelectGraph = (graph) => {
    setSelectedDiscoveryGraph(graph);
  };

  const handleDeleteGraph = async (graphId) => {
    if (window.confirm("Are you sure you want to delete this graph?")) {
      await graphService.deleteGraph(graphId, deasyUserConfig.deasyApiKey);
      setGraphs((prev) => prev.filter((g) => g.graph_id !== graphId));
      if (selectedDiscoveryGraph?.graph_id === graphId) {
        setSelectedDiscoveryGraph(null);
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">My Graphs</h1>
        <button
          onClick={handleCreateDiscoveryGraph}
          className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90"
        >
          New Graph
        </button>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Graph Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last Updated
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created At
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {graphs.map((graph) => {
                return (
                  <tr
                    key={graph.graph_id}
                    className={`${
                      selectedDiscoveryGraph?.graph_id === graph.graph_id
                        ? "bg-primary/5"
                        : ""
                    } cursor-pointer hover:bg-gray-50`}
                    onClick={() => handleSelectGraph(graph)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                      <div className="flex items-center text-left">
                        {selectedDiscoveryGraph?.graph_id ===
                          graph.graph_id && (
                          <FaCheck className="h-4 w-4 text-primary mr-2" />
                        )}
                        {graph.graph_name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                      {graph.graph_description}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                      {new Date(graph.updated_at).toLocaleString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                      {new Date(graph.created_at).toLocaleString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleDeleteGraph(graph.graph_id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {fetchingGraphs && (
        <div className="text-center py-12">
          <p className="text-gray-500 mb-4">Loading graphs...</p>
        </div>
      )}

      {graphs.length === 0 && !fetchingGraphs && (
        <div className="text-center py-12">
          <p className="text-gray-500 mb-4">No graphs created yet</p>
          <button
            onClick={handleCreateDiscoveryGraph}
            className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90"
          >
            Create your first graph
          </button>
        </div>
      )}

      {isCreateModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Create New Graph</h2>
            <input
              type="text"
              placeholder="Graph Name"
              value={newGraphName}
              onChange={(e) => setNewGraphName(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
              required
            />
            <textarea
              placeholder="Graph Description"
              value={newGraphDescription}
              onChange={(e) => setNewGraphDescription(e.target.value)}
              className="w-full p-2 mb-4 border rounded h-24"
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setIsCreateModalOpen(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateGraph}
                className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GraphDiscoverySelector;
