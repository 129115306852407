import { Modal } from "@mui/material";
import { Check } from "lucide-react";
import FileList from "../../../../Utils/FileList.js";

export const FilePickerModal = ({
  openFilePicker,
  setOpenFilePicker,
  selectedFiles,
  setSelectedFiles,
}) => {
  return (
    <Modal
      open={openFilePicker}
      onClose={() => setOpenFilePicker(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-6 w-4/5 max-w-[1200px] min-w-[600px] h-[90vh] flex flex-col">
        <h2 className="text-xl font-semibold shrink-0">Select Files</h2>

        <div className="mt-4 flex-1 min-h-0">
          <FileList
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            showTitle={false}
          />
        </div>

        <div className="flex justify-end gap-2 mt-4 shrink-0">
          <button
            className="px-4 py-2 rounded-lg border border-[#4dac94] text-[#4dac94] font-semibold hover:bg-[#4dac94]/10"
            onClick={() => setOpenFilePicker(false)}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded-lg bg-[#4dac94] text-white font-semibold hover:bg-[#4dac94]/90 flex items-center gap-2"
            onClick={() => setOpenFilePicker(false)}
          >
            <Check size={18} />
            Finish
          </button>
        </div>
      </div>
    </Modal>
  );
};
