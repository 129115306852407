import React, { useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import { WorkflowContext } from "../../../contexts/WorkflowContext";
import { GraphContext } from "../../../contexts/GraphContext";

const WorkflowContextBanner = () => {
  const { deasyUserConfig } = useContext(BaseContext);
  const { selectedWorkflow } = useContext(WorkflowContext);
  const { selectedDiscoveryGraph } = useContext(GraphContext);

  const activeVDBProfile = selectedWorkflow?.vdbConfigIds?.[0];
  const activeVDBConfig =
    deasyUserConfig?.vdbmConfig?.Configs?.[activeVDBProfile];

  const activeLLMProfile = selectedWorkflow?.llmConfigIds?.[0];

  if (!selectedWorkflow) {
    return (
      <div className="bg-yellow-50 border-b border-yellow-100 px-8 py-2">
        <div className="flex items-center text-yellow-800">
          <span className="text-sm">
            No workflow selected. Please select or create a workflow to
            continue.
          </span>
        </div>
      </div>
    );
  }

  if (!activeVDBProfile || !activeVDBConfig) {
    return null;
  }

  const getVdbLogo = (type) => {
    switch (type) {
      case "QdrantVectorDBManager":
        return "/images/vdb-logos/qdrant.png";
      case "PineconeVectorDBManager":
        return "/images/vdb-logos/pinecone.png";
      case "PostgresVectorDBManager":
        return "/images/vdb-logos/postgresql.png";
      default:
        return null;
    }
  };

  return (
    <div className="bg-white border-b px-4 py-3 overflow-x-auto pl-8">
      <div className="flex items-center">
        <div className="flex items-center gap-4 flex-nowrap">
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-500">Current Workflow:</span>
            <span className="font-medium text-primary">
              {selectedWorkflow?.name || "No workflow selected"}
            </span>
          </div>
          <div className="h-4 w-px bg-gray-200 hidden sm:block" />
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-500">LLM Endpoint:</span>
            <div className="flex items-center gap-2">
              <img
                src="/images/llm-logos/openai.png"
                alt="OpenAI"
                className="h-5 w-auto"
              />
              <span className="font-medium">{activeLLMProfile || "N/A"}</span>
            </div>
          </div>
          <div className="h-4 w-px bg-gray-200 hidden sm:block" />
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-500">Vector Database:</span>
            <div className="flex items-center gap-2">
              <img
                src={getVdbLogo(activeVDBConfig.type)}
                alt={activeVDBConfig.type}
                className="h-5 w-auto"
              />
              <span className="font-medium">{activeVDBProfile || "N/A"}</span>
            </div>
          </div>
          <div className="h-4 w-px bg-gray-200 hidden sm:block" />
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-500">Collection:</span>
            <span className="font-medium">{activeVDBConfig.collection}</span>
          </div>
          <div className="h-4 w-px bg-gray-200 hidden sm:block" />
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-500">Current Graph:</span>
            <span className="font-medium text-primary">
              {selectedDiscoveryGraph?.graph_name || "N/A"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowContextBanner;
