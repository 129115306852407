import React, { useContext, useState, useEffect } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import { WorkflowType, WorkflowStatus } from "../../../../models/WorkflowModel";
import { WorkflowContext } from "../../../../contexts/WorkflowContext";
import { WorkflowModel } from "../../../../models/WorkflowModel";
import { workflowService } from "../../../../services/api";
import { v4 as uuidv4 } from "uuid";

const WorkflowConfigurationScreen = ({ workflowId, onClose }) => {
  const { deasyUserConfig } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;

  const {
    setSelectedWorkflow,
    setCurrentScreen,
    createWorkflow,
    updateWorkflow,
    workflows,
  } = useContext(WorkflowContext);

  const [formData, setFormData] = useState({
    name: "",
    type: WorkflowType.CONNECT_DATA,
    llmEndpoint: deasyUserConfig?.llmConfig?.LastActive || "",
  });

  useEffect(() => {
    if (workflowId) {
      const workflow = workflows.find((w) => w.id === workflowId);
      if (workflow) {
        setFormData({
          name: workflow.name,
          type: workflow.type,
          llmEndpoint: workflow.llmEndpoint || "",
        });
      }
    }
  }, [workflowId, workflows]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const timestamp = new Date().toISOString();
      const workflowPayload = {
        workflow_name: formData.name,
        vdb_config_ids: [deasyUserConfig?.vdbmConfig?.LastActive || ""],
        llm_config_ids: [formData.llmEndpoint],
      };

      if (workflowId) {
        await workflowService.updateWorkflow(
          workflowId,
          workflowPayload,
          deasyApiKey,
        );

        const workflow = workflows.find((w) => w.id === workflowId);
        const newWorkflow = {
          ...workflow,
          name: formData.name,
          type: formData.type,
          llmEndpoint: formData.llmEndpoint,
          llmType:
            deasyUserConfig?.llmConfig?.Configs[formData.llmEndpoint]
              ?.llmType || "",
          updatedAt: timestamp,
        };
        updateWorkflow(workflowId, newWorkflow);
      } else {
        const newWorkflowId = uuidv4();
        workflowPayload.workflow_id = newWorkflowId;
        workflowPayload.created_at = timestamp;

        await workflowService.createWorkflow(workflowPayload, deasyApiKey);

        const newWorkflow = new WorkflowModel({
          id: newWorkflowId,
          name: formData.name,
          type: formData.type,
          llmEndpoint: formData.llmEndpoint,
          status: WorkflowStatus.DRAFT,
          clusters_count: 0,
          tags_extracted: 0,
          auto_sync: false,
          created_at: timestamp,
        });

        createWorkflow(newWorkflow);
        setSelectedWorkflow(newWorkflow);
        setCurrentScreen(WorkflowType.CONNECT_DATA);
        onClose();
      }
    } catch (error) {
      console.error("Failed to create/update workflow:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Get available endpoints from deasyUserConfig
  const llmEndpoints = deasyUserConfig?.llmConfig?.Configs || {};

  return (
    <div className="max-w-2xl mx-auto">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Workflow Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            LLM Endpoint
          </label>
          <select
            name="llmEndpoint"
            value={formData.llmEndpoint}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
            required
          >
            <option value="">Select LLM endpoint</option>
            {Object.entries(llmEndpoints).map(([name, config]) => (
              <option key={name} value={name}>
                {name} ({config.llmType})
              </option>
            ))}
          </select>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-primary text-white rounded hover:bg-primary/90"
          >
            {workflowId ? "Update Workflow" : "Create Workflow"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default WorkflowConfigurationScreen;
