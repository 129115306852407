import { useState } from "react";
import { tokenService } from "../../../../services/api";
import {
  FormInput,
  LoadingSpinner,
  updateTokenServiceSecret,
  useToast,
  Toast,
} from "../ConfigUtils";
import { ProfileCard } from "./ProfileCard";
import { EditableProfileCard } from "./EditableProfileCard";

const DB_OPTIONS = [
  { value: "QdrantVectorDBManager", label: "Qdrant" },
  { value: "ChromaVectorDBManager", label: "Chroma" },
  { value: "PineconeVectorDBManager", label: "Pinecone" },
];

const getDisplayType = (type) => {
  const option = DB_OPTIONS.find((opt) => opt.value === type);
  return option ? option.label : type;
};

export const VectorDBConfiguration = ({
  formState,
  handleInputChange,
  handleLoadConfig,
  handleSaveNewConfig,
  deasyUserConfig,
  setDeasyUserConfig,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [editingProfile, setEditingProfile] = useState(null);
  const [profileName, setProfileName] = useState("");

  const profiles = deasyUserConfig?.vdbmConfig?.Configs || {};
  const activeProfile = deasyUserConfig?.vdbmConfig?.LastActive;

  const { toast, showToast, hideToast } = useToast();

  const handleAddNew = () => {
    setEditingProfile(null);
    setProfileName("");
    setShowForm(true);
  };

  const handleEdit = (profileName) => {
    handleLoadConfig("vectorDB", profileName);
    setEditingProfile(profileName);
    setProfileName(profileName);
    setShowForm(true);
  };

  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState(null);

  // Handlers
  const handleConfigLoad = (name) => {
    handleLoadConfig("vectorDB", name);
    if (name === "new") {
      return;
    }

    const newConfig = {
      ...deasyUserConfig,
      vdbmConfig: {
        ...deasyUserConfig.vdbmConfig,
        LastActive: name,
        Configs: deasyUserConfig.vdbmConfig.Configs,
      },
    };
    setDeasyUserConfig(newConfig);
    updateTokenServiceSecret("deasyUserConfig", JSON.stringify(newConfig));
  };

  const validateFields = () => {
    const errors = [];

    if (!profileName?.trim()) {
      errors.push("Profile name is required");
    }

    if (
      profiles[profileName?.trim()] &&
      (!editingProfile || profileName.trim() !== editingProfile)
    ) {
      errors.push("A profile with this name already exists");
    }

    if (!formState.type) {
      errors.push("Please select a database type");
    }

    if (!formState.collection?.trim()) {
      errors.push("Collection name is required");
    }

    if (!formState.apiKey?.trim()) {
      errors.push("API key is required");
    }

    if (
      formState.type !== "PineconeVectorDBManager" &&
      !formState.url?.trim()
    ) {
      errors.push("Database URL is required");
    }

    return errors;
  };

  const validateAndSave = async () => {
    const validationErrors = validateFields();
    if (validationErrors.length > 0) {
      showToast(validationErrors.join(", "), "error");
      return;
    }

    setIsValidating(true);
    setValidationError(null);

    try {
      const config = {
        type: formState.type,
        apiKey: formState.apiKey.trim(),
        collection: formState.collection.trim(),
        url: formState.url?.trim(),
      };
      const response = await tokenService.validateVectorDB(config);

      if (response.data.success) {
        handleSaveNewConfig("vectorDB", profileName.trim(), config);
        setShowForm(false);
        showToast(
          "Vector database configuration saved successfully",
          "success",
        );
      } else {
        showToast(response.data.message || "Validation failed", "error");
      }
    } catch (error) {
      showToast(
        error.response?.data?.message || "Failed to validate configuration",
        "error",
      );
    } finally {
      setIsValidating(false);
    }
  };

  const getButtonText = () => {
    if (isValidating) {
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
          Verifying credentials...
        </div>
      );
    }
    return editingProfile ? "Save Changes" : "Save New Profile";
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-700">
          Vector Database Profiles
        </h2>
        <button
          onClick={handleAddNew}
          className="px-4 py-2 bg-white text-primary border-2 border-primary rounded-md hover:bg-primary hover:text-white"
        >
          +
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.entries(profiles).map(([name, config]) => (
          <ProfileCard
            key={name}
            type={getDisplayType(config.type)}
            name={name}
            isActive={name === activeProfile}
            onSelect={(name) => handleConfigLoad(name)}
            onEdit={handleEdit}
            onDelete={(name) => handleSaveNewConfig("vectorDB", name, null)}
            details={{
              Type: getDisplayType(config.type),
              Collection: config.collection,
              URL: config.url || "N/A",
            }}
          />
        ))}

        {showForm && (
          <EditableProfileCard
            name={profileName}
            setName={setProfileName}
            isNew={!editingProfile}
            onSave={validateAndSave}
            onCancel={() => setShowForm(false)}
            validationError={validationError}
            isValidating={isValidating}
            saveButtonText={getButtonText()}
          >
            {/* Database Type Selection */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Database Type
              </label>
              <div className="space-y-2">
                {DB_OPTIONS.map((db) => (
                  <label
                    key={db.value}
                    className="flex items-center space-x-3 cursor-pointer"
                  >
                    <input
                      type="radio"
                      name="database"
                      value={db.value}
                      checked={formState.type === db.value}
                      onChange={(e) =>
                        handleInputChange("type", e.target.value)
                      }
                      className="form-radio h-5 w-5 text-emerald-600"
                    />
                    <span className="text-gray-700">{db.label}</span>
                  </label>
                ))}
              </div>
            </div>

            <FormInput
              label="Collection Name"
              type="text"
              value={formState.collection}
              onChange={(e) => handleInputChange("collection", e.target.value)}
              placeholder="Enter the name of your collection"
            />

            <FormInput
              label="API Key"
              type="password"
              value={formState.apiKey}
              onChange={(e) => handleInputChange("apiKey", e.target.value)}
              placeholder="Enter your API key"
            />

            {formState.type !== "PineconeVectorDBManager" && (
              <FormInput
                label="Database URL"
                type="text"
                value={formState.url}
                onChange={(e) => handleInputChange("url", e.target.value)}
                placeholder="Enter your database URL"
              />
            )}
          </EditableProfileCard>
        )}
      </div>
      <Toast
        message={toast.message}
        type={toast.type}
        show={toast.show}
        onClose={hideToast}
      />
    </div>
  );
};
