import React from "react";

export const ProfileCard = ({
  type,
  name,
  isActive,
  onSelect,
  onEdit,
  onDelete,
  details,
}) => {
  return (
    <div
      className={`relative p-7 py-9 rounded-lg border-2 transition-all cursor-pointer text-left
        ${
          isActive
            ? "border-primary bg-primary-50"
            : "border-gray-200 hover:border-emerald-200 bg-white"
        }`}
      onClick={() => onSelect(name)}
    >
      <div className="flex justify-between items-start mb-2">
        <div>
          <h3 className="font-medium text-gray-900">{name}</h3>
          <span className="text-sm text-gray-500">{type}</span>
        </div>
        <div className="flex gap-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit(name);
            }}
            className="p-1 text-gray-500 hover:text-emerald-600"
            title="Edit profile"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete(name);
            }}
            className="p-1 text-gray-500 hover:text-red-600"
            title="Delete profile"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="space-y-1">
        {Object.entries(details).map(([key, value]) => (
          <div key={key} className="text-sm">
            <span className="text-gray-500">{key}: </span>
            <span className="text-gray-900">
              {key.toLowerCase().includes("key") ? "••••••" : value}
            </span>
          </div>
        ))}
      </div>

      {isActive && (
        <div className="absolute top-2 right-2">
          <span className="px-2 py-1 text-xs font-medium text-emerald-700 bg-emerald-100 rounded-full">
            Active
          </span>
        </div>
      )}
    </div>
  );
};
