import React, { createContext, useState, useEffect, useContext } from "react";
import { WorkflowType } from "../models/WorkflowModel";
import { workflowService, metadataService } from "../services/api";
import { BaseContext } from "../contexts/BaseContext";

export const WorkflowContext = createContext();

export const WorkflowProvider = ({ children }) => {
  const [currentScreen, setCurrentScreen] = useState("all");
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [workflows, setWorkflows] = useState([]);
  const [workflowData, setWorkflowData] = useState({
    selectedVdb: null,
    selectedProfile: null,
    vdbConfig: null,
    discoveredData: null,
    relevantData: null,
    exportFormat: null,
  });
  const [useCases, setUseCases] = useState([]);
  const { deasyUserConfig } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const [refreshUseCases, setRefreshUseCases] = useState(false);

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await workflowService.listWorkflows(deasyApiKey);
        const transformedWorkflows = response.data.workflows.map(
          (workflow) => ({
            id: workflow.workflow_id,
            name: workflow.workflow_name,
            createdAt: workflow.created_at,
            llmConfigIds: workflow.llm_config_ids,
            vdbConfigIds: workflow.vdb_config_ids,
            userId: workflow.user_id,
            clustersCount: workflow.clusters_count || 0,
            tagsExtracted: workflow.tags_extracted || 0,
            autoSync: workflow.auto_sync || false,
            status: workflow.status || "draft",
          }),
        );
        setWorkflows(transformedWorkflows);
      } catch (error) {
        console.error("Failed to fetch workflows:", error);
        setWorkflows([]);
      }
    };

    fetchWorkflows();
  }, [deasyApiKey]);

  const refreshUseCasesHook = () => {
    setRefreshUseCases(true);
  };

  useEffect(() => {
    const fetchUsecases = async () => {
      if (selectedWorkflow?.id) {
        try {
          const response = await metadataService.listUsecases(
            selectedWorkflow.id,
            deasyApiKey,
          );
          setUseCases(response);

          setSelectedWorkflow((prev) => ({
            ...prev,
            useCases: response,
          }));
          setRefreshUseCases(false);
        } catch (error) {
          console.error("Failed to fetch usecases:", error);
          setUseCases([]);
          setRefreshUseCases(false);
        }
      }
    };

    if (selectedWorkflow?.id || refreshUseCases) {
      fetchUsecases();
    }
  }, [selectedWorkflow?.id, deasyApiKey, refreshUseCases]);

  const updateWorkflowData = (updates) => {
    setWorkflowData((prev) => ({
      ...prev,
      ...updates,
    }));
  };

  const createWorkflow = (newWorkflow) => {
    setWorkflows((prev) => [...prev, newWorkflow]);
    setSelectedWorkflow(newWorkflow);
  };

  const updateWorkflow = (workflowId, updates) => {
    setWorkflows((prev) =>
      prev.map((workflow) =>
        workflow.id === workflowId
          ? { ...workflow, ...updates, updatedAt: new Date().toISOString() }
          : workflow,
      ),
    );

    if (selectedWorkflow?.id === workflowId) {
      setSelectedWorkflow((prev) => ({ ...prev, ...updates }));
    }
  };

  const deleteWorkflow = (workflowId) => {
    setWorkflows((prev) => prev.filter((w) => w.id !== workflowId));
    if (selectedWorkflow?.id === workflowId) {
      setSelectedWorkflow(null);
    }
  };

  const navigateToNextStep = (currentStep) => {
    let nextStep;
    switch (currentStep) {
      case WorkflowType.CONNECT_DATA:
        nextStep = WorkflowType.DATA_DISCOVERY;
        break;
      case WorkflowType.DATA_DISCOVERY:
        nextStep = WorkflowType.USE_CASE_MANAGEMENT;
        break;
      case WorkflowType.USE_CASE_MANAGEMENT:
        nextStep = WorkflowType.EXPORT_METADATA;
        break;
      case WorkflowType.EXPORT_METADATA:
        nextStep = WorkflowType.COMPLETE;
        break;
      default:
        nextStep = WorkflowType.CONNECT_DATA;
    }

    if (selectedWorkflow) {
      setSelectedWorkflow({
        ...selectedWorkflow,
        type: nextStep,
      });
    }
    setCurrentScreen(nextStep);
  };

  const value = {
    currentScreen,
    setCurrentScreen,
    workflowData,
    updateWorkflowData,
    selectedWorkflow,
    setSelectedWorkflow,
    workflows,
    setWorkflows,
    createWorkflow,
    updateWorkflow,
    deleteWorkflow,
    navigateToNextStep,
    useCases,
    setUseCases,
    refreshUseCasesHook,
  };

  return (
    <WorkflowContext.Provider value={value}>
      {children}
    </WorkflowContext.Provider>
  );
};
