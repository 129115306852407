import {
  AiOutlinePlusCircle,
  AiFillPlusCircle,
  AiOutlineInfoCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { Position, Handle } from "reactflow";
import { useContext, useState } from "react";
import { NodeInfoModal } from "./NodeInfoModal";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { useDrop } from "react-dnd";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { processData } from "../WorkflowSummaryTab";

export const findNodeStats = (stats, nodePath) => {
  if (!stats || !nodePath) return null;

  let current = stats;

  for (const pathPart of nodePath) {
    if (!current) return null;

    // For root node
    if (pathPart === "root") {
      return current;
    }

    // Look for matching node in children
    if (current.children) {
      current = current.children.find((child) => child.name === pathPart);
    } else {
      return null;
    }
  }

  return current;
};

const DeleteConfirmationModal = ({ onConfirm, onCancel, nodeName }) => {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-96 shadow-xl">
        <h3 className="text-lg font-semibold mb-4">Delete {nodeName}</h3>
        <p className="mb-6 text-gray-600">
          Are you sure you want to delete this node and all its children? This
          action cannot be undone.
        </p>
        <div className="flex justify-end gap-3">
          <button
            className="px-4 py-2 rounded-md text-gray-600 hover:bg-gray-50 border border-gray-200"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded-md bg-danger text-white hover:bg-danger/90"
            onClick={onConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const handleConfirmNodeDelete = (setShowDeleteModal, data) => {
  if (data.onDeleteNode) {
    data.onDeleteNode(data.id);
  }
  setShowDeleteModal(false);
};

// Custom node components
export const QuestionNode = ({ data }) => {
  const { selectedNodeData, hierarchyStats, discoveryGraphData } =
    useContext(GraphContext);
  const nodeIsSelected =
    selectedNodeData !== null && selectedNodeData?.id === data.id;
  const showControls = !data.hideControls;

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Check if this is an open-ended tag (has <ANY> or empty values)
  const isOpenEndedTag = (nodeData) => {
    // Helper function to find tag in tree
    const findTagInTree = (path) => {
      let current = discoveryGraphData;
      // Skip root if present
      const startIndex = path[0] === "root" ? 1 : 0;

      for (let i = startIndex; i < path.length; i++) {
        const segment = path[i];
        if (!current[segment]) return null;
        current = current[segment];
      }
      return current;
    };

    const tagConfig = findTagInTree(nodeData.nodePath);
    return (
      nodeData.isOpenEnded ||
      (tagConfig?.TagAvailableValues?.length === 1 &&
        tagConfig?.TagAvailableValues[0] === "<ANY>")
    );
  };

  // Get the root node's file count for total datasets
  const getTotalCount = () => {
    if (!hierarchyStats) return 0;
    return hierarchyStats.file_count || 0;
  };

  const nodeStats = findNodeStats(hierarchyStats, data.nodePath);
  const totalCount = getTotalCount();

  // For the root node or open-ended tags, handle display differently
  const displayCount =
    data.label === "root"
      ? totalCount
      : isOpenEndedTag(data)
        ? "-"
        : nodeStats?.file_count || 0;

  const displayPercentage =
    data.label === "root"
      ? "100%"
      : isOpenEndedTag(data)
        ? "-"
        : nodeStats && totalCount > 0
          ? ((nodeStats.file_count / totalCount) * 100).toFixed(1) + "%"
          : "0%";

  const handleAddClick = () => {
    data.onAddNode(data, "question");
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TAG",
    drop: (item, monitor) => {
      const tag = item.tag;
      if (tag) {
        data.onAddNode(data, "question");
        data.handleAddNodeApplied(data, tag);
      }
      return { dropped: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`flex flex-col items-center ${isOver ? "bg-primary/10 rounded-lg p-2" : ""}`}
    >
      <div className="flex gap-2">
        {showControls && (
          <>
            <button
              className={`text-primary hover:scale-110 transition-all ${
                nodeIsSelected
                  ? "bg-primary text-white rounded-full shadow-lg animate-pulse"
                  : "hover:bg-primary/10 rounded-full"
              }`}
              onClick={handleAddClick}
              title={`Add new node to "${data.label}" tag`}
            >
              {nodeIsSelected ? (
                <AiFillPlusCircle size={28} className="p-0.5" />
              ) : (
                <AiOutlinePlusCircle size={24} />
              )}
            </button>
            <button
              className="text-danger"
              onClick={() => setShowDeleteModal(true)}
            >
              <AiOutlineMinusCircle size={24} style={{ strokeWidth: 1 }} />
            </button>
          </>
        )}
        <button className="text-gray-500" onClick={() => setShowModal(true)}>
          <AiOutlineInfoCircle size={24} style={{ strokeWidth: 1 }} />
        </button>
      </div>
      <div className="flex flex-col items-center">
        <div className="rounded-sm border border-gray-300 bg-white px-4 py-1">
          {data.label}
          {isOpenEndedTag(data) && (
            <div className="text-sm text-gray-500 text-center italic">
              Open-ended tag
            </div>
          )}
          <div className="text-sm text-gray-500 text-center">
            {!isOpenEndedTag(data) && (
              <>
                <div>{displayCount.toLocaleString()}</div>
                <div>{displayPercentage}</div>
              </>
            )}
          </div>
        </div>
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
      </div>

      {showModal && (
        <div className="w-auto">
          <NodeInfoModal
            nodeData={data}
            nodeType="question"
            onClose={() => setShowModal(false)}
          />
        </div>
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          nodeName={data.label}
          onConfirm={() => handleConfirmNodeDelete(setShowDeleteModal, data)}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export const ValueNode = ({ data }) => {
  const {
    selectedNodeData,
    hierarchyStats,
    discoveryGraphData,
    selectedNodes,
    setSelectedNodes,
  } = useContext(GraphContext);
  const nodeIsSelected =
    selectedNodeData !== null && selectedNodeData?.id === data.id;

  const { nodes: graphNodes } = processData(discoveryGraphData, 1000);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const showControls = !data.hideControls;
  const isSelected = selectedNodes.some((node) => node.id === data.id);

  // Check if parent is an open-ended tag
  const isParentOpenEnded = (nodeData) => {
    // Helper function to find tag in tree
    const findTagInTree = (path) => {
      let current = discoveryGraphData;
      // Skip root if present
      const startIndex = path[0] === "root" ? 1 : 0;

      for (let i = startIndex; i < path.length - 1; i++) {
        const segment = path[i];
        if (!current[segment]) return null;
        current = current[segment];
      }
      return current;
    };

    const parentConfig = findTagInTree(nodeData.nodePath);
    return (
      parentConfig?.TagAvailableValues?.length === 1 &&
      parentConfig?.TagAvailableValues[0] === "<ANY>"
    );
  };

  const nodeStats = findNodeStats(hierarchyStats, data.nodePath);

  const handleAddClick = () => {
    data.onAddNode(data, "value");
  };

  const handleNodeClick = (e, nodeStats) => {
    e.stopPropagation();
    setSelectedNodes((prev) => {
      const newSelection = [...prev];
      const nodeIndex = newSelection.findIndex((node) => node.id === data.id);

      if (nodeIndex !== -1) {
        // Get the node's path
        const nodePath = data.nodePath;

        // Remove the current node and all nodes whose paths start with this node's path
        return newSelection.filter((node) => {
          const isChild =
            node.data.nodePath.slice(0, nodePath.length).join(">") !==
            nodePath.join(">");
          return isChild;
        });
      }
      // If node is not selected, select it and all its children
      else {
        const nodePath = data.nodePath;
        const descendants = [];

        // Find all nodes that have this node's path as their prefix
        graphNodes.forEach((node) => {
          // Only include nodes of type "value"
          if (node.data.nodePath && node.type === "value") {
            const currentPath = node.data.nodePath;
            const isDescendant =
              currentPath.slice(0, nodePath.length).join(">") ===
              nodePath.join(">");

            if (isDescendant) {
              descendants.push({
                id: node.id,
                data: {
                  nodePath: node.data.nodePath,
                  label: node.data.label,
                },
                stats: {
                  fileCount: nodeStats?.file_count || 0,
                  percentage: nodeStats?.percentage || 0,
                },
              });
            }
          }
        });

        return [...newSelection, ...descendants];
      }
    });
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TAG",
    drop: (item, monitor) => {
      const tag = item.tag;
      if (tag) {
        data.onAddNode(data, "value");
        data.handleAddNodeApplied(data, tag);
      }
      return { dropped: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`flex flex-col items-center ${isOver ? "bg-primary/10 rounded-lg p-2" : ""}`}
    >
      <div className="flex flex-col items-center">
        <div
          onClick={(e) => handleNodeClick(e, nodeStats)}
          className={`rounded-sm border border-gray-300 bg-white px-4 py-1 cursor-pointer
            ${isSelected ? "ring-2 ring-primary ring-offset-2" : ""}`}
        >
          {data.label}
          {data.isOutOfSync && (
            <>
              <div
                className="mt-1 text-xs text-warning bg-warning/10 px-2 py-1 rounded
                shadow-lg border border-warning/50"
              >
                This value is not included in the parent's Available Values and
                will not be triggered, either add it to or remove it from the
                parent's Available Values.
              </div>
            </>
          )}
          <div className="text-sm text-gray-500 text-center">
            {!isParentOpenEnded(data) && (
              <>
                <div>
                  {nodeStats ? nodeStats.file_count.toLocaleString() : "0"}
                </div>
                <div>
                  {nodeStats ? nodeStats.percentage.toFixed(1) + "%" : "0%"}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex gap-2">
          {showControls && (
            <>
              <button
                className={`text-primary hover:scale-110 transition-all ${
                  nodeIsSelected
                    ? "bg-primary text-white rounded-full shadow-lg animate-pulse"
                    : "hover:bg-primary/10 rounded-full"
                }`}
                onClick={handleAddClick}
              >
                {nodeIsSelected ? (
                  <AiFillPlusCircle size={28} className="p-0.5" />
                ) : (
                  <AiOutlinePlusCircle size={24} />
                )}
              </button>
              <button
                className="text-danger"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteModal(true);
                }}
              >
                <AiOutlineMinusCircle size={24} style={{ strokeWidth: 1 }} />
              </button>
            </>
          )}
          <button
            className="text-gray-500"
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(true);
            }}
          >
            <AiOutlineInfoCircle size={24} style={{ strokeWidth: 1 }} />
          </button>
        </div>
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
      </div>

      {showModal && (
        <div className="w-auto">
          <NodeInfoModal
            nodeData={data}
            nodeType="value"
            onClose={() => setShowModal(false)}
          />
        </div>
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          nodeName={data.label}
          onConfirm={() => handleConfirmNodeDelete(setShowDeleteModal, data)}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export const RootNode = ({ data }) => {
  const { setCurrentNode } = useContext(BaseContext);

  const { setSidePanelOpen, selectedNodeData } = useContext(GraphContext);
  const nodeIsSelected =
    selectedNodeData !== null && selectedNodeData?.id === data.id;
  const showControls = !data.hideControls;

  const [showModal, setShowModal] = useState(false);

  const handleAddRootChild = () => {
    setCurrentNode(data);
    setSidePanelOpen(true);
    data.onAddNode(data, "root");
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TAG",
    drop: (item, monitor) => {
      const tag = item.tag;
      if (tag) {
        data.onAddNode(data, "root");
        data.handleAddNodeApplied(data, tag);
      }
      return { dropped: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`flex flex-col items-center ${isOver ? "bg-primary/10 rounded-lg p-2" : ""}`}
    >
      <div className="rounded-xl border-3 border-primary bg-primary bg-opacity-10 border border-primary/90 px-8 py-3 shadow-lg transform hover:scale-105 transition-all">
        <div className="text-2xl font-bold text-primary text-center">
          {data.datasetSize.toLocaleString()}
        </div>
        <span className="text-base text-primary/80 font-semibold block text-center">
          Total Datasets
        </span>
      </div>
      <div className="flex flex-row gap-2 mt-1">
        {showControls && (
          <button
            className={`text-primary hover:scale-110 transition-all ${
              nodeIsSelected
                ? "bg-primary text-white rounded-full shadow-lg animate-pulse"
                : "hover:bg-primary/10 rounded-full"
            }`}
            onClick={handleAddRootChild}
            title={`Add new node to "${data.label}" tag`}
          >
            {nodeIsSelected ? (
              <AiFillPlusCircle size={32} className="p-0.5" />
            ) : (
              <AiOutlinePlusCircle size={28} />
            )}
          </button>
        )}
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={() => setShowModal(true)}
        >
          <AiOutlineInfoCircle size={32} style={{ strokeWidth: 1.5 }} />
        </button>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={false}
        style={{ opacity: 0 }}
      />

      {showModal && (
        <NodeInfoModal
          nodeData={{
            label: "Root Node",
            tagDistributionData: {
              datasetCount: data.datasetSize,
              datasetDistribution: "100%",
            },
          }}
          nodeType="root"
          onClose={() => setShowModal(false)}
          portalTarget={document.body}
        />
      )}
    </div>
  );
};

export const nodeTypes = {
  question: QuestionNode,
  value: ValueNode,
  root: RootNode,
};
