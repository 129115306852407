import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import App from "./App";
import { BaseProvider } from "./contexts/BaseContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BaseProvider>
    <App />
  </BaseProvider>,
);
