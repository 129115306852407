import React, { useState, useContext } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import { WorkflowContext } from "../../../../contexts/WorkflowContext";
import WorkflowProgress from "../../../utils/WorkflowProgress";

const vdbOptions = [
  {
    id: "QdrantVectorDBManager",
    name: "Qdrant",
    description: "Open-source vector database written in Rust",
    logo: "/images/vdb-logos/qdrant.png",
  },
  {
    id: "PineconeVectorDBManager",
    name: "Pinecone",
    description: "Managed vector database for machine learning applications",
    logo: "/images/vdb-logos/pinecone.png",
  },
  {
    id: "PostgresVectorDBManager",
    name: "PostgreSQL + pgvector",
    description: "Vector similarity search for PostgreSQL",
    logo: "/images/vdb-logos/postgresql.png",
  },
];

const ConnectDataScreen = () => {
  const { deasyUserConfig, setDeasyUserConfig } = useContext(BaseContext);
  const { selectedWorkflow, updateWorkflow } = useContext(WorkflowContext);

  const [selectedVdb, setSelectedVdb] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [newProfileName, setNewProfileName] = useState("");

  // Get profiles for selected VDB
  const getVdbProfiles = () => {
    if (!selectedVdb) return [];
    return Object.entries(deasyUserConfig.vdbmConfig.Configs || {})
      .filter(([_, config]) => config.type === selectedVdb)
      .map(([name, config]) => ({
        name,
        ...config,
      }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const configData = Object.fromEntries(formData.entries());

    const profileName = isCreatingNew ? newProfileName : selectedProfile;

    // Update the configuration
    const newConfigs = {
      ...deasyUserConfig.vdbmConfig.Configs,
      [profileName]: {
        type: selectedVdb,
        ...configData,
      },
    };

    setDeasyUserConfig({
      ...deasyUserConfig,
      vdbmConfig: {
        ...deasyUserConfig.vdbmConfig,
        LastActive: profileName,
        Configs: newConfigs,
      },
    });

    // Update workflow with VDB configuration
    if (selectedWorkflow) {
      const updatedWorkflow = {
        ...selectedWorkflow,
        vdbProfile: profileName,
        vdbType: selectedVdb,
      };
      updateWorkflow(selectedWorkflow.id, updatedWorkflow);
    }

    setIsCreatingNew(false);
    setNewProfileName("");
  };

  return (
    <div className="flex flex-col h-full">
      <WorkflowProgress currentStep={0} />

      <div className="flex-1 p-8">
        <div className="mb-6">
          <h2 className="text-lg font-medium mb-4">Select Vector Database</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {vdbOptions.map((vdb) => (
              <div
                key={vdb.id}
                className={`p-6 border rounded-lg cursor-pointer transition-all hover:shadow-md text-left ${
                  selectedVdb === vdb.id
                    ? "border-primary bg-primary/5"
                    : "border-gray-200"
                }`}
                onClick={() => {
                  setSelectedVdb(vdb.id);
                  setSelectedProfile(null);
                  setIsCreatingNew(false);
                }}
              >
                <div className="flex items-center mb-4">
                  <img
                    src={vdb.logo}
                    alt={`${vdb.name} logo`}
                    className="h-8 w-auto mr-3 rounded-lg"
                  />
                  <h3 className="text-lg font-medium">{vdb.name}</h3>
                </div>
                <p className="text-gray-600 text-sm">{vdb.description}</p>
              </div>
            ))}
          </div>
        </div>

        {selectedVdb && (
          <div className="mt-8">
            <h2 className="text-lg font-medium mb-4">Select Profile</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
              {getVdbProfiles().map((profile) => (
                <div
                  key={profile.name}
                  className={`p-4 border rounded cursor-pointer ${
                    selectedProfile === profile.name && !isCreatingNew
                      ? "border-primary bg-primary/5"
                      : "border-gray-200 hover:border-primary/50"
                  }`}
                  onClick={() => {
                    setSelectedProfile(profile.name);
                    setIsCreatingNew(false);
                  }}
                >
                  <h3 className="font-medium">{profile.name}</h3>
                  {profile.name === deasyUserConfig.vdbmConfig.LastActive && (
                    <span className="text-xs text-primary mt-1">Active</span>
                  )}
                </div>
              ))}
              <div
                className={`p-4 border rounded cursor-pointer ${
                  isCreatingNew
                    ? "border-primary bg-primary/5"
                    : "border-gray-200 hover:border-primary/50"
                }`}
                onClick={() => {
                  setIsCreatingNew(true);
                  setSelectedProfile(null);
                }}
              >
                <span className="text-gray-600">+ Create New Profile</span>
              </div>
            </div>

            {(selectedProfile || isCreatingNew) && (
              <div className="max-w-2xl border-2 border-primary rounded-lg p-4 bg-white shadow-md">
                <form onSubmit={handleSubmit} className="space-y-4">
                  {isCreatingNew && (
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Profile Name
                      </label>
                      <input
                        type="text"
                        className="w-full p-2 border rounded"
                        placeholder="Enter profile name"
                        value={newProfileName}
                        onChange={(e) => setNewProfileName(e.target.value)}
                        required
                      />
                    </div>
                  )}

                  {selectedVdb === "QdrantVectorDBManager" && (
                    <>
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Qdrant URL
                        </label>
                        <input
                          name="url"
                          type="text"
                          className="w-full p-2 border rounded"
                          placeholder="http://localhost:6333"
                          defaultValue={
                            !isCreatingNew
                              ? getVdbProfiles().find(
                                  (p) => p.name === selectedProfile,
                                )?.url
                              : ""
                          }
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Collection Name
                        </label>
                        <input
                          name="collection"
                          type="text"
                          className="w-full p-2 border rounded"
                          placeholder="my_collection"
                          defaultValue={
                            !isCreatingNew
                              ? getVdbProfiles().find(
                                  (p) => p.name === selectedProfile,
                                )?.collection
                              : ""
                          }
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          API Key (optional)
                        </label>
                        <input
                          name="apiKey"
                          type="password"
                          className="w-full p-2 border rounded"
                          placeholder="Enter your API key"
                          defaultValue={
                            !isCreatingNew
                              ? getVdbProfiles().find(
                                  (p) => p.name === selectedProfile,
                                )?.apiKey
                              : ""
                          }
                        />
                      </div>
                    </>
                  )}

                  {selectedVdb === "PineconeVectorDBManager" && (
                    <>
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Environment
                        </label>
                        <input
                          name="environment"
                          type="text"
                          className="w-full p-2 border rounded"
                          placeholder="us-east1-gcp"
                          defaultValue={
                            !isCreatingNew
                              ? getVdbProfiles().find(
                                  (p) => p.name === selectedProfile,
                                )?.environment
                              : ""
                          }
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Collection Name
                        </label>
                        <input
                          name="collection"
                          type="text"
                          className="w-full p-2 border rounded"
                          placeholder="my_collection"
                          defaultValue={
                            !isCreatingNew
                              ? getVdbProfiles().find(
                                  (p) => p.name === selectedProfile,
                                )?.collection
                              : ""
                          }
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          API Key
                        </label>
                        <input
                          name="apiKey"
                          type="password"
                          className="w-full p-2 border rounded"
                          placeholder="Enter your API key"
                          defaultValue={
                            !isCreatingNew
                              ? getVdbProfiles().find(
                                  (p) => p.name === selectedProfile,
                                )?.apiKey
                              : ""
                          }
                          required
                        />
                      </div>
                    </>
                  )}

                  {selectedVdb === "PostgresVectorDBManager" && (
                    <>
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Connection String
                        </label>
                        <input
                          name="connectionString"
                          type="text"
                          className="w-full p-2 border rounded"
                          placeholder="postgresql://user:password@localhost:5432/dbname"
                          defaultValue={
                            !isCreatingNew
                              ? getVdbProfiles().find(
                                  (p) => p.name === selectedProfile,
                                )?.connectionString
                              : ""
                          }
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Collection Name
                        </label>
                        <input
                          name="collection"
                          type="text"
                          className="w-full p-2 border rounded"
                          placeholder="my_collection"
                          defaultValue={
                            !isCreatingNew
                              ? getVdbProfiles().find(
                                  (p) => p.name === selectedProfile,
                                )?.collection
                              : ""
                          }
                          required
                        />
                      </div>
                    </>
                  )}

                  <button
                    type="submit"
                    className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90"
                  >
                    {isCreatingNew
                      ? "Create Profile"
                      : "Use as active vector database"}
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectDataScreen;
