import React, { useContext, useEffect, useState } from "react";
import { WorkflowType } from "../../models/WorkflowModel";
import { WorkflowContext } from "../../contexts/WorkflowContext";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const WorkflowNavigation = () => {
  const { currentScreen, setCurrentScreen, selectedWorkflow } =
    useContext(WorkflowContext);
  const [isExpanded, setIsExpanded] = useState(true);

  // Set default screen to "all" when component mounts
  useEffect(() => {
    if (!currentScreen) {
      setCurrentScreen("all");
    }
  }, [currentScreen, setCurrentScreen]);

  const workflowItems = [
    {
      id: "all",
      label: "All workflows",
      screen: "all",
      alwaysEnabled: true,
    },
    {
      id: WorkflowType.CONNECT_DATA,
      label: "Connect data",
      screen: WorkflowType.CONNECT_DATA,
    },
    {
      id: WorkflowType.DATA_DISCOVERY,
      label: "Data discovery",
      screen: WorkflowType.DATA_DISCOVERY,
    },
    {
      id: WorkflowType.USE_CASE_MANAGEMENT,
      label: "Use Cases",
      screen: WorkflowType.USE_CASE_MANAGEMENT,
    },
    {
      id: WorkflowType.EXPORT_METADATA,
      label: "Export",
      screen: WorkflowType.EXPORT_METADATA,
    },
  ];

  const handleNavClick = (item) => {
    if (item.alwaysEnabled || selectedWorkflow) {
      setCurrentScreen(item.screen);
    }
  };

  return (
    <div className="flex h-screen relative">
      {/* Main navigation container */}
      <div
        className={`
          bg-[#f0f9f7] h-full border-r transition-all duration-300 relative
          ${isExpanded ? "w-40" : "w-0"}
        `}
      >
        {/* Header section with toggle button */}
        <div
          className={`
          h-12 border-b bg-[#f0f9f7] flex items-center px-4
          transition-all duration-300
          ${isExpanded ? "opacity-100" : "opacity-0"}
        `}
        >
          <span className="text-gray-700 font-medium">Workflow Steps</span>
        </div>

        {/* Content section */}
        <div
          className={`
          flex-1 overflow-hidden transition-all duration-300
          ${isExpanded ? "opacity-100 w-40" : "opacity-0 w-0"}
        `}
        >
          <div className="p-4">
            {workflowItems.map((item) => {
              const isDisabled = !item.alwaysEnabled && !selectedWorkflow;

              return (
                <div
                  key={item.id}
                  className={`
                    relative text-left py-2 px-4 cursor-pointer text-sm rounded
                    group whitespace-nowrap
                    ${
                      currentScreen === item.screen
                        ? "bg-primary text-white"
                        : isDisabled
                          ? "text-gray-400 hover:text-gray-400 cursor-not-allowed"
                          : "text-gray-600 hover:text-gray-900"
                    }
                  `}
                  onClick={() => handleNavClick(item)}
                >
                  {item.label}

                  {/* Tooltip */}
                  {isDisabled && isExpanded && (
                    <div className="absolute left-full ml-2 top-1/2 -translate-y-1/2 w-48 p-2 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                      Please select a workflow first to access this step
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Toggle button container */}
      <div
        className={`
        absolute top-0 h-12 flex items-center
        transition-all duration-300
        ${isExpanded ? "left-40" : "left-0"}
      `}
      >
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="
            h-12 w-6 bg-[#f0f9f7] border-r border-t border-b
            flex items-center justify-center
            hover:bg-[#e5f4f1] transition-colors
          "
        >
          {isExpanded ? (
            <IoChevronBack className="h-4 w-4 text-gray-600" />
          ) : (
            <IoChevronForward className="h-4 w-4 text-gray-600" />
          )}
        </button>
      </div>
    </div>
  );
};

export default WorkflowNavigation;
