import React, { useContext, useState } from "react";
import { WorkflowContext } from "../../../contexts/WorkflowContext";
import { FaCheck } from "react-icons/fa";
import WorkflowConfigurationScreen from "./screens/WorkflowConfigurationScreen";
import { WorkflowType, WorkflowStatus } from "../../../models/WorkflowModel";
import { workflowService } from "../../../services/api";
import { BaseContext } from "../../../contexts/BaseContext";

const AllWorkflows = () => {
  const { selectedWorkflow, setSelectedWorkflow, workflows, deleteWorkflow } =
    useContext(WorkflowContext);

  const [showConfiguration, setShowConfiguration] = useState(false);
  const [editingWorkflowId, setEditingWorkflowId] = useState(null);
  const { deasyUserConfig } = useContext(BaseContext);
  const deasyApiKey = deasyUserConfig.deasyApiKey;

  const handleCreateWorkflow = () => {
    const newWorkflow = {
      id: `wf-${Date.now()}`,
      name: "New Workflow",
      type: WorkflowType.CONFIGURE,
      status: WorkflowStatus.DRAFT,
    };
    setSelectedWorkflow(newWorkflow);
    setEditingWorkflowId(null);
    setShowConfiguration(true);
  };

  const handleEditWorkflow = (workflowId, e) => {
    e.stopPropagation();
    setEditingWorkflowId(workflowId);
    setShowConfiguration(true);
  };

  const handleCloseConfiguration = () => {
    setShowConfiguration(false);
    setEditingWorkflowId(null);
  };

  const handleSelectWorkflow = (workflow) => {
    setSelectedWorkflow(workflow);
  };

  if (showConfiguration) {
    return (
      <div className="p-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold">
            {editingWorkflowId ? "Edit Workflow" : "Create New Workflow"}
          </h1>
          <button
            onClick={handleCloseConfiguration}
            className="text-gray-600 hover:text-gray-900"
          >
            Cancel
          </button>
        </div>
        <WorkflowConfigurationScreen
          workflowId={editingWorkflowId}
          onClose={handleCloseConfiguration}
        />
      </div>
    );
  }

  return (
    <div className="px-6 py-5">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">My Workflows</h1>
        <button
          onClick={handleCreateWorkflow}
          className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90"
        >
          New Workflow
        </button>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Workflow Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  # Clusters
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  # Tags Extracted
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Auto-sync
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {workflows?.map((workflow) => (
                <tr
                  key={workflow.id}
                  className={`${
                    selectedWorkflow?.id === workflow.id ? "bg-primary/5" : ""
                  } cursor-pointer hover:bg-gray-50`}
                  onClick={() => handleSelectWorkflow(workflow)}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center text-left">
                      {selectedWorkflow?.id === workflow.id && (
                        <FaCheck className="h-4 w-4 text-primary mr-2" />
                      )}
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                        ${
                          workflow.status === "completed"
                            ? "bg-green-100 text-green-800"
                            : workflow.status === WorkflowStatus.DRAFT
                              ? "bg-blue-100 text-blue-800"
                              : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {workflow.status || "Draft"}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                    {workflow.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                    {workflow.clustersCount || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                    {workflow.tagsExtracted || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                    {workflow.autoSync ? "Yes" : "No"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={(e) => handleEditWorkflow(workflow.id, e)}
                      className="text-primary hover:text-primary-dark mr-4"
                    >
                      Edit
                    </button>
                    <button
                      onClick={async (e) => {
                        e.stopPropagation();
                        try {
                          await workflowService.deleteWorkflow(
                            workflow.id,
                            deasyApiKey,
                          );
                          deleteWorkflow(workflow.id);
                        } catch (error) {
                          console.error("Failed to delete workflow:", error);
                        }
                      }}
                      className="text-red-600 hover:text-red-900"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {workflows?.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-500 mb-4">No workflows created yet</p>
          <button
            onClick={handleCreateWorkflow}
            className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90"
          >
            Create your first workflow
          </button>
        </div>
      )}
    </div>
  );
};

export default AllWorkflows;
