import { createContext, useState } from "react";

export const TokenContext = createContext();
export const TokenProvider = ({ children }) => {
  const [tokens, setTokens] = useState([]);
  const [userCredits, setUserCredits] = useState(0);

  return (
    <TokenContext.Provider
      value={{
        // Getters
        tokens,
        userCredits,
        // Setters
        setTokens,
        setUserCredits,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
