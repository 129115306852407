import React, { useState, useContext } from "react";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { Tabs, Tab } from "@mui/material";
import TagEditor from "../../../../SavedTags/components/TagEditor/TagEditor";
import CompactTagsTable from "../../../../SavedTags/components/CompactTagsTable";
import { AutoSuggestedMetadata } from "./Panels/AutoSuggestedMetadata";
import { auth } from "../../../../../../config/firebase";
import { prepareVectorDBConfiguration } from "../../../../../../services/utils";
import { FilePickerModal } from "../ExtractMeta/FilePickerModal";

export const AddNodeSidePanel = ({ handleAddNodeApplied }) => {
  const {
    sidePanelOpen,
    setSidePanelOpen,
    setSelectedNodeData,
    selectedNodeData,
    updateHierarchyStats,
  } = useContext(GraphContext);
  const { deasyUserConfig } = useContext(BaseContext);

  const [tagInEditor, setTagInEditor] = useState({});
  const [isTagEditorCollapsed, setIsTagEditorCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const buildConditions = (nodePath) => {
    if (!nodePath) return [];

    const pathParts = nodePath.split(">");
    const conditions = [];

    // Skip the root node if it's the first element
    const startIndex = pathParts[0] === "root" ? 1 : 0;

    // Build conditions only for the current path
    for (let i = startIndex; i < pathParts.length - 1; i += 2) {
      const tag = pathParts[i];
      const value = pathParts[i + 1];

      conditions.push({
        tag_id: tag,
        values: [value],
      });
    }

    return conditions;
  };

  const handleApply = async () => {
    if (!tagInEditor || !selectedNodeData) return;

    try {
      await handleAddNodeApplied(selectedNodeData, tagInEditor);

      // Get active configurations
      const activeVdbConfig =
        deasyUserConfig.vdbmConfig?.Configs?.[
          deasyUserConfig.vdbmConfig?.LastActive
        ] || {};
      const activeLlmConfig =
        deasyUserConfig.llmConfig?.Configs?.[
          deasyUserConfig.llmConfig?.LastActive
        ] || {};

      // Build conditions only for the current path
      const pathConditions = buildConditions(selectedNodeData.path);

      // Add the new tag condition
      const newTagCondition = {
        tag_id: tagInEditor.name,
        values: [], // Empty values array for new tag
      };

      const conditions = [...pathConditions, newTagCondition];

      // Create request data
      const data = {
        vector_db_config: prepareVectorDBConfiguration({
          ...activeVdbConfig,
          user: auth.currentUser.email,
        }),
        endpoint_manager_config: activeLlmConfig,
        conditions: conditions,
      };

      await updateHierarchyStats(data, deasyUserConfig.deasyApiKey);

      setTagInEditor({});
      setSelectedNodeData(null);
      setSidePanelOpen(false);
    } catch (error) {
      console.error("Error applying tag:", error);
    }
  };

  const isApplyDisabled = !tagInEditor || Object.keys(tagInEditor).length === 0;
  const [openFilePicker, setOpenFilePicker] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const TabContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <CompactTagsTable
            hideNewTagButton={true}
            tagInEditor={tagInEditor}
            setTagInEditor={setTagInEditor}
          />
        );
      case 1:
        return (
          <TagEditor
            showHeader={false}
            includeNewTagButton={true}
            tagInEditor={tagInEditor}
            setTagInEditor={setTagInEditor}
            isTagEditorCollapsed={isTagEditorCollapsed}
            setIsTagEditorCollapsed={setIsTagEditorCollapsed}
          />
        );
      case 2:
        return (
          <AutoSuggestedMetadata
            openFilePicker={openFilePicker}
            setOpenFilePicker={setOpenFilePicker}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        transform: sidePanelOpen ? "translateX(0)" : "translateX(100%)",
        opacity: sidePanelOpen ? 1 : 0,
        transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
        width: "500px",
      }}
      className="absolute right-0 top-0 h-screen bg-white border border-gray-200 flex flex-col rounded-l-md"
    >
      {/* Close button */}
      <button
        onClick={() => {
          setSelectedNodeData({});
          setSidePanelOpen(false);
        }}
        className="absolute top-1 right-1 z-10 p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
      >
        ✕
      </button>

      {/* Main content container */}
      <div className="flex flex-col h-full overflow-hidden">
        {/* Tabs */}
        <div className="flex-shrink-0 pt-4">
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            variant="fullWidth"
            TabIndicatorProps={{
              style: { backgroundColor: "#4dac94" },
            }}
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
                minHeight: "32px",
                padding: "4px 8px",
              },
              "& .Mui-selected": {
                color: "#4dac94 !important",
              },
            }}
          >
            <Tab label="Metadata library" sx={{ fontSize: "0.8rem" }} />
            <Tab label="Define metadata" sx={{ fontSize: "0.8rem" }} />
            <Tab label="Auto-suggest metadata" sx={{ fontSize: "0.8rem" }} />
          </Tabs>
        </div>

        {/* Main content area */}
        <div className="flex-1 flex flex-col min-h-0">
          <div className="flex-1 overflow-y-auto border border-gray-200">
            <TabContent hideHeader={true} />
          </div>
          {selectedTab !== 2 && (
            <div className="flex-shrink-0 p-4 border-t bg-white">
              <button
                className={`w-full btn ${isApplyDisabled ? "btn-disabled" : "btn-primary"}`}
                onClick={handleApply}
                disabled={isApplyDisabled}
              >
                {isApplyDisabled ? "Select a tag to apply" : "Apply Tag"}
              </button>
            </div>
          )}
        </div>
      </div>
      <FilePickerModal
        openFilePicker={openFilePicker}
        setOpenFilePicker={setOpenFilePicker}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />
    </div>
  );
};
