import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";

import ComputeEstimation from "./ComputeEstimation.js";
import ReactFlow from "reactflow";
import "@xyflow/react/dist/style.css";
import { nodeTypes } from "../Graph/GraphNodes.js";
import { processData } from "../WorkflowSummaryTab.js";
import { useContext } from "react";
import { metadataService } from "../../../../../../services/api.js";
import { BaseContext } from "../../../../../../contexts/BaseContext.js";
import {
  prepareEndpointManagerConfig,
  prepareVectorDBConfiguration,
} from "../../../../../../services/utils.js";
import { FileIcon } from "lucide-react";
import { FilePickerModal } from "./FilePickerModal.js";
import { GraphContext } from "../../../../../../contexts/GraphContext.js";
import { ProgressModal } from "./ProgressModal.js";

const ExtractMetadataTab = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagNames, setTagNames] = useState([]);
  const [tagDefs, setTagDefs] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [openFilePicker, setOpenFilePicker] = useState(false);

  const { deasyUserConfig } = useContext(BaseContext);
  const { discoveryGraphData } = useContext(GraphContext);
  const [currentStatusText, setCurrentStatusText] = useState("Please wait");
  const [classifyProgress, setClassifyProgress] = useState(0);
  const [classifyAbortController, setClassifyAbortController] = useState(null);
  const [classifyBatchesProgress, setClassifyBatchesProgress] = useState(0);
  const [classifyTotalBatches, setClassifyTotalBatches] = useState(0);
  const checkProgress = async (jobId, batchLength) => {
    if (classifyProgress >= 100) return;

    try {
      const jobStatus = await metadataService.trackJob(jobId);
      const processed_files = jobStatus.data?.processed_files || 0;
      const processed_batches = jobStatus.data?.processed_batches || 0;
      const progress_percent = (processed_files / selectedFiles) * 100;
      setClassifyProgress(progress_percent);

      if (
        processed_batches >= batchLength ||
        processed_files >= selectedFiles ||
        progress_percent >= 100
      ) {
        setClassifyProgress(100);
      } else {
        // Schedule next check in 2 seconds
        setTimeout(checkProgress, 2000);
      }
    } catch (error) {
      console.error("Error tracking job progress:", error);
      // Even on error, continue checking
      setTimeout(checkProgress, 2000);
    }
  };

  function getAllNodes(obj, nodes = new Set()) {
    if (!obj || typeof obj !== "object") {
      return nodes;
    }
    Object.keys(obj).forEach((key) => {
      nodes.add(key);
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item) => {
          if (typeof item === "string") {
            nodes.add(item);
          }
        });
      } else if (typeof obj[key] === "object") {
        getAllNodes(obj[key], nodes);
      }
    });

    return nodes;
  }
  const vectorDBConfiguration =
    deasyUserConfig.vdbmConfig.Configs[deasyUserConfig.vdbmConfig.LastActive];
  const llmEndpointConfiguration =
    deasyUserConfig.llmConfig.Configs[deasyUserConfig.llmConfig.LastActive];

  const deasyApiKey = deasyUserConfig.deasyApiKey;
  const classify = async (overwrite = true) => {
    setSelectedTags(
      tagNames.filter((tag) => getAllNodes(discoveryGraphData).has(tag)),
    );
    setCurrentStatusText("Please wait");
    setModalOpen(true);
    try {
      const filteredKeys = Object.keys(tagDefs)
        .filter((key) => selectedTags.includes(key))
        .reduce((filteredObj, key) => {
          filteredObj[key] = tagDefs[key];
          return filteredObj;
        }, {});
      setCurrentStatusText("Classifying points");
      const controller = new AbortController();
      const [jobId, batchLength] = metadataService.taggingStudioClassify(
        prepareVectorDBConfiguration(vectorDBConfiguration),
        prepareEndpointManagerConfig(llmEndpointConfiguration),
        selectedFiles,
        filteredKeys,
        deasyApiKey,
        false,
        overwrite,
        discoveryGraphData,
        controller,
        () => {
          setClassifyBatchesProgress(classifyBatchesProgress + 1);
        },
      );
      setClassifyTotalBatches(batchLength);
      setClassifyAbortController(controller);
      // Generate File Level tags
      await checkProgress(jobId, batchLength);
      setCurrentStatusText("Generating file level tags");

      await metadataService.generateFileLevelTags(
        prepareVectorDBConfiguration(vectorDBConfiguration),
        prepareEndpointManagerConfig(llmEndpointConfiguration),
        selectedFiles,
        filteredKeys,
        deasyApiKey,
      );
      setCurrentStatusText("Success!");
    } catch (error) {
      console.log(error);
      setCurrentStatusText("Error");
    } finally {
      setTimeout(() => {
        setModalOpen(false);
        setClassifyProgress(0);
        setClassifyTotalBatches(0);
        setClassifyBatchesProgress(0);
        setClassifyAbortController(null);
      }, 2000);
    }
  };
  useEffect(() => {
    const getTags = async () => {
      const allTags = {};
      const response = await metadataService.getSavedTags(deasyApiKey);
      response.data.tags.forEach((tag) => {
        allTags[tag.name] = {
          name: tag.name,
          description: tag.description,
          output_type: tag.output_type,
          option: tag.option,
          availableValues: tag.available_values,
        };
      });
      setTagDefs(allTags);
      setTagNames([...response.data.tags.map((tag) => tag.name)]);
      setSelectedTags(
        [...response.data.tags.map((tag) => tag.name)].filter((tag) =>
          getAllNodes(discoveryGraphData).has(tag),
        ),
      );
    };
    getTags();
    return () => {
      if (classifyAbortController) classifyAbortController.abort();
    };
    // eslint-disable-next-line
  }, []);
  const { nodes, edges } = processData(discoveryGraphData, 1000);
  return (
    <div className="w-full h-full">
      <div className="bg-white rounded-lg border border-gray-200 h-[80%] p-6">
        <div className="flex flex-row h-full justify-center">
          <div className="flex flex-col justify-start items-start flex-1 w-full max-w-[50%]">
            <Typography
              variant="h6"
              className="py-4"
              style={{ alignSelf: "flex-start" }}
            >
              Configure Extraction
            </Typography>
            <div className="w-full items-start justify-start align-left max-w-[100%]">
              <div className="flex flex-row items-start justify-center">
                <div className="py-2 w-full text-left">Choose your files</div>
              </div>

              <Button
                variant="outlined"
                startIcon={<FileIcon />}
                className="w-full"
                sx={{
                  textTransform: "none",
                  borderColor: "#4dac94",
                  color: "#4dac94",
                }}
                onClick={() => setOpenFilePicker(true)}
              >
                {selectedFiles.length > 0
                  ? `${selectedFiles.length} file${selectedFiles.length > 1 ? "s" : ""} selected`
                  : "Select Files..."}
              </Button>
              <ComputeEstimation
                tagDefs={tagDefs}
                selectedFiles={selectedFiles}
                selectedTags={selectedTags}
              />
            </div>
            <div className="flex flex-row mt-auto w-full">
              <Button
                variant="contained"
                className="w-52"
                style={{
                  textTransform: "none",
                  backgroundColor: "#4dac94",
                }}
                onClick={() => classify(true)}
              >
                Classify
              </Button>
              <Button
                className="w-full"
                style={{
                  textTransform: "none",
                }}
                onClick={() => classify(false)}
              >
                Classify without overwriting?
              </Button>
            </div>
          </div>
          <div className="ml-4 flex-1">
            <div className="relative w-full h-full rounded-lg overflow-hidden bg-white mt-6">
              <div
                className="absolute inset-0"
                style={{
                  backgroundImage: `radial-gradient(circle at center, #e5e5e5 2px, transparent 2px)`,
                  backgroundSize: "20px 20px",
                  animation: "moveBackground 4s linear infinite",
                }}
              />
              <div
                className="absolute inset-0"
                style={{
                  background: `
                      linear-gradient(90deg, white 0%, transparent 5%, transparent 95%, white 100%),
                      linear-gradient(180deg, white 0%, transparent 5%, transparent 95%, white 100%)
                    `,
                  pointerEvents: "none",
                  zIndex: 1,
                }}
              />
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                fitView
                defaultEdgeOptions={{
                  type: "step",
                  style: { stroke: "#d1d5db" },
                }}
              />
            </div>
          </div>
          <style>{`
              @keyframes moveBackground {
                0% {
                  transform: translate(20px, 20px);
                }
                100% {
                  transform: translate(0px, 0px);
                }
              }
            `}</style>
        </div>
      </div>
      <ProgressModal
        openProgressModal={modalOpen}
        currentStatusText={currentStatusText}
        classifyProgress={classifyProgress}
        classifyBatchesProgress={classifyBatchesProgress}
        classifyTotalBatches={classifyTotalBatches}
      />
      <FilePickerModal
        openFilePicker={openFilePicker}
        setOpenFilePicker={setOpenFilePicker}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />
    </div>
  );
};

export default ExtractMetadataTab;
